import { useContext, useState } from 'react';
import { PropsFormActiveProvider } from '../../components/molecules/Modal/CreateEditActiveModal/Type';
import { EditModalContext } from './useEditModalContext';

export function EditModalProvider({ children }: PropsFormActiveProvider): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);
  const [id, setId] = useState<string>('');
  const handleOpenCloseModal = () => {
    setOpen(pre => !pre);
  };

  const handleId = (value: string) => {
    setId(value);
  };

  const context = {
    id,
    handleId,
    open,
    handleOpenCloseModal
  };

  return <EditModalContext.Provider value={context}>{children}</EditModalContext.Provider>;
}

export function useEditModalContext() {
  const context = useContext(EditModalContext);
  return context;
}
