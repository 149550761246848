import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const accordionDetailsEntryOperationStyles = makeStyles<Theme>((theme: Theme) => ({
  tableCell: {
    background: theme.palette.secondary.main,
    color: theme.palette.text.secondary
  },

  toggle: {
    display: 'flex',
    justifyContent: 'end',
    marginRight: '2rem'
  },

  buttonEdit: {
    fontSize: '1rem',
    border: `1px solid  ${theme.palette.background.default}`,
    borderRadius: '4px'
  }
}));
