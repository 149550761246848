export const dataFormObjectState = {
  ceg: '',
  nomeAntigo: '',
  siglaDoAtivo: '',
  cegResumido: '',
  cluster1: '',
  cluster2: '',
  clusterONS: '',
  situacaoOperacional: '',
  empresa: '',
  cnpj: '',
  municipio: '',
  uf: '',
  fonte: '',
  numeroDeUgs: null,
  potencia: null,
  gf: null,
  pontoDeConexao: '',
  tensaoDoPontoDeConexao: null,
  tipoDeAcessado: '',
  agenteAcessado: '',
  parecerDeAcesso: '',
  linkParecerDeAcesso: '',
  mustMusd: null,
  custCusd: '',
  linkCustCusd: '',
  cctCcd: '',
  linkCctCcd: '',
  mercado: '',
  leiloes: [] as string[],
  editaisDosLeiloes: [] as string[],
  linkEditalDoLeilao: '',
  inicioSupLeilao: null,
  fimSupLeilao: null,
  tipoDoLeilao: '',
  processoAneel: '',
  linkProcessoAneel: '',
  outorga: '',
  linkOutorga: '',
  inicioOutorga: null,
  duracaoAnos: null,
  fimOutorga: null,
  fimOutorgaString: '',
  acati: '',
  linkAcati: '',
  reidi: '',
  linkReidi: ''
};
