import { PageWrapper } from '../components/atoms';
import Navigation from '../components/organisms/Navigation/Navigation';
import NavigationTabs from '../components/organisms/NavigationTabs/NavigationTabs';

export default function ActivityManagementPage(): JSX.Element {
  return (
    <>
      <Navigation headerTitle="Gestão de Atividades" />
      <PageWrapper>
        <NavigationTabs />
      </PageWrapper>
    </>
  );
}
