export enum ResultProjectsInDevelopment {
  POSITIVO = 'POSITIVO',
  POSITIVO_COM_RESTRICOES = 'POSITIVO_COM_RESTRICOES',
  NEGATIVO = 'NEGATIVO'
}

export enum SourceDescription {
  EOL = 'Eólica',
  PCH = 'Hidrelétrica',
  UFV = 'Fotovoltaica'
}
