import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const formUploadBaseGeracaoStyle = makeStyles<Theme>((theme: Theme) => ({
  inputUpload: {
    borderRadius: '4px',
    borderStyle: 'dashed',
    borderWidth: '2px',
    borderColor: '#959595',
    height: '2.813rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },

  buttonUpload: {
    fontSize: '0.6rem',
    cursor: 'pointer',
    marginLeft: '0.3rem'
  },

  textInput: {
    fontSize: '0.9rem'
  },

  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end'
  },

  boxInput: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%'
  },

  boxInputText: {
    margin: '0 auto'
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    marginBottom: '0.5rem',
    padding: '0.5rem'
  },

  boxLabel: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  typographySmall: {
    fontSize: '10px',
    marginTop: '10px'
  },

  boxMessageDocument: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginLeft: '1rem',
    marginRight: '1rem'
  },

  deleteDocument: {
    cursor: 'pointer'
  },

  messageDocumentType: {
    fontSize: '0.6rem',
    textAlign: 'center',
    position: 'relative',
    marginTop: '-0.85rem'
  }
}));
