import Keywords from '../components/organisms/Keywords/Keywords';
import { AlertProvider } from '../hooks/useAlerts/useAlerts';
import { AcronymsProvider } from '../hooks/useGetAcronyms/useGetAcronyms';

export default function DocumentsPage(): JSX.Element {
  return (
    <AlertProvider>
      <AcronymsProvider>
        <Keywords />
      </AcronymsProvider>
    </AlertProvider>
  );
}
