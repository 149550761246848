export enum AlertMessagesEnum {
  SUCCESS = 'Elemento adicionado com sucesso.',
  ERROR = 'Não foi possível adicionar, tente novamente.',
  INFO = 'Um ou mais campos estão vazios.',
  SUCCESS_DONWLOAD_BASE_GERACAO = 'Data da última captura da Consulta CEG foi',
  WARNING_DONWLOAD_BASE_GERACAO = 'Planilha vazia, não há dados salvos da Consulta CEG',
  ERROR_DONWLOAD_BASE_GERACAO = 'Ocorreu um erro no dowload, tente novamente'
}

export enum AlertSeverityEnum {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error'
}
