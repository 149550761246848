import { Box, Grid, Typography } from '@mui/material';
import { useStyles } from '../../AccordionActiveDetailingStyles';
import { AccordionDetailsActiveProps } from './Type';

export function AccordionDetailsActive({ data }: AccordionDetailsActiveProps) {
  const classes = useStyles();

  return (
    <Box className={classes.contentContainer}>
      <Grid container spacing={10} className={classes.grid}>
        <Grid item>
          <Typography>
            CEG: <span>{data?.ceg}</span>
          </Typography>
          <Typography>
            Sigla do Ativo: <span>{data?.siglaDoAtivo}</span>
          </Typography>
          <Typography>
            Cluster 2: <span>{data?.cluster2}</span>
          </Typography>
          <Typography>
            Empresa: <span>{data?.empresa}</span>
          </Typography>
          <Typography>
            CNPJ: <span>{data?.cnpj}</span>
          </Typography>
          <Typography>
            Município: <span>{data?.municipio}</span>
          </Typography>
          <Typography>
            UF: <span>{data?.uf}</span>
          </Typography>
          <Typography>
            Fonte: <span>{data?.fonte}</span>
          </Typography>
          <Typography>
            Número de UGs: <span>{data?.numeroDeUgs}</span>
          </Typography>
          <Typography>
            Potência: <span>{data?.potencia}</span>
          </Typography>
        </Grid>

        <Grid item>
          <Typography>
            GF: <span>{data?.gf}</span>
          </Typography>
          <Typography>
            Ponto de Conexão: <span>{data?.pontoDeConexao}</span>
          </Typography>
          <Typography>
            Tensão: <span>{data?.tensaoDoPontoDeConexao}</span>
          </Typography>
          <Typography>
            Mercado: <span>{data?.mercado}</span>
          </Typography>
          <Typography>
            Leilão: <span>{data?.leilao}</span>
          </Typography>
          <Typography>
            Tipo de Leilão: <span>{data?.tipoDoLeilao}</span>
          </Typography>
          <Typography>
            Início do Sup: <span>{data?.inicioSupLeilao}</span>
          </Typography>
          <Typography>
            Fim Sup: <span>{data?.fimSupLeilao}</span>
          </Typography>
          <Typography>
            Outorga: <span>{data?.outorga}</span>
          </Typography>
          <Typography>
            Início da Outorga: <span>{data?.inicioOutorga}</span>
          </Typography>
        </Grid>

        <Grid item>
          <Typography>
            Processo Aneel: <span>{data?.processoAneel}</span>
          </Typography>
          <Typography>
            Fim da Outorga: <span>{data?.fimOutorga}</span>
          </Typography>
          <Typography>
            ACATI: <span>{data?.acati}</span>
          </Typography>
          <Typography>
            REIDI: <span>{data?.reidi}</span>
          </Typography>
          <Typography>
            MUST: <span>{data?.mustMusd}</span>
          </Typography>
          <Typography>
            CUST: <span>{data?.custCusd}</span>
          </Typography>
          <Typography>
            CCT: <span>{data?.cctCcd}</span>
          </Typography>
          <Typography>
            Agente Acessado: <span>{data?.agenteAcessado}</span>
          </Typography>
          <Typography>
            Parecer de Acesso: <span>{data?.parecerDeAcesso}</span>
          </Typography>
          <Typography>
            Tipo de DAPR:
            <span>{data?.daprs}</span>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
