import { Typography, Grid, Box, Link } from '@mui/material';
import { accordionDetailsActivityStyles } from './AccordionDetailsActivityStyles';
import LinkIcon from '@mui/icons-material/Link';
import { AccordionDetailsActivityProps } from './Type';
import { FormatData } from '../../../../../util/FormatterActiveDetails';
export function AccordionDetailsActivity({ data, cluster }: AccordionDetailsActivityProps): JSX.Element {
  const classes = accordionDetailsActivityStyles();

  return (
    <Box className={classes.box}>
      {data.map(item => (
        <Box className={classes.boxItem} key={item.id}>
          <Grid item xs={2}>
            <Typography>
              {item.titulo}
              <Link className={classes.link} href={`/gestao-atividades/${cluster}/${item.id}`}>
                <LinkIcon />
                Link
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>
              Descrição: <span>{item.descricao}</span>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>
              Prazo: <span>{FormatData(item.prazo)}</span>
            </Typography>
          </Grid>
        </Box>
      ))}
    </Box>
  );
}
