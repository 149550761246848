import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { dialogStyle } from '../../ButtonsModals/DialogStyle';
import { DeleteDialogProps } from './Type';

export function DeleteDialog({ open, text, onClose, handleDelete }: DeleteDialogProps) {
  const classes = dialogStyle();
  const handleDialogDelete = () => {
    if (open) {
      handleDelete();
      onClose();
    }
  };

  return (
    <div>
      <Dialog className={classes.dialogBox} open={open}>
        <DialogTitle id="alert-dialog-title">
          {'Deseja realmente excluir essa informação do banco de dados?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={classes.dialogContentcolor}>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button onClick={handleDialogDelete} autoFocus color="error">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
