import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const menuLateralStyle = makeStyles<Theme>((theme: Theme) => ({
  swipeableDrawer: {
    anchor: 'left',
    color: theme.palette.background.default
  },

  closeDrawerBackground: {
    backgroundColor: theme.palette.primary.main,
    minHeight: '64px'
  },

  closeDrawer: {
    size: 'large',
    edge: 'start',
    marginRight: '2'
  },

  closeDrawerIcon: {
    fontSize: '40px',
    color: theme.palette.text.secondary
  },

  drawerListDivider: {
    minWidth: '250px'
  },

  drawerListLink: {
    color: theme.palette.text.primary,
    underline: 'none',
    textDecoration: 'none'
  },

  drawerListItem: {
    fontWeight: 500,
    fontSize: '20px',
    flexGrow: 1
  }
}));
