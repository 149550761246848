import { createContext } from 'react';

interface ContextData {
  open: boolean;
  handleOpenCloseModal: () => void;
  formClear: () => void;
  modalState: string;
  handleUpload: (file: File[] | undefined | null, endpoint: string) => void;
  file: InterfaceFile;
  handleLoading: (progress: number, file: string, success?: boolean) => void;
  handleModalState: (typeStateModal: string) => void;
  addMessageError: (value: string) => void;
  messageError: string;
  testUpload: (file: File[] | undefined, path: string) => void;
}
export interface InterfaceFile {
  progress: number;
  file: string;
}

export const ModalUploadContext = createContext<ContextData>({} as ContextData);
