import * as React from 'react';
import Box from '@mui/material/Box';
import { pageWrapperStyle } from './PageWrapperStyle';

type PageWrapperProps = {
  children?: React.ReactChild | React.ReactChild[];
};

export function PageWrapper({ children }: PageWrapperProps): JSX.Element {
  const classes = pageWrapperStyle();
  return <Box className={classes.box}>{children}</Box>;
}
