import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { CreateEditConsultationModalProps } from './Type';
import CloseIcon from '@mui/icons-material/Close';
import { createEditConsultationModalStyle } from './CreateEditConsultationModalStyle';
import { useEffect, useState } from 'react';
import { DesktopDatePicker } from '@mui/lab';
import { createConsultation, updateConsultation } from '../../../../services/Service';
import { queryClient } from '../../../../services/QueryClient';
import { useAlerts } from '../../../../hooks/useAlerts/useAlerts';
import { ValidationProps } from '../../PublicConsultationsBase/Type';
import { verifiedNumber } from '../../../../util/FormatterNumber';
import { checkFields, removeContribution } from '../../PublicConsultationsBase/formatter';
import InputMask from 'react-input-mask';
import { TypeOrgan } from '../../../../interfaces/PublicConsultations/enumPublicConsultations';
import { InterfacePublicConsultations } from '../../../../interfaces/PublicConsultations/interfacePublicConsultations';
import { AlertMessagesEnum, AlertSeverityEnum } from '../../../../hooks/useAlerts/useAlertsEnums';

export function CreateEditConsultationModal({ open, context, iteration, onClose }: CreateEditConsultationModalProps) {
  const classes = createEditConsultationModalStyle();

  const [numeroConsulta, setNumeroConsulta] = useState('');

  const [validation, setValidation] = useState<ValidationProps>({} as ValidationProps);

  const [dataAbertura, setDataAbertura] = useState<Date | null>(null);
  const [dataConclusao, setDataConclusao] = useState<Date | null>(null);
  const [ementa, setEmenta] = useState('');
  const [ementaLink, setEmentaLink] = useState('');
  const [valorEnvolvido, setValorEnvolvido] = useState<string | number>('');
  const [responsavel, setResponsavel] = useState('');
  const [organ, setOrgan] = useState<string[]>([]);
  const [status, setStatus] = useState('');
  const [prioridade, setPrioridade] = useState('');
  const [contribuicao, setContribuicao] = useState('');
  const [contribuicaoLink, setContribuicaoLink] = useState('');

  useEffect(() => {
    if (context === 'create-consultation') {
      setNumeroConsulta('');
      setDataAbertura(null);
      setDataConclusao(null);
      setEmenta('');
      setEmentaLink('');
      setValorEnvolvido('');
      setResponsavel('');
      setStatus('');
      setStatus('');
      setPrioridade('');
      setContribuicao('');
      setContribuicaoLink('');
      setOrgan([]);
    } else {
      setNumeroConsulta(iteration.numeroConsulta);
      setDataAbertura(iteration.dataAbertura);
      setDataConclusao(iteration.dataConclusao);
      setEmenta(iteration.ementa);
      setEmentaLink(iteration.ementaLink);
      setValorEnvolvido(iteration.valorEnvolvido ? Number(iteration.valorEnvolvido).toFixed(0) : '0');
      setResponsavel(iteration.responsavel);
      setStatus(iteration.status);
      setStatus(iteration.status);
      setPrioridade(iteration.prioridade);
      setContribuicao(iteration.contribuicao);
      setContribuicaoLink(iteration.contribuicaoLink);
      setOrgan(iteration.orgao !== undefined ? iteration.orgao.split('e') : []);
    }
  }, [open]);

  const handleCheckValue = (event: any): void => {
    const valueFormat = event.target.value;

    const number = verifiedNumber(valueFormat);

    setValorEnvolvido(number);
  };

  const { handleAlertCall } = useAlerts();

  function handleCreateConsultation() {
    if (context === 'create-consultation') {
      const data = {
        numeroConsulta: numeroConsulta,
        ementa: ementa,
        ementaLink: ementaLink,
        dataAbertura: dataAbertura,
        dataConclusao: dataConclusao,
        prioridade: prioridade,
        responsavel: responsavel,
        valorEnvolvido: valorEnvolvido ? valorEnvolvido.toString() : 0,
        status: status,
        contribuicao: contribuicao,
        contribuicaoLink: contribuicaoLink,
        orgao: organ.join(' e ')
      };
      const check = checkFields(removeContribution(data as InterfacePublicConsultations));
      if (!check) {
        createConsultation(data)
          .then(() => handleAlertCall(AlertSeverityEnum.SUCCESS, AlertMessagesEnum.SUCCESS))
          .then(() => onClose())
          .then(() => queryClient.invalidateQueries('consultations'))
          .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
      } else {
        handleAlertCall(AlertSeverityEnum.INFO, AlertMessagesEnum.INFO);
      }
    }
    if (context === 'edit-consultation') {
      const data = {
        numeroConsulta: numeroConsulta,
        ementa: ementa,
        ementaLink: ementaLink,
        dataAbertura: dataAbertura,
        dataConclusao: dataConclusao,
        prioridade: prioridade,
        responsavel: responsavel,
        valorEnvolvido: valorEnvolvido ? valorEnvolvido.toString() : 0,
        status: status,
        contribuicao: contribuicao,
        contribuicaoLink: contribuicaoLink,
        orgao: organ.join(' e ')
      };

      const check = checkFields(removeContribution(data as InterfacePublicConsultations));

      if (!check) {
        updateConsultation(data, iteration.id)
          .then(() => handleAlertCall(AlertSeverityEnum.SUCCESS, AlertMessagesEnum.SUCCESS))
          .then(() => onClose())
          .then(() => queryClient.invalidateQueries('consultations'))
          .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
      } else {
        handleAlertCall(AlertSeverityEnum.INFO, AlertMessagesEnum.INFO);
      }
    }
  }

  return (
    <Modal open={open}>
      <Box className={classes.container}>
        <Grid container maxWidth={'xl'}>
          <Grid item xs={11}>
            <Typography className={classes.title}>
              {context === 'create-consultation' ? 'Adicionar consulta pública:' : 'Editar consulta pública'}
            </Typography>
          </Grid>
          <Grid sx={{ textAlign: 'right' }} item xs={1}>
            <Button
              className={classes.closeIcon}
              onClick={() => {
                onClose();
                setValidation({} as ValidationProps);
              }}
            >
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item md={4}>
            <InputMask
              value={numeroConsulta}
              onBlur={() =>
                numeroConsulta === '___/____'
                  ? setValidation({ ...validation, numeroConsulta: true })
                  : setValidation({ ...validation, numeroConsulta: false })
              }
              mask={'999/9999'}
              onChange={event => setNumeroConsulta(event.target.value)}
            >
              {() => (
                <TextField
                  required
                  placeholder="000/AAAA"
                  fullWidth
                  label={'Número da consulta'}
                  variant={'outlined'}
                  error={validation.numeroConsulta}
                  helperText={validation.numeroConsulta ? 'Campo obrigatório' : null}
                  className={classes.labelColor}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item md={4}>
            <DesktopDatePicker
              label="Data de Abertura"
              value={dataAbertura}
              onChange={newValue => {
                setDataAbertura(newValue);
              }}
              renderInput={params => <TextField required className={classes.labelColor} fullWidth {...params} />}
            />
          </Grid>
          <Grid item md={4}>
            <DesktopDatePicker
              label="Data de Conclusão"
              value={dataConclusao}
              onChange={newValue => {
                setDataConclusao(newValue);
              }}
              renderInput={params => <TextField required className={classes.labelColor} fullWidth {...params} />}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.gridContainer}>
          <Grid item md={12}>
            <TextField
              required
              onBlur={() =>
                ementa === ''
                  ? setValidation({ ...validation, ementa: true })
                  : setValidation({ ...validation, ementa: false })
              }
              error={validation.ementa}
              helperText={validation.ementa ? 'Campo obrigatório' : null}
              multiline
              fullWidth
              className={classes.labelColor}
              label="Ementa"
              variant="outlined"
              value={ementa}
              onChange={event => setEmenta(event.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={8} mt={2}>
            <TextField
              required
              onBlur={() =>
                ementaLink === ''
                  ? setValidation({ ...validation, linkEmenta: true })
                  : setValidation({ ...validation, linkEmenta: false })
              }
              error={validation.linkEmenta}
              helperText={validation.linkEmenta ? 'Campo obrigatório' : null}
              multiline
              fullWidth
              className={classes.labelColor}
              label="Link da ementa"
              variant="outlined"
              value={ementaLink}
              onChange={event => setEmentaLink(event.target.value)}
            />
          </Grid>
          <Grid item md={4} mt={2}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ color: 'black' }} position="start">
                    <span>R$</span>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment sx={{ color: 'black' }} position="end">
                    <span>,00</span>
                  </InputAdornment>
                )
              }}
              fullWidth
              className={classes.labelColor}
              label="Valor envolvido"
              variant="outlined"
              value={valorEnvolvido}
              onChange={handleCheckValue}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={8} mt={2}>
            <Autocomplete
              multiple
              id="tags-filled"
              options={Object.keys(TypeOrgan).map(key => key)}
              freeSolo
              onChange={(_event, value) => {
                setOrgan(value);
              }}
              value={organ}
              renderTags={(value, getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={params => (
                <TextField
                  {...params}
                  fullWidth
                  required
                  variant="outlined"
                  label="Órgão"
                  className={classes.labelColor}
                />
              )}
            />
          </Grid>

          <Grid item md={4} mt={2}>
            <FormControl fullWidth required>
              <InputLabel id="prioridade" className={classes.formControlLabelColor}>
                Prioridade
              </InputLabel>
              <Select
                labelId="prioridade"
                id="prioridade-select"
                value={prioridade}
                label="Prioridade"
                onChange={event => setPrioridade(event.target.value)}
              >
                <MenuItem value={'ALTA'}>Alta</MenuItem>
                <MenuItem value={'MEDIA'}>Média</MenuItem>
                <MenuItem value={'BAIXA'}>Baixa</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={8} mt={2}>
            <TextField
              required
              onBlur={() =>
                responsavel === ''
                  ? setValidation({ ...validation, responsavel: true })
                  : setValidation({ ...validation, responsavel: false })
              }
              error={validation.responsavel}
              helperText={validation.responsavel ? 'Campo obrigatório' : null}
              multiline
              fullWidth
              className={classes.labelColor}
              label="Responsável"
              variant="outlined"
              value={responsavel}
              onChange={event => setResponsavel(event.target.value)}
            />
          </Grid>

          <Grid item md={4} mt={2}>
            <FormControl fullWidth required>
              <InputLabel id="status" className={classes.formControlLabelColor}>
                Status
              </InputLabel>
              <Select
                labelId="status"
                id="status-select"
                value={status}
                label="Status"
                onChange={event => setStatus(event.target.value)}
              >
                <MenuItem value={'ABERTA'}>Aberta</MenuItem>
                <MenuItem value={'FECHADA'}>Fechada</MenuItem>
                <MenuItem value={'REABERTA'}>Reaberta</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={6} mt={2}>
            <TextField
              multiline
              fullWidth
              className={classes.labelColor}
              label="Contribuição"
              variant="outlined"
              value={contribuicao}
              onChange={event => setContribuicao(event.target.value)}
            />
          </Grid>
          <Grid item md={6} mt={2}>
            <TextField
              multiline
              fullWidth
              className={classes.labelColor}
              label="Link da contribuição"
              variant="outlined"
              value={contribuicaoLink}
              onChange={event => setContribuicaoLink(event.target.value)}
            />
          </Grid>
        </Grid>
        <Typography mt="1rem" sx={{ fontSize: '0.8rem' }}>
          Campos com o * são obrigatórios.
        </Typography>
        <Box sx={{ mt: '1.5rem', display: 'flex', gap: '1rem', justifyContent: 'end' }}>
          <Button
            sx={{ boxShadow: 'none' }}
            variant="contained"
            color="error"
            onClick={() => {
              onClose();
              setValidation({} as ValidationProps);
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleCreateConsultation();
            }}
            sx={{ boxShadow: 'none' }}
            variant="contained"
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
