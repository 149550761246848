import { ativoSiglaCluster } from '../../../../interfaces/Dapr/interfaceDapr';
import {
  StringColumnOperationalSituation,
  StringColumnSituationIssue,
  StringColumnTypeDapr
} from '../../../../util/FormatterDetailsDAPRs';
import { StringFarolColumn } from '../../../molecules/Columns/utils/ColumnsFormatting';

type DataDaprNotFormat = {
  id: string;
  situacaoOperacional: string;
  tipo: string;
  statusFarol: string;
  situacaoDaEmissao: string;
  linkDapr: string;
  numeroDePendencias: number;
  ativosOperacionais: ativoSiglaCluster[];
};

export type DataDaprFormat = {
  id: string;
  situacaoOperacional: string;
  tipo: string;
  statusFarol: string;
  situacaoDaEmissao: string;
  linkDapr: string;
  numeroDePendencias: number;
  usina: string;
  cluster: string;
};

export function FormatTableDapr(datas: DataDaprNotFormat[]) {
  let dataFormatDapr: DataDaprFormat[] = [];
  datas.forEach(item => {
    item.tipo = StringColumnTypeDapr(item.tipo);
    item.statusFarol = StringFarolColumn(item.statusFarol);
    item.situacaoDaEmissao = StringColumnSituationIssue(item.situacaoDaEmissao);
    item.ativosOperacionais.map(itemAtivo => {
      dataFormatDapr.push({
        ...item,
        usina: itemAtivo.siglaDoAtivo,
        cluster: itemAtivo.cluster2,
        situacaoOperacional: StringColumnOperationalSituation(itemAtivo.situacaoOperacional)
      });
    });
  });

  return dataFormatDapr;
}
