import { GridColDef } from '@mui/x-data-grid';
import { KeywordAcronym } from '../../Keywords/components/KeywordsAcronym/KeywordAcronym';
import { EditDeleteButtons } from '../../Columns/utils/EditDeleteButtons';

export const ColumnKeywords: GridColDef[] = [
  {
    field: 'ativosOperacionais',
    headerName: 'Ativo',
    renderCell: cellValues => <KeywordAcronym value={cellValues.value} />,
    flex: 0.2,
    align: 'center'
  },
  {
    field: 'palavra',
    headerName: 'Palavra-chave',
    flex: 0.5,
    align: 'center'
  },
  {
    type: 'singleSelect',
    field: 'opcoes',
    headerName: 'Opções',
    flex: 0.2,
    renderCell: cellValues => <EditDeleteButtons id={`${cellValues.row.id}`} context="keywords" />
  }
];
