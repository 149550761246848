import {
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  Box,
  Stack,
  Button,
  TextField
} from '@mui/material';
import InputMask from 'react-input-mask';
import { createEditActiveModalStyle } from '../CreateEditActiveModalStyle';
import { PropsFormData } from '../Type';
import { TypeOperationalSituation, Cluster2 } from '../../../../../interfaces/Active/enumActive';
import { useFormContext } from '../hook/FormActiveProvider';
import { useEffect, useState } from 'react';

export function FormMainData({ onClose }: PropsFormData): JSX.Element {
  const {
    handleInput,
    handleSelectSituacaoOperacional,
    handleSelectCluster2,
    formClear,
    mainDataEnableOrDisable,
    dataForm,
    continueForm,
    cegOnly
  } = useFormContext();
  const classes = createEditActiveModalStyle();

  function handleOnClose() {
    formClear();
    onClose();
  }

  return (
    <>
      <h4>Dados Principais</h4>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputMask mask="aaa.aa.aa.999999-9.99" value={dataForm.ceg} onChange={event => handleInput('ceg', event)}>
              {() => (
                <TextField
                  label={'CEG *'}
                  variant={'outlined'}
                  className={cegOnly ? classes.inputPrazoError : classes.labelColor}
                />
              )}
            </InputMask>
            <div className={cegOnly ? classes.helpTextError : classes.helpTextNone}>
              Essa ceg já existe no banco de dados
            </div>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Nome Antigo</InputLabel>
            <OutlinedInput
              value={dataForm.nomeAntigo}
              type="text"
              onChange={event => handleInput('nomeAntigo', event)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Situação Operacional *</InputLabel>
            <Select
              value={dataForm.situacaoOperacional}
              onChange={({ target }) => handleSelectSituacaoOperacional(target.value as TypeOperationalSituation)}
            >
              <MenuItem value={TypeOperationalSituation.EM_CONSTRUCAO}>Em construção</MenuItem>
              <MenuItem value={TypeOperationalSituation.EM_TESTE}>Em teste</MenuItem>
              <MenuItem value={TypeOperationalSituation.EM_OPERACAO}>Em operação</MenuItem>
              <MenuItem value={TypeOperationalSituation.EM_PRE_IMPLANTACAO}>Em pré-implantação</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Sigla Do Ativo *</InputLabel>
            <OutlinedInput
              value={dataForm.siglaDoAtivo}
              type="text"
              onChange={event => handleInput('siglaDoAtivo', event)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>CEG Resumido</InputLabel>
            <OutlinedInput type="text" value={dataForm.cegResumido} disabled />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Cluster ONS</InputLabel>
            <OutlinedInput
              value={dataForm.clusterONS}
              type="text"
              onChange={event => handleInput('clusterONS', event)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Cluster 1 *</InputLabel>
            <OutlinedInput value={dataForm.cluster1} type="text" onChange={event => handleInput('cluster1', event)} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Cluster 2 *</InputLabel>
            <Select value={dataForm.cluster2} onChange={({ target }) => handleSelectCluster2(target.value as Cluster2)}>
              <MenuItem value={Cluster2.BAHIA}>Bahia</MenuItem>
              <MenuItem value={Cluster2.CHUI}>Chuí</MenuItem>
              <MenuItem value={Cluster2.DELTA}>Delta</MenuItem>
              <MenuItem value={Cluster2.SUDESTE}>Sudeste</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box className={classes.containerButton}>
        <Stack direction="row" spacing={2}>
          <Button onClick={() => handleOnClose()} variant="contained" color="error">
            Cancelar
          </Button>
          <Button disabled={mainDataEnableOrDisable} onClick={() => continueForm()} variant="contained">
            Continuar
          </Button>
        </Stack>
      </Box>
    </>
  );
}
