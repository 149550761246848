import { Box, IconButton, TextField } from '@mui/material';
import { GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { useStyles } from './TableToolbarStyle';

import { TableToolbarProps } from './Type';
import { ToggleEditDelete } from '../../atoms';

export function CustomToolbar({ clearSearch, onChange, value, hasAddModal }: TableToolbarProps): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.customToolbar}>
      <Box className={classes.toolbarBox}>
        {hasAddModal}
        <GridToolbarColumnsButton />
      </Box>
      <Box className={classes.searchBox}>
        <GridToolbarFilterButton className={classes.filterButton} />
        <TextField
          value={value}
          onChange={onChange}
          placeholder="Pesquisar..."
          size="small"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: value ? 'visible' : 'hidden' }}
                onClick={clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }}
        />

        <ToggleEditDelete />
      </Box>
    </Box>
  );
}
