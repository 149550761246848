import { Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack, Button } from '@mui/material';
import { TypeOfAccessed } from '../../../../../interfaces/Active/enumActive';
import { createEditActiveModalStyle } from '../CreateEditActiveModalStyle';
import { useFormContext } from '../hook/FormActiveProvider';
import { PropsFormData } from '../Type';

export function Connection({ onClose }: PropsFormData): JSX.Element {
  const classes = createEditActiveModalStyle();
  const {
    dataForm,
    handleInput,
    handleSelectTipoDoAcessado,
    handleDecimalNumbersThreePlaces,
    formClear,
    continueForm,
    connectionDataEnableOrDisable
  } = useFormContext();
  function handleOnClose() {
    formClear();
    onClose();
  }
  return (
    <>
      <h4>Conexão</h4>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Ponto De Conexão *</InputLabel>
            <OutlinedInput
              type="text"
              value={dataForm.pontoDeConexao}
              onChange={event => handleInput('pontoDeConexao', event)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Tensão Do Ponto De Conexão kV *</InputLabel>
            <OutlinedInput
              type="number"
              value={dataForm.tensaoDoPontoDeConexao}
              onChange={event => handleInput('tensaoDoPontoDeConexao', event)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Tipo De Acessado *</InputLabel>
            <Select
              value={dataForm.tipoDeAcessado}
              onChange={({ target }) => handleSelectTipoDoAcessado(target.value as TypeOfAccessed)}
            >
              <MenuItem value={TypeOfAccessed.TRANSMISSORA}>Transmissão</MenuItem>
              <MenuItem value={TypeOfAccessed.DISTRIBUIDORA}>Distribuição</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Agente Acessado *</InputLabel>
            <OutlinedInput
              type="text"
              value={dataForm.agenteAcessado}
              onChange={event => handleInput('agenteAcessado', event)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Parecer De Acesso *</InputLabel>
            <OutlinedInput
              type="text"
              value={dataForm.parecerDeAcesso}
              onChange={event => handleInput('parecerDeAcesso', event)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Link Parecer De Acesso</InputLabel>
            <OutlinedInput
              type="url"
              value={dataForm.linkParecerDeAcesso}
              onChange={event => handleInput('linkParecerDeAcesso', event)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>MUST/MUSD MW *</InputLabel>
            <OutlinedInput
              type="number"
              value={dataForm.mustMusd}
              onChange={event => handleDecimalNumbersThreePlaces('mustMusd', event)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>CUST/CUSD *</InputLabel>
            <OutlinedInput type="text" value={dataForm.custCusd} onChange={event => handleInput('custCusd', event)} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Link CUST/CUSD</InputLabel>
            <OutlinedInput
              type="url"
              value={dataForm.linkCustCusd}
              onChange={event => handleInput('linkCustCusd', event)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>CCT/CCD *</InputLabel>
            <OutlinedInput type="text" value={dataForm.cctCcd} onChange={event => handleInput('cctCcd', event)} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Link CCT/CCD</InputLabel>
            <OutlinedInput
              type="url"
              value={dataForm.linkCctCcd}
              onChange={event => handleInput('linkCctCcd', event)}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box className={classes.containerButton}>
        <Stack direction="row" spacing={2}>
          <Button onClick={() => handleOnClose()} variant="contained" color="error">
            Cancelar
          </Button>
          <Button disabled={connectionDataEnableOrDisable} onClick={continueForm} variant="contained">
            Continuar
          </Button>
        </Stack>
      </Box>
    </>
  );
}
