import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Collapse, Container, Grid, Zoom } from '@mui/material';
import { useQuery } from 'react-query';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { useEffect, useState } from 'react';
import { InterfacePendency } from '../../../../interfaces/Pendency/interfacePendency';
import { FormPendencia } from '../../FormPendencia/FormPendencia';
import { Pendencia } from '../../Pendencia/Pendencia';
import {
  StringColumnOperationalSituation,
  StringColumnSituationIssue,
  StringColumnTypeDapr
} from '../../../../util/FormatterDetailsDAPRs';
import { getPendencyById, newPendency } from '../../../../services/Service';
import { useSetEditable } from '../../../../hooks/useSetEditable/useSetEditable';
import { DetailingDaprModalProps } from './Type';

export function DetailingDaprModal({ open, onClose, id }: DetailingDaprModalProps): JSX.Element {
  const { editable } = useSetEditable();

  let pendencia: InterfacePendency | undefined = undefined;
  const { data } = useQuery<InterfacePendency, Error>(['pendency', { id: id }], () => getPendencyById(id));
  pendencia = data;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeNewPendencia = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (open === false) {
      closeNewPendencia();
    }
  });

  return (
    <div>
      <Modal open={open} aria-labelledby="detalhamento-dapr" aria-describedby="detalhamento-dapr">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            maxHeight: '100%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            alignSelf: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Grid container maxWidth={'xl'} justifyContent="space-between">
            <Grid item xs={11}>
              <Typography id="modal-dapr-title" variant="h5" fontWeight="bold" component="h2" paddingBottom={4}>
                Detalhamento de DAPRs
              </Typography>
            </Grid>
            <Grid sx={{ textAlign: 'right' }} item xs={1}>
              <Button sx={{ minWidth: 0, padding: 0 }} onClick={onClose}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography fontWeight="bold">Usinas:</Typography>
              {pendencia?.ativosOperacionais.map(ativoOperacional => ativoOperacional.siglaDoAtivo).join(' / ')}
            </Grid>
            <Grid item xs={3}>
              <Typography fontWeight="bold">Situação operacional:</Typography>
              {pendencia?.ativosOperacionais
                .map(ativoOperacional => StringColumnOperationalSituation(ativoOperacional.situacaoOperacional))
                .join(' / ')}
            </Grid>
            <Grid item xs={3}>
              <Typography fontWeight="bold">Tipo:</Typography>
              {pendencia && StringColumnTypeDapr(pendencia?.tipo)}
            </Grid>
            <Grid item xs={3}>
              <Typography fontWeight="bold">Cluster:</Typography>
              {pendencia?.ativosOperacionais.map(ativoOperacional => ativoOperacional.cluster2).join(' / ')}
            </Grid>
          </Grid>

          {/* Segunda linha */}

          <Grid container spacing={1} sx={{ marginTop: '24px' }}>
            <Grid item xs={12}>
              <Typography fontWeight="bold">Situação da Emissão:</Typography>
              {pendencia && StringColumnSituationIssue(pendencia?.situacaoDaEmissao)}
            </Grid>
            <Container
              disableGutters
              maxWidth={false}
              sx={{ display: editable ? 'flex' : 'none', gap: '1rem', justifyContent: 'flex-end' }}
            >
              <Button
                startIcon={<AddIcon />}
                onClick={() => setIsOpen(true)}
                disabled={!editable || pendencia?.pendencias.length == 3}
              >
                Adicionar pendências
              </Button>
            </Container>
          </Grid>
          <Container
            maxWidth="xl"
            sx={{
              marginTop: '1rem',
              paddingBottom: '48px',
              bgcolor: '#F5F5F5',
              maxHeight: '375px',
              height: '100%',
              overflowY: 'scroll'
            }}
          >
            <Collapse in={isOpen} sx={{ display: editable ? 'flex' : 'none' }}>
              <FormPendencia
                title="Registro de nova pendência"
                requestPendencia={newPendency}
                id={id}
                onClose={closeNewPendencia}
              />
            </Collapse>

            {pendencia?.pendencias?.map(pendency => {
              return (
                <Pendencia
                  key={pendency.id}
                  id={pendency.id}
                  queryId={id}
                  descricao={pendency.descricao}
                  prazoPendencia={pendency.prazoPendencia}
                  statusPlanoDeAcao={pendency.statusPlanoDeAcao}
                  prazoPlanoAcao={pendency.prazoPlanoAcao}
                  createAt={pendency.createAt}
                  updateAt={pendency.updateAt}
                />
              );
            })}
          </Container>
        </Box>
      </Modal>
    </div>
  );
}
