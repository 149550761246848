import { useQuery } from 'react-query';
import { InterfaceKeywords } from '../../../interfaces/Keywords/interfaceKeywords';
import { getKeywords } from '../../../services/Service';
import { LoadingError, LoadingProgress } from '../../../util/ProgressStatus';
import { ToggleEditDelete } from '../../atoms';
import { ColumnKeywords } from '../../molecules/DataGridTable/Columns/ColumnKeywords';
import { Keywords } from '../../molecules/Keywords/Keywords';
import Navigation from '../Navigation/Navigation';

export default function KeywordsPage(): JSX.Element {
  const { data, isLoading, error } = useQuery<InterfaceKeywords[], Error>('palavras-chave', getKeywords);

  return (
    <>
      <Navigation headerTitle="Palavras-chave" />
      {error && <LoadingError />}
      {isLoading || !data ? (
        <LoadingProgress />
      ) : (
        <Keywords dataRows={data} dataColumns={ColumnKeywords} isEditable={<ToggleEditDelete />} />
      )}
    </>
  );
}
