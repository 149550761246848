import { DesktopDatePicker } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { useState } from 'react';
import { TypeOrgan } from '../../../interfaces/PublicConsultations/enumPublicConsultations';
import { FilterProps, PublicConsultationsFilterContentProps } from '../PublicConsultationsBase/Type';
import { publicConsultationsFilterContentStyle } from './PublicConsultationsFilterContentStyle';

export function PublicConsultationsFilterContent({ clear, setFilterData }: PublicConsultationsFilterContentProps) {
  const classes = publicConsultationsFilterContentStyle();
  const [responsavel, setResponsavel] = useState('');
  const [status, setStatus] = useState('');
  const [prioridade, setPrioridade] = useState('');
  const [prazo, setPrazo] = useState<Date | null>(null);
  const [organ, setOrgan] = useState<string[]>([]);

  const filterData: FilterProps = {
    responsavel: responsavel,
    prazo: prazo,
    status: status,
    prioridade: prioridade,
    organ: organ.join(', ')
  };

  const limpar = () => {
    setResponsavel('');
    setStatus('');
    setPrioridade('');
    setPrazo(null);
    setOrgan([]);
    clear();
  };
  return (
    <Stack spacing={2} m="1rem 1rem 0 1rem">
      <TextField
        fullWidth
        className={classes.labelColor}
        id="input-responsavel"
        label="Responsável"
        variant="outlined"
        size="small"
        value={responsavel}
        onChange={event => setResponsavel(event.target.value)}
      />

      <FormControl fullWidth size="small">
        <InputLabel id="input-status-label" className={classes.formControlLabelColor}>
          Status
        </InputLabel>
        <Select
          labelId="input-status-label"
          id="input-status"
          value={status}
          label="status"
          onChange={event => setStatus(event.target.value)}
        >
          <MenuItem value={'ABERTA'}>Aberta</MenuItem>
          <MenuItem value={'FECHADA'}>Fechada</MenuItem>
          <MenuItem value={'REABERTA'}>Reaberta</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth size="small">
        <Autocomplete
          multiple
          id="tags-filled"
          options={Object.keys(TypeOrgan).map(key => key)}
          freeSolo
          onChange={(_event, value) => {
            setOrgan(value);
          }}
          value={organ}
          renderTags={(value, getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              size="small"
              label="Órgãos"
              className={classes.labelColor}
            />
          )}
        />
      </FormControl>

      <FormControl fullWidth size="small">
        <InputLabel id="input-prioridade-label" className={classes.formControlLabelColor}>
          Prioridade
        </InputLabel>
        <Select
          labelId="input-prioridade-label"
          id="input-prioridade"
          value={prioridade}
          label="prioridade"
          onChange={event => setPrioridade(event.target.value)}
        >
          <MenuItem value={'ALTA'}>Alta</MenuItem>
          <MenuItem value={'MEDIA'}>Média</MenuItem>
          <MenuItem value={'BAIXA'}>Baixa</MenuItem>
        </Select>
      </FormControl>

      <DesktopDatePicker
        label="Prazo"
        inputFormat="dd/MM/yyyy"
        value={prazo}
        onChange={newValue => {
          setPrazo(newValue!);
        }}
        renderInput={params => <TextField fullWidth size="small" className={classes.labelColor} {...params} />}
      />

      <Box display="flex" justifyContent="end">
        <Button
          color="warning"
          onClick={() => {
            limpar();
          }}
        >
          Limpar
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            setFilterData(filterData);
          }}
        >
          Aplicar
        </Button>
      </Box>
    </Stack>
  );
}
