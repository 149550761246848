import { RegulatoryPrecedentsBase } from '../../molecules/RegulatoryPrecedentsBase/RegulatoryPrecedentsBase';
import Navigation from '../Navigation/Navigation';
import { RegulatoryPrecedentsProps } from './Type';

export function RegulatoryPrecedents({ title }: RegulatoryPrecedentsProps) {
  return (
    <>
      <Navigation headerTitle={title} />
      <RegulatoryPrecedentsBase />
    </>
  );
}
