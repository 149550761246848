import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Autocomplete, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useQuery } from 'react-query';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { useEffect, useState } from 'react';
import { InterfaceKeywords } from '../../../../interfaces/Keywords/interfaceKeywords';
import { useAlerts } from '../../../../hooks/useAlerts/useAlerts';
import { useGetAcronyms } from '../../../../hooks/useGetAcronyms/useGetAcronyms';
import { queryClient } from '../../../../services/QueryClient';
import { AlertMessagesEnum, AlertSeverityEnum } from '../../../../hooks/useAlerts/useAlertsEnums';
import { getKeywordById, updateKeyword } from '../../../../services/Service';
import { EditKeywordModalProps } from './Type';

export function EditKeywordModal({ open, id, onClose }: EditKeywordModalProps): JSX.Element {
  const { data } = useQuery<InterfaceKeywords, Error>(['keyword', { id: id }], () => getKeywordById(id));

  const { handleAlertCall } = useAlerts();
  const { acronyms } = useGetAcronyms();

  const [keywordLabel, setKeywordLabel] = useState('');
  let previousLabel = data?.palavra;

  const [active, setActive] = useState([]);

  const [keyword, setKeyword] = useState(data?.ativosOperacionais);

  useEffect(() => {
    setKeywordLabel(String(data?.palavra));
    setKeyword(data?.ativosOperacionais);
    queryClient.invalidateQueries(['keyword', { id: id }]);
  }, [open]);

  function handleDelete(id: string) {
    let arrayFiltered = keyword?.filter(ativo => ativo.id !== id);
    return arrayFiltered as [];
  }

  function handleSave() {
    if (keywordLabel != '') {
      let newActives = active.concat(keyword as []);

      const data = {
        palavra: keywordLabel,
        ativosOperacionais: newActives
      };
      updateKeyword(id, data)
        .then(() => handleAlertCall(AlertSeverityEnum.SUCCESS, AlertMessagesEnum.SUCCESS))
        .then(() => setActive([]))
        .then(onClose)
        .then(() => {
          queryClient.invalidateQueries('palavras-chave');
          queryClient.invalidateQueries(['keyword', { id: id }]);
        })
        .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
    } else {
      handleAlertCall(AlertSeverityEnum.INFO, AlertMessagesEnum.INFO);
    }
  }

  return (
    <div>
      <Modal open={open} aria-labelledby="edicao-palavras-chave" aria-describedby="edicao-palavras-chave">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            maxWidth: '100%',
            maxHeight: '95vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            alignSelf: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Grid container maxWidth={'xl'} display="flex">
            <Grid item xs={11}>
              <Typography id="modal-dapr-title" variant="h5" fontWeight="bold" component="h2" paddingBottom={4}>
                Edição de palavras-chave:
              </Typography>
            </Grid>
            <Grid sx={{ textAlign: 'right' }} item xs={1}>
              <Button sx={{ minWidth: 0, padding: 0 }} onClick={onClose}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid container maxWidth={'xl'} justifyContent="space-between" gap={'2rem'}>
            <Grid item>
              <Typography sx={{ marginBottom: '1rem' }}>Editar palavra:</Typography>
              <TextField
                fullWidth
                InputLabelProps={{
                  style: { color: '#000' }
                }}
                label={previousLabel}
                variant="outlined"
                onChange={value => {
                  setKeywordLabel(value.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <Typography sx={{ marginBottom: '1rem' }}>Ativos operacionais relacionados:</Typography>
              {keyword?.length == 0 ? (
                <Box
                  sx={{
                    border: '1px solid #ccc',
                    height: '3.5rem',
                    borderRadius: '.3rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Typography>Não há ativos relacionados</Typography>
                </Box>
              ) : null}
              <Box sx={{ maxHeight: '15rem', overflowY: 'auto', paddingRight: '1rem', marginBottom: '1rem' }}>
                {keyword?.map(value => {
                  return (
                    <Box
                      key={value.id}
                      sx={{
                        display: 'flex',
                        gap: '1rem',
                        marginBottom: '1rem',
                        justifyContent: 'start',
                        paddingLeft: '0',
                        paddingRight: '0'
                      }}
                    >
                      <TextField
                        disabled={true}
                        fullWidth
                        InputLabelProps={{
                          style: { color: '#000' }
                        }}
                        id="palavra"
                        label={value.siglaDoAtivo}
                        variant="outlined"
                      ></TextField>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          setKeyword(handleDelete(value.id));
                        }}
                      >
                        <CloseIcon />
                      </Button>
                    </Box>
                  );
                })}
              </Box>
              <Typography sx={{ marginBottom: '1rem' }}>Adicionar ativos relacionados:</Typography>
              <Autocomplete
                id="listaAtivos"
                fullWidth
                limitTags={35}
                multiple
                onChange={(event, value) => setActive(value as [])}
                options={acronyms ? acronyms : []}
                getOptionLabel={option => option?.siglaDoAtivo}
                color="primary"
                renderInput={params => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      style: { color: '#000' }
                    }}
                    label="Ativos"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              onClick={() => {
                handleSave();
              }}
              startIcon={<SaveIcon />}
              sx={{ marginTop: '1rem', height: '3.5rem' }}
              variant="contained"
            >
              Salvar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
