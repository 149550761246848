import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { accordionGenericStyles } from './AccordionGenericStyles';
import { AccordionGenericProps } from './Type';

export function AccordionGeneric({ title, children, open, identifier }: AccordionGenericProps) {
  const classes = accordionGenericStyles();
  return (
    <Accordion defaultExpanded={open} className={classes.accordion}>
      <AccordionSummary
        sx={{ m: '1rem', height: '5rem' }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={identifier}
        id={identifier}
      >
        <Typography className={classes.title}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
