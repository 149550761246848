import { DesktopDatePicker } from '@mui/lab';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { SourceActive } from '../../../interfaces/Active/enumActive';
import { activesFilterStyles } from './ActivesFilterStyles';
import { ActivesFilterContentProps, FilterProps } from './Type';

export function ActivesFilterContent({ setFilterData, clear, close }: ActivesFilterContentProps) {
  const classes = activesFilterStyles();

  const [sigla, setSigla] = useState('');
  const [cluster, setCluster] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [uf, setUf] = useState('');
  const [fonte, setFonte] = useState('');
  const [potencia, setPotencia] = useState('');
  const [gf, setGf] = useState('');
  const [conexao, setConexao] = useState('');
  const [tensao, setTensao] = useState('');
  const [fimOutorga, setFimOutorga] = useState<Date | null>(null);

  const filterData: FilterProps = {
    siglaDoAtivo: sigla,
    cluster2: cluster,
    empresa: empresa,
    uf: uf,
    fonte: fonte,
    potencia: potencia,
    gf: gf,
    pontoDeConexao: conexao,
    tensaoDoPontoDeConexao: tensao,
    fimOutorga: fimOutorga
  };

  const limpar = () => {
    setSigla('');
    setCluster('');
    setEmpresa('');
    setUf('');
    setFonte('');
    setPotencia('');
    setGf('');
    setConexao('');
    setTensao('');
    setFimOutorga(null);
    clear();
  };
  return (
    <Stack spacing={2} m="1rem 1rem 0 1rem">
      <TextField
        fullWidth
        className={classes.labelColor}
        id="input-sigla"
        label="Sigla do Ativo"
        variant="outlined"
        size="small"
        value={sigla}
        onChange={event => setSigla(event.target.value)}
      />

      <FormControl fullWidth size="small">
        <InputLabel id="input-fonte-label" className={classes.formControlLabelColor}>
          Fonte
        </InputLabel>
        <Select
          labelId="input-fonte-label"
          id="input-fonte"
          value={fonte}
          label="fonte"
          onChange={event => setFonte(event.target.value)}
        >
          <MenuItem value={SourceActive.EOL}>EOL</MenuItem>
          <MenuItem value={SourceActive.PCH}>PCH</MenuItem>
          <MenuItem value={SourceActive.UFV}>UFV</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth size="small">
        <InputLabel id="input-cluster2-label" className={classes.formControlLabelColor}>
          Cluster 2
        </InputLabel>
        <Select
          labelId="input-cluster2-label"
          id="input-cluster"
          value={cluster}
          label="cluster 2"
          onChange={event => setCluster(event.target.value)}
        >
          <MenuItem value={'Bahia'}>Bahia</MenuItem>
          <MenuItem value={'Chuí'}>Chuí</MenuItem>
          <MenuItem value={'Delta'}>Delta</MenuItem>
          <MenuItem value={'Sudeste'}>Sudeste</MenuItem>
        </Select>
      </FormControl>

      <TextField
        fullWidth
        className={classes.labelColor}
        id="input-empresa"
        label="Empresa"
        variant="outlined"
        size="small"
        value={empresa}
        onChange={event => setEmpresa(event.target.value)}
      />

      <FormControl fullWidth size="small">
        <InputLabel id="input-uf-label" className={classes.formControlLabelColor}>
          UF
        </InputLabel>
        <Select
          labelId="input-uf-label"
          id="input-uf"
          value={uf}
          label="uf"
          onChange={event => setUf(event.target.value)}
        >
          <MenuItem value={'AC'}>AC</MenuItem>
          <MenuItem value={'AL'}>AL</MenuItem>
          <MenuItem value={'AP'}>AP</MenuItem>
          <MenuItem value={'AM'}>AM</MenuItem>
          <MenuItem value={'BA'}>BA</MenuItem>
          <MenuItem value={'CE'}>CE</MenuItem>
          <MenuItem value={'DF'}>DF</MenuItem>
          <MenuItem value={'ES'}>ES</MenuItem>
          <MenuItem value={'GO'}>GO</MenuItem>
          <MenuItem value={'MA'}>MA</MenuItem>
          <MenuItem value={'MT'}>MT</MenuItem>
          <MenuItem value={'MS'}>MS</MenuItem>
          <MenuItem value={'MG'}>MG</MenuItem>
          <MenuItem value={'PA'}>PA</MenuItem>
          <MenuItem value={'PB'}>PB</MenuItem>
          <MenuItem value={'PR'}>PR</MenuItem>
          <MenuItem value={'PE'}>PE</MenuItem>
          <MenuItem value={'PI'}>PI</MenuItem>
          <MenuItem value={'RJ'}>RJ</MenuItem>
          <MenuItem value={'RN'}>RN</MenuItem>
          <MenuItem value={'RS'}>RS</MenuItem>
          <MenuItem value={'RO'}>RO</MenuItem>
          <MenuItem value={'RR'}>RR</MenuItem>
          <MenuItem value={'SC'}>SC</MenuItem>
          <MenuItem value={'SP'}>SP</MenuItem>
          <MenuItem value={'SE'}>SE</MenuItem>
          <MenuItem value={'TO'}>TO</MenuItem>
        </Select>
      </FormControl>

      <TextField
        fullWidth
        className={classes.labelColor}
        id="input-potencia"
        label="Potência"
        variant="outlined"
        size="small"
        value={potencia}
        onChange={event => setPotencia(event.target.value)}
      />

      <TextField
        fullWidth
        className={classes.labelColor}
        id="input-gf"
        label="GF"
        variant="outlined"
        size="small"
        value={gf}
        onChange={event => setGf(event.target.value)}
      />

      <TextField
        fullWidth
        className={classes.labelColor}
        id="input-conexao"
        label="Ponto de conexão"
        variant="outlined"
        size="small"
        value={conexao}
        onChange={event => setConexao(event.target.value)}
      />

      <TextField
        fullWidth
        className={classes.labelColor}
        id="input-tensao"
        label="Tensão"
        variant="outlined"
        size="small"
        value={tensao}
        onChange={event => setTensao(event.target.value)}
      />

      <DesktopDatePicker
        label="Fim da outorga"
        inputFormat="dd/MM/yyyy"
        value={fimOutorga}
        onChange={newValue => {
          setFimOutorga(newValue!);
        }}
        renderInput={params => <TextField fullWidth size="small" className={classes.labelColor} {...params} />}
      />

      <Box display="flex" justifyContent="end">
        <Button
          color="warning"
          onClick={() => {
            limpar();
          }}
        >
          Limpar
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            setFilterData(filterData);
          }}
        >
          Aplicar
        </Button>
      </Box>
    </Stack>
  );
}
