import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const baseGenerationStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    minWidth: '95%',
    backgroundColor: theme.palette.background.paper,
    marginTop: '1rem',
    padding: '1.2rem'
  },
  title: {
    paddingTop: '1rem',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: '22.5%'
  }
}));
