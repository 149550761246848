import { InterfaceMapPrecedent, InterfaceMapPrecedentFormat } from '../interfaces/MapPrecedent/interfaceMapPrecedent';
import { DateFormatter } from './DateFormatter';

function String(value: string) {
  const Formatado = value.replace(/\\n/g, '');
  return Formatado;
}

function FormatWordsCapitalized(value: any) {
  const capitalized = value[0].toUpperCase() + value.substr(1);
  return capitalized;
}

function FormatWords(value: any) {
  const moreCommonWords = Object.entries(value).slice(0, 11);
  const result = moreCommonWords.map(([key, value], index) => {
    return <span key={`${key}-${value}-${index}`}>{`${FormatWordsCapitalized(key)}${' - '}${value}`}</span>;
  });

  return result;
}

export function FormatterMapPrecedent(data: InterfaceMapPrecedent[]): InterfaceMapPrecedentFormat[] {
  const formattedData = data.map((item: InterfaceMapPrecedent) => {
    return {
      ...item,

      processo: String(item.processo),

      data: DateFormatter(item.data),

      relator: String(item.relator),

      assunto: String(item.assunto),

      areaResponsavel: String(item.areaResponsavel),

      interessado: String(item.interessado),

      dispositivo: String(item.dispositivo),

      palavrasJSX: FormatWords(item.palavras)
    };
  });
  return formattedData;
}
