import { Box, Grid, Typography, Accordion, AccordionDetails, AccordionSummary, Link } from '@mui/material';
import { accordionRegulatoryPrecedentsStyles } from './AccordionRegulatoryPrecedentsStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { InterfaceMapPrecedentFormat } from '../../../../interfaces/MapPrecedent/interfaceMapPrecedent';

export interface AccordionRegulatoryPrecedentsProps {
  data: InterfaceMapPrecedentFormat[];
}

export function AccordionRegulatoryPrecedents({ data }: AccordionRegulatoryPrecedentsProps) {
  const classes = accordionRegulatoryPrecedentsStyles();

  const [expanded, setExpanded] = useState<string>('');

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  const heighGridSummary = (value: string) => {
    return expanded === value ? { height: '' } : { height: '7.8rem' };
  };

  const heighTypography = (value: string) => {
    return expanded === value ? { height: '100' } : { height: '6rem' };
  };

  return (
    <Box className={classes.box}>
      {data.map(item => (
        <Accordion
          key={item.id}
          className={classes.accordion}
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
        >
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={item.id}
            id={item.id}
          >
            <Grid
              container
              justifyContent="space-between"
              className={classes.gridSummary}
              sx={heighGridSummary(item.id)}
            >
              <Grid item xs={8.2}>
                <Typography>
                  Processo Nº{' '}
                  <Link
                    target="_blank"
                    href={`${process.env.REACT_APP_API_URL}voto/download/${item.id}/${item.filename}`}
                    className={classes.link}
                  >
                    {item.processo}
                  </Link>
                </Typography>
                <Typography>
                  Relator: <span>{item.relator}</span>
                </Typography>
                <Typography>
                  Assunto:
                  <span> {item.assunto}</span>
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography>
                  Data: <span>{item.data}</span>
                </Typography>
                <Typography className={classes.typography} sx={heighTypography(item.id)}>
                  {item.palavrasJSX.map(item => item)}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingTop: '0rem' }}>
            <Grid container className={classes.grid}>
              <Grid item xs={8}>
                <Typography>
                  Área Responsavel: <span> {item.areaResponsavel}</span>
                </Typography>
                <Typography>
                  Interessado: <span>{item.interessado}</span>
                </Typography>
                <Typography>
                  Dispositivo: <span>{item.dispositivo}</span>
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
