import { DesktopDatePicker } from '@mui/lab';
import { Box, Button, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { DateFormatter } from '../../../util/DateFormatter';
import { regulatoryPrecedentsFilterStyle } from './RegulatoryPrecedentsFilterStyle';
import { FilterProps, RegulatoryPrecedentsFilterContentProps } from './Type';

export function RegulatoryPrecedentsFilterContent({
  clear,
  setFilterData,
  close
}: RegulatoryPrecedentsFilterContentProps) {
  const classes = regulatoryPrecedentsFilterStyle();
  const [relator, setRelator] = useState('');
  const [area, setArea] = useState('');
  const [interessado, setInteressado] = useState('');
  const [data, setData] = useState<Date | null>(null);

  const filterData: FilterProps = {
    relator: relator,
    area: area,
    interessado: interessado,
    data: data
  };

  const limpar = () => {
    setRelator('');
    setArea('');
    setInteressado('');
    setData(null);
    clear();
  };

  return (
    <Stack spacing={2} m="1rem 1rem 0 1rem">
      <TextField
        fullWidth
        className={classes.labelColor}
        id="input-relator"
        label="Relator"
        variant="outlined"
        size="small"
        value={relator}
        onChange={event => setRelator(event.target.value)}
      />

      <TextField
        fullWidth
        className={classes.labelColor}
        id="input-area"
        label="Área Responsável"
        variant="outlined"
        size="small"
        value={area}
        onChange={event => setArea(event.target.value)}
      />

      <TextField
        fullWidth
        className={classes.labelColor}
        id="input-interessado"
        label="Interessado"
        variant="outlined"
        size="small"
        value={interessado}
        onChange={event => setInteressado(event.target.value)}
      />

      <DesktopDatePicker
        label="Data"
        inputFormat="dd/MM/yyyy"
        value={data}
        onChange={newValue => {
          setData(newValue!);
        }}
        renderInput={params => <TextField fullWidth size="small" className={classes.labelColor} {...params} />}
      />

      <Box display="flex" justifyContent="end">
        <Button
          color="warning"
          onClick={() => {
            limpar();
          }}
        >
          Limpar
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            setFilterData(filterData);
            close();
          }}
        >
          Aplicar
        </Button>
      </Box>
    </Stack>
  );
}
