import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const segmentedActivityStyle = makeStyles<Theme>((theme: Theme) => ({
  box: {
    margin: '1.8rem 3.1rem',
    borderBottom: '1px solid #D5DBE4',
    paddingBottom: '0.8rem',
    overflow: 'hidden'
  },
  subtitulo: {
    textAlign: 'left',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    fontSize: '18px',
    left: '300px',
    marginBottom: '5px'
  },
  siglas: {
    maxWidth: '150ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    '&::after': {
      content: '"..."'
    },
    '&:hover, &::after': {
      whiteSpace: 'normal',
      content: '"."'
    }
  },
  activityBox: {
    display: 'flex',
    margin: '30px  auto 0px ',
    height: '100%',
    width: '95%'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));
