import { styled } from '@mui/system';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';

export const Tab = styled(TabUnstyled)`
  color: #46505a;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  min-height: 60px;
  border: none;
  margin: 0;
  padding: 0;

  &:hover {
    color: #fff;
    background-color: #5979f2;
  }

  &.${tabUnstyledClasses.selected} {
    color: #fff;
    background-color: #5979f2;
  }
`;
export const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-size: 0.875rem;
`;

export const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  min-height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  border: 1px solid;
  border-color: #46505a;
  margin-bottom: 16px;
  padding: 0;
  outline: none;
  border-color: #c4c4c4;
  border-radius: 4px;
`;
