import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField
} from '@mui/material';
import { useState } from 'react';
import { useAlerts } from '../../../hooks/useAlerts/useAlerts';
import { queryClient } from '../../../services/QueryClient';

import { formPendenciaStyle } from './FormPendenciaStyles';
import { FormPendenciaProps } from './Type';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { AlertMessagesEnum, AlertSeverityEnum } from '../../../hooks/useAlerts/useAlertsEnums';

export function FormPendencia({
  title,
  pendenciaData,
  requestPendencia,
  id,
  onClose
}: FormPendenciaProps): JSX.Element {
  const classes = formPendenciaStyle();

  const { handleAlertCall } = useAlerts();

  const [description, setDescription] = useState(pendenciaData ? pendenciaData.descricao : '');
  const [status, setStatus] = useState(pendenciaData ? pendenciaData.statusPlanoDeAcao : '');
  const [deadline, setDeadline] = useState<Date | null>(
    pendenciaData ? (pendenciaData.prazoPlanoAcao ? new Date(pendenciaData.prazoPlanoAcao) : null) : null
  );
  const [expirationDate, setExpirationDate] = useState<Date | null>(
    pendenciaData ? (pendenciaData.prazoPendencia ? new Date(pendenciaData.prazoPendencia) : null) : null
  );

  function handleSavePendency() {
    if (description !== '' && status !== '' && expirationDate !== null) {
      const data = {
        descricao: description,
        prazoPendencia: expirationDate,
        statusPlanoDeAcao: status,
        prazoPlanoAcao: deadline
      };
      requestPendencia(data, id)
        .then(() => handleAlertCall(AlertSeverityEnum.SUCCESS, AlertMessagesEnum.SUCCESS))
        .then(() => queryClient.invalidateQueries('pendency'))
        .then(() => queryClient.invalidateQueries('DAPRs'))
        .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
      handleCancelarPendency();
    } else {
      handleAlertCall(AlertSeverityEnum.INFO, AlertMessagesEnum.INFO);
    }
  }

  function handleCancelarPendency() {
    onClose();
    setDescription(pendenciaData ? pendenciaData.descricao : '');
    setDeadline(pendenciaData ? (pendenciaData.prazoPlanoAcao ? new Date(pendenciaData.prazoPlanoAcao) : null) : null);
    setStatus(pendenciaData ? pendenciaData.statusPlanoDeAcao : '');
    setExpirationDate(
      pendenciaData ? (pendenciaData.prazoPendencia ? new Date(pendenciaData.prazoPendencia) : null) : null
    );
  }

  return (
    <Container className={classes.container}>
      <h3>{title}</h3>
      <Grid container direction="row" spacing={4}>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            required
            fullWidth
            label="Descrição"
            variant="outlined"
            value={description}
            className={classes.labelColor}
            onChange={value => {
              setDescription(value.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel className={classes.formControlLabelColor}>Status</InputLabel>
            <Select
              required
              value={status}
              label="Status *"
              onChange={(event: SelectChangeEvent) => setStatus(event.target.value)}
            >
              <MenuItem value={'NAO_HA'}>Não há</MenuItem>
              <MenuItem value={'PARALISADO'}>Paralisado</MenuItem>
              <MenuItem value={'EM_ANDAMENTO'}>Em andamento</MenuItem>
              <MenuItem value={'FORA_DO_PRAZO'}>Fora do prazo</MenuItem>
              <MenuItem value={'CONCLUIDO'}>Concluído</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <DesktopDatePicker
            inputFormat="dd/MM/yyyy"
            label="Prazo do plano de ação"
            value={deadline}
            onChange={newValue => {
              setDeadline(newValue);
            }}
            renderInput={params => <TextField fullWidth className={classes.labelColor} {...params} />}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <DesktopDatePicker
            inputFormat="dd/MM/yyyy"
            label="Data de expiração"
            value={expirationDate}
            onChange={newValue => {
              setExpirationDate(newValue);
            }}
            renderInput={params => <TextField fullWidth required className={classes.labelColor} {...params} />}
          />
        </Grid>
      </Grid>

      <Stack direction="row" spacing={3} sx={{ marginTop: '1rem', justifyContent: 'flex-end' }}>
        <Button color="error" sx={{ height: '3rem' }} variant="contained" onClick={handleCancelarPendency}>
          Cancelar
        </Button>
        <Button sx={{ height: '3rem' }} variant="contained" onClick={handleSavePendency}>
          Salvar
        </Button>
      </Stack>
    </Container>
  );
}
