import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const menuButtonStyle = makeStyles<Theme>((theme: Theme) => ({
  card: {
    minWidth: '185px',
    minHeight: '185px',
    margin: '0px 10px 0px 10px',
    maxWidth: '185px',
    maxHeight: '185px'
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  },
  iconDiv: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  icon: {
    fontSize: '50px',
    marginTop: '20px',
    marginBottom: '20px'
  },
  cardTitle: {
    fontSize: '20px',
    textAlign: 'center',
    wordWrap: 'break-word'
  }
}));
