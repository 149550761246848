import { Button, Menu } from '@mui/material';
import { useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { SegmentedActivityFilterProps } from './Type';
import { SegmentedActivityFilterContent } from './SegmentedActivityFilterContent';
import { segmentedActivityFilterStyles } from './SegmentedActivityFilterStyles';

export function SegmentedActivityFilter({ setFilterData, clear, siglas }: SegmentedActivityFilterProps) {
  const classes = segmentedActivityFilterStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        color="secondary"
        startIcon={<FilterListIcon />}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Filtros
      </Button>
      <Menu
        disableEnforceFocus
        className={classes.menuOverflow}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <SegmentedActivityFilterContent setFilterData={setFilterData} clear={clear} siglas={siglas} />
      </Menu>
    </div>
  );
}
