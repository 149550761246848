import { Button, Container } from '@mui/material';
import { useSetEditable } from '../../../../hooks/useSetEditable/useSetEditable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';

import { EditDaprModal } from '../../Modal/EditDaprModal/EditDaprModal';
import { DeleteDialog } from '../../Modal/DeleteDialog/DeleteDialog';
import { deleteDapr } from '../../../../services/dapr/dapr';
import { deleteKeyword } from '../../../../services/Service';
import { queryClient } from '../../../../services/QueryClient';
import { useAlerts } from '../../../../hooks/useAlerts/useAlerts';
import { AlertSeverityEnum, AlertMessagesEnum } from '../../../../hooks/useAlerts/useAlertsEnums';
import { ColumnProps } from './Type';
import { EditKeywordModal } from '../../Modal/EditKeywordModal/EditKeywordModal';

export function EditDeleteButtons({ id, context }: ColumnProps) {
  const [openDelete, setDeleteOpen] = useState(false);
  const { handleAlertCall } = useAlerts();
  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const [openEdit, setEditOpen] = useState(false);
  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleDeleteDapr = () => {
    deleteDapr(id)
      .then(() => queryClient.invalidateQueries('DAPRs'))
      .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
  };

  const handleDeleteKeyword = () => {
    deleteKeyword(id)
      .then(() => queryClient.invalidateQueries('palavras-chave'))
      .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
  };

  const { editable } = useSetEditable();
  return context === 'daprs' ? (
    <>
      <Container disableGutters sx={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
        <Button
          disabled={!editable}
          onClick={handleEditOpen}
          color="warning"
          startIcon={<EditIcon fontSize="inherit" />}
        >
          Editar
        </Button>
        <Button
          disabled={!editable}
          onClick={handleDeleteOpen}
          color="error"
          startIcon={<DeleteIcon fontSize="inherit" />}
        >
          Excluir
        </Button>
      </Container>
      <DeleteDialog
        open={openDelete}
        onClose={handleDeleteClose}
        handleDelete={handleDeleteDapr}
        text="Se você excluir, todos os dados desse DAPR também serão excluídos."
      />
      <EditDaprModal open={openEdit} onClose={handleEditClose} id={id} />
    </>
  ) : (
    <>
      <Container disableGutters sx={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
        <Button
          disabled={!editable}
          onClick={handleEditOpen}
          color="warning"
          startIcon={<EditIcon fontSize="inherit" />}
        >
          Editar
        </Button>
        <Button
          disabled={!editable}
          onClick={handleDeleteOpen}
          color="error"
          startIcon={<DeleteIcon fontSize="inherit" />}
        >
          Excluir
        </Button>
      </Container>
      <DeleteDialog
        open={openDelete}
        onClose={handleDeleteClose}
        handleDelete={handleDeleteKeyword}
        text=" Se você excluir, todos os relacionamentos dessa palavra também serão excluídos no processo."
      />
      <EditKeywordModal open={openEdit} onClose={handleEditClose} id={id} />
    </>
  );
}
