import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const dialogStyle = makeStyles<Theme>((theme: Theme) => ({
  dialogBox: {
    '& .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop': {
      backgroundColor: 'rgba(0,0,0, 0.5)'
    },
    '& .css-gbocc2-MuiPaper-root-MuiDialog-paper': {
      boxShadow: 'none'
    }
  },
  dialogContentcolor: {
    color: theme.palette.text.primary
  }
}));
