import { Button, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { CreateDaprModal } from '../../molecules/Modal/CreateDaprModal/CreateDaprModal';
import { addDaprButtonStyles } from './AddDaprButtonStyles';
import { useAuth } from '../../../hooks/useAuth/useAuth';

type AddElementButtonProps = {
  element: string;
};

export function AddDaprButton({ element }: AddElementButtonProps) {
  const [open, setOpen] = useState(false);
  const { editor } = useAuth();
  function setModalOpen() {
    setOpen(true);
  }
  function setModalClose() {
    setOpen(false);
  }
  const classes = addDaprButtonStyles();
  return (
    <>
      <Tooltip title={editor ? '' : 'Você não tem permissão para esta ação'}>
        <div>
          <Button
            disabled={!editor}
            sx={{ mr: 8 }}
            onClick={() => setModalOpen()}
            className={editor ? '' : classes.disabled}
          >
            <AddIcon sx={{ mr: 1 }} className={editor ? '' : classes.disabled} />
            <Typography className={editor ? '' : classes.disabled} fontSize={'0.8125rem'} fontWeight={500}>
              Adicionar {element}
            </Typography>
          </Button>
        </div>
      </Tooltip>
      <CreateDaprModal open={open} onClose={() => setModalClose()} />
    </>
  );
}
