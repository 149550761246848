import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { navigationStyle } from './NavigationStyle';
import Header from '../../molecules/Header/Header';
import MenuLateral from '../../molecules/MenuLateral/MenuLateral';

type NavigationProps = {
  headerTitle: string;
};

export default function Navigation({ headerTitle }: NavigationProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const classes = navigationStyle();
  return (
    <Box className={classes.navigationBox}>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Header title={headerTitle} setOpen={setOpen} />
        <MenuLateral open={open} setOpen={setOpen} />
      </AppBar>
    </Box>
  );
}
