import { BaseGeneration } from '../components/organisms/BaseGeneration/BaseGeneration';
import Navigation from '../components/organisms/Navigation/Navigation';

export default function DocumentsPage(): JSX.Element {
  return (
    <>
      <Navigation headerTitle="Base Geração Brasil" />

      <BaseGeneration />
    </>
  );
}
