export function SingleSelect(Enum: any) {
  const arrayObjects = [];

  for (const [propertyKey, propertyValue] of Object.entries(Enum)) {
    if (!Number.isNaN(Number(propertyKey))) {
      continue;
    }
    arrayObjects.push(String(propertyValue));
  }

  return arrayObjects;
}
