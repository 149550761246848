import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const CommentActivityStyles = makeStyles<Theme>((theme: Theme) => ({
  containerCommet: {
    width: '98%',
    marginTop: '1rem',
    marginLeft: '0.2rem'
  },

  containerButton: {
    display: 'flex',
    '& .MuiButton-root': {
      height: '2.5rem'
    }
  },

  labelColor: {
    '& .MuiInputLabel-formControl': {
      color: theme.palette.text.primary
    }
  }
}));
