import { Box } from '@mui/material';
import { useStyles } from './DataGridTableStyle';
import React from 'react';
import { DataGrid, GridRowData, ptBR } from '@mui/x-data-grid';
import { CustomToolbar } from '../TableToolbar/TableToolbar';
import { PropsTable } from './Type';

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export const DataGridTable = ({ dataRows, dataColumns, isEditable, hasAddModal }: PropsTable) => {
  const classes = useStyles();

  const [searchText, setSearchText] = React.useState('');
  const [rows, setRows] = React.useState<GridRowData[]>(dataRows);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);

    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = dataRows.filter((row: GridRowData) => {
      return Object.keys(row).some((field: any) => {
        if (row[field] != null) {
          return searchRegex.test(row[field].toString());
        }
      });
    });

    setRows(filteredRows);
  };

  React.useEffect(() => {
    setRows(dataRows);
  }, [dataRows]);

  return (
    <Box className={classes.container}>
      <DataGrid
        className={classes.root}
        components={{
          Toolbar: CustomToolbar
        }}
        rows={rows}
        columns={dataColumns}
        headerHeight={70}
        disableColumnMenu={true}
        hideFooter={rows.length > 100 ? false : true}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(''),

            hasAddModal: hasAddModal
          }
        }}
      />
    </Box>
  );
};
