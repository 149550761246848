import { Alert, AlertColor, Snackbar } from '@mui/material';
import { useState, ReactNode, useContext } from 'react';
import { AlertContext } from './useAlertsContext';
import { AlertSeverityEnum } from './useAlertsEnums';

interface MessageProps {
  children: ReactNode;
}

export function AlertProvider({ children }: MessageProps) {
  const [openMessage, setMessageOpen] = useState(false);

  const [messageSeverity, setMessageSeverity] = useState<AlertColor>();
  const [message, setMessage] = useState('');

  const handleAlertCall = (context: AlertSeverityEnum, messageText: string) => {
    setMessageOpen(true);
    setMessage(messageText);
    setMessageSeverity(context);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessageOpen(false);
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openMessage}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={messageSeverity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <AlertContext.Provider
        value={{
          openMessage,
          handleAlertCall,
          handleClose
        }}
      >
        {children}
      </AlertContext.Provider>
    </>
  );
}

export function useAlerts() {
  const context = useContext(AlertContext);
  return context;
}
