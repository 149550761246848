import Modal from '@mui/material/Modal';
import { CreateEditActivityModalProps } from './Type';
import { createEditActivityModalStyle } from './CreateEditActivityModalStyle';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { useContext, useState, useEffect } from 'react';
import { useAlerts } from '../../../../hooks/useAlerts/useAlerts';
import { createActivity, updateActivity } from '../../../../services/activityManagement/activityManagement';
import { InterfaceSiglaDoAtivoCluster2 } from '../../../../interfaces/Pendency/interfacePendency';
import { InputNumber } from '../../../atoms';
import { queryClient } from '../../../../services/QueryClient';
import { ClusterContext } from '../../../organisms/NavigationTabs/NavigationTabs';
import { formatNumber, notFormatNumber } from '../../../../util/FormatterNumber';
import { AlertMessagesEnum, AlertSeverityEnum } from '../../../../hooks/useAlerts/useAlertsEnums';

export function CreateEditActivityModal({
  open,
  onClose,
  cluster,
  data,
  title,
  typeEdit
}: CreateEditActivityModalProps): JSX.Element {
  const classes = createEditActivityModalStyle();
  const clusterContext = useContext(ClusterContext);

  let acronyms: InterfaceSiglaDoAtivoCluster2[] | undefined;

  acronyms = cluster;

  const [mandatory, setMandatory] = useState<boolean>(false);
  const [deadlineMandatory, setDeadlineMandatory] = useState<string | undefined>();
  const [deadline, setDeadline] = useState<Date | null>(null);

  const { handleAlertCall } = useAlerts();

  useEffect(() => {
    setDeadlineMandatory(handleMandatoryDeadline());
  }, [mandatory, deadline]);

  const handleMandatoryDeadline = () => {
    if (mandatory && deadline) {
      return classes.inputPrazoSuccess;
    } else if (mandatory) {
      return classes.inputPrazoError;
    } else {
      return '';
    }
  };

  const [disabled, setDisabled] = useState<boolean>(false);

  const [titleData, setTitleData] = useState<string>('');

  const [dateCreate, setDateCreate] = useState<Date | null>(new Date());
  const [active, setActive] = useState<InterfaceSiglaDoAtivoCluster2[]>([]);
  const [description, setDescription] = useState<string>('');
  const [value, setValue] = useState<string | undefined>();
  const [responsible, setResponsible] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [priority, setPriority] = useState<string>('');

  const handleStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);

    setDisabled(false);

    if (event.target.value === 'ATIVO') {
      setMandatory(true);
    } else {
      setMandatory(false);
    }
  };

  const formClear = (): void => {
    setTitleData('');
    setDeadline(null);
    setDateCreate(new Date());
    setDescription('');
    setValue('');
    setResponsible('');
    setStatus('');
    setPriority('');
    setMandatory(false);
  };

  const handleOnClose = () => {
    onClose();
    formClear();
  };
  const handleValue = (newValue: string) => {
    setValue(newValue);
  };

  function handleActivity() {
    if (status === 'ATIVO' && deadline === null) {
      handleAlertCall(AlertSeverityEnum.INFO, AlertMessagesEnum.INFO);
    }
    if (
      title !== '' &&
      dateCreate !== null &&
      description !== '' &&
      status !== '' &&
      priority !== '' &&
      responsible !== '' &&
      active.length > 0
    ) {
      const dataObj = {
        titulo: titleData,
        data: dateCreate,
        descricao: description,
        valorEnvolvido: value ? +notFormatNumber(value) : null,
        status: status,
        prioridade: priority,
        prazo: deadline,
        responsavel: responsible,
        ativosOperacionais: active.map(ativo => ativo.id)
      };
      if (typeEdit === true && data !== undefined) {
        updateActivity(data.id, dataObj)
          .then(() => handleAlertCall(AlertSeverityEnum.SUCCESS, AlertMessagesEnum.SUCCESS))
          .then(() => queryClient.invalidateQueries(['ActivityManagement', { cluster: clusterContext }]))
          .then(handleOnClose)
          .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
      } else {
        createActivity(dataObj)
          .then(() => handleAlertCall(AlertSeverityEnum.SUCCESS, AlertMessagesEnum.SUCCESS))
          .then(() => queryClient.invalidateQueries(['ActivityManagement', { cluster: clusterContext }]))
          .then(handleOnClose)

          .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
      }
    } else {
      handleAlertCall(AlertSeverityEnum.INFO, AlertMessagesEnum.INFO);
    }
  }
  const [activeDefault, setActiveDefault] = useState<InterfaceSiglaDoAtivoCluster2[]>([]);

  useEffect(() => {
    setTitleData(data ? data.titulo : '');
    setDateCreate(data ? new Date(data.data) : new Date());
    setDescription(data ? data.descricao : '');
    setResponsible(data ? data.responsavel : '');
    setValue(
      data ? (data.valorEnvolvido ? formatNumber(data.valorEnvolvido).replace('..00', '') : undefined) : undefined
    );
    setActive(data ? data.ativosOperacionais : []);
    setActiveDefault(data ? data.ativosOperacionais : []);
    const statusData = data ? data.status : '';
    setStatus(statusData);
    setDisabled(statusData === 'ENCERRADO');
    setPriority(data ? data.prioridade : '');
    setDeadline(data ? (data.prazo ? new Date(data.prazo) : null) : null);
  }, [data, open]);

  return (
    <>
      <Modal open={open} aria-labelledby="activit-create" aria-describedby="activit-create">
        <Box className={classes.container}>
          <Grid container maxWidth={'xl'} className={classes.titleIcon}>
            <Grid item xs={11}>
              <Typography className={classes.title}>{title}</Typography>
            </Grid>
            <Grid sx={{ textAlign: 'right' }} item xs={1}>
              <Button className={classes.closeIcon} onClick={onClose}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                required
                fullWidth
                disabled={disabled}
                type="text"
                value={titleData}
                className={classes.labelColor}
                onChange={event => setTitleData(event.target.value)}
                label="Título"
                multiline
                variant="outlined"
                helperText=" "
              />
            </Grid>

            <Grid item xs={4}>
              <DesktopDatePicker
                label="Data de Criação *"
                value={dateCreate}
                inputFormat="dd/MM/yyyy"
                disabled={disabled}
                onChange={newValue => {
                  setDateCreate(newValue);
                }}
                renderInput={params => (
                  <TextField helperText=" " disabled={disabled} fullWidth className={classes.labelColor} {...params} />
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <DesktopDatePicker
                disabled={disabled}
                label="Prazo"
                value={deadline}
                inputFormat="dd/MM/yyyy"
                onChange={newValue => {
                  setDeadline(newValue);
                }}
                renderInput={params => (
                  <TextField
                    fullWidth
                    disabled={disabled}
                    required={mandatory}
                    className={`${deadlineMandatory} ${classes.labelColor}`}
                    helperText={<span className={disabled ? classes.helperText : ''}>Campo obrigatório</span>}
                    {...params}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.inputContainer}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                value={description}
                className={classes.labelColor}
                disabled={disabled}
                onChange={event => setDescription(event.target.value)}
                minRows={2}
                label="Descrição"
                multiline
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.inputContainer}>
            <Grid item xs={8}>
              <Autocomplete
                disabled={disabled}
                fullWidth
                id="listaAtivos"
                multiple
                defaultValue={[...activeDefault]}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={acronyms ? acronyms : []}
                getOptionLabel={option => option?.siglaDoAtivo}
                onChange={(event, value) => setActive(value as [])}
                renderInput={params => (
                  <TextField
                    {...params}
                    disabled={disabled}
                    label="Ativos envolvidos *"
                    className={classes.labelColor}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <InputNumber value={value} valueUpdate={handleValue} desabilit={disabled} />
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.inputContainer}>
            <Grid item xs={4}>
              <TextField
                required
                fullWidth
                type="text"
                value={responsible}
                className={classes.labelColor}
                disabled={disabled}
                onChange={event => setResponsible(event.target.value)}
                label="Responsável"
                multiline
                variant="outlined"
              />
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel className={classes.formControlLabelColor}>Status*</InputLabel>
                <Select value={status} label="Status " onChange={handleStatus}>
                  <MenuItem value={'ATIVO'}>Ativo</MenuItem>
                  <MenuItem value={'STAND_BY'}>Stand-by</MenuItem>
                  <MenuItem value={'ENCERRADO'}>Encerrado</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel className={classes.formControlLabelColor}>Prioridade* </InputLabel>
                <Select
                  value={priority}
                  disabled={disabled}
                  label="Prioridade"
                  onChange={event => setPriority(event.target.value)}
                >
                  <MenuItem value={'BAIXA'}>Baixa</MenuItem>
                  <MenuItem value={'MEDIA'}>Média</MenuItem>
                  <MenuItem value={'ALTA'}>Alta</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box className={classes.containerButton}>
            <Stack direction="row" spacing={1}>
              <Button variant="contained" color="error" onClick={handleOnClose}>
                Cancelar
              </Button>

              <Button variant="contained" onClick={handleActivity}>
                Salvar
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
