import { useQuery } from 'react-query';
import { InterfaceBaseGeneration } from '../../../interfaces/BaseGeneration/interfaceBaseGeneration';
import { getLogPlanilha } from '../../../services/uploadGenerationBase/uploadGenerationBase';
import { LoadingError, LoadingProgress } from '../../../util/ProgressStatus';

import { WorksheetsUploadModal } from '../Modal/WorksheetsUploadModal/WorksheetsUploadModal';
import { InputUploadSpreadsheet } from './components/InputUploadSpreadsheet';

export function FormUploadBaseGeracao(): JSX.Element {
  const { data, isLoading, error } = useQuery<InterfaceBaseGeneration[], Error>('logBaseGeneration', getLogPlanilha);

  return (
    <>
      {error && <LoadingError />}
      {isLoading || !data ? (
        <LoadingProgress />
      ) : (
        <>
          {data.map(item => (
            <InputUploadSpreadsheet key={item.title} data={item} />
          ))}
        </>
      )}

      <WorksheetsUploadModal />
    </>
  );
}
