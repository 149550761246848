import { IconButton, Tooltip } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { toggleEditDeleteStyle } from './ToggleEditDeleteStyle';
import { useSetEditable } from '../../../hooks/useSetEditable/useSetEditable';
import { useAuth } from '../../../hooks/useAuth/useAuth';

export function ToggleEditDelete(): JSX.Element {
  const { editor } = useAuth();
  const classes = toggleEditDeleteStyle();
  const { editable, setEditableState } = useSetEditable();
  return (
    <>
      <Tooltip title={editor ? '' : 'Você não tem permissão para esta ação'}>
        <div>
          <IconButton disabled={!editor} onClick={setEditableState} className={classes.button} color="secondary">
            {editable ? (
              <LockOpenIcon className={editor ? classes.unlock : classes.disabled} fontSize="small" />
            ) : (
              <LockIcon className={editor ? classes.lock : classes.disabled} fontSize="small" />
            )}
          </IconButton>
        </div>
      </Tooltip>
    </>
  );
}
