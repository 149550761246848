import { ChangeEvent, createContext } from 'react';
import {
  Cluster2,
  SourceActive,
  TypeOfAccessed,
  TypeOfAuction,
  TypeOperationalSituation,
  UF
} from '../../../../../interfaces/Active/enumActive';
import { DataForm } from '../Type';

interface FormContext {
  handleInput: (input: string, event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleSelectSituacaoOperacional: (value: TypeOperationalSituation) => void;
  formClear: () => void;
  cegOnly: boolean;
  updateCegOnly: (value: boolean) => void;
  mainDataEnableOrDisable: boolean;
  companyPlantDataEnableOrDisable: boolean;
  connectionDataEnableOrDisable: boolean;
  auctionDataEnableOrDisable: boolean;
  grantDataEnableOrDisable: boolean;
  dataForm: DataForm;
  valueTabsUnstyled: number;
  continueForm: () => void;
  changeTab(value: string | number): void;
  handleSelectUf: (value: UF) => void;
  handleSelectFonte: (value: SourceActive) => void;
  handleNumbersTwoPlaces: (input: string, event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleSelectTipoDoAcessado: (value: TypeOfAccessed) => void;
  handleSelectTipoDoLeilao: (value: TypeOfAuction) => void;
  handleSelectCluster2: (value: Cluster2) => void;
  handleDate: (input: string, value: Date | null) => void;
  handleDecimalNumbersTwoPlaces: (input: string, event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleDecimalNumbersThreePlaces: (input: string, event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleDuracaoAnos: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleInicioOutorga: (value: Date | null) => void;
  handleInputChip: (input: string, value: string[]) => void;
  IsCpnjValid: boolean;
  setAllDataForm: (data: DataForm) => void;
  setLastTabFinish: React.Dispatch<React.SetStateAction<number>>;
}

export const FormActiveContext = createContext<FormContext>({} as FormContext);
