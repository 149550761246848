import { ProjectsInDevelopment } from '../components/organisms/ProjectsInDevelopment/ProjectsInDevelopment';
import { AlertProvider } from '../hooks/useAlerts/useAlerts';

export default function ProjectsInDevelopmentPage(): JSX.Element {
  return (
    <AlertProvider>
      <ProjectsInDevelopment />
    </AlertProvider>
  );
}
