export const notFormatNumber = (valueNew: string) => {
  let valueFormat: string = valueNew;

  var number = valueFormat.split('.').join('');

  return number;
};

export const formatNumber = (valueNew: any) => {
  let valueInverted = valueNew
    .split('')
    .reverse()
    .join('');
  let formatValue = '';
  for (var n in valueInverted) {
    if (Number(n) % 3 === 0 && n !== '0') {
      formatValue = formatValue + '.' + valueInverted[n];
    } else {
      formatValue = formatValue + valueInverted[n];
    }
  }
  let resultado = formatValue
    .split('')
    .reverse()
    .join('');

  return resultado;
};

export const verifiedNumber = (valueUnFormat: any) => {
  let number = '';
  for (var n in valueUnFormat) {
    if (Number(valueUnFormat[n])) {
      number = number + valueUnFormat[n];
    } else if (valueUnFormat[n] === '0' && n !== '0') {
      if (number.length) {
        number = number + valueUnFormat[n];
      }
    }
  }
  return number;
};

export function moneyFormatter(value: string | number | null) {
  if (value) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(Number(value));
  }
  if (value === 0 || value === '0') {
    return `R$ 0`;
  } else {
    return null;
  }
}
