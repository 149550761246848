import { GridColDef } from '@mui/x-data-grid';
import { TypeOperationalSituationFormat } from '../../../../interfaces/Active/enumActive';
import { StatusFarolFormat, TypeFormat, TypeSituationIssueFormat } from '../../../../interfaces/Dapr/enumDapr';
import { ActionsColumn, LinkColumn, StatusColumn } from '../../Columns/RenderCellColumns';
import { SingleSelect } from '../../Columns/utils/ColumnsSingleSelect';

export const ColumnDapr: GridColDef[] = [
  {
    field: 'cluster',
    headerName: 'Cluster',
    align: 'center',
    flex: 0.5
  },
  {
    field: 'usina',
    headerName: 'Usina',
    flex: 0.7,
    align: 'center'
  },
  {
    type: 'singleSelect',
    valueOptions: SingleSelect(TypeOperationalSituationFormat),
    field: 'situacaoOperacional',
    headerName: 'Situação Operacional',
    flex: 1,
    align: 'center'
  },
  {
    type: 'singleSelect',
    valueOptions: SingleSelect(TypeFormat),
    field: 'tipo',
    headerName: 'Tipo de DAPR',
    flex: 1,
    renderCell: cellValues => (
      <LinkColumn value={cellValues.value != null ? `${cellValues.value}` : ''} link={cellValues.row.linkDapr} />
    )
  },
  {
    type: 'number',
    field: 'numeroDePendencias',
    headerName: 'Número de Pendências',
    flex: 1,
    align: 'center'
  },
  {
    type: 'singleSelect',
    valueOptions: SingleSelect(TypeSituationIssueFormat),
    field: 'situacaoDaEmissao',
    headerName: 'Situação de Emissão',
    flex: 1,
    align: 'center'
  },
  {
    type: 'singleSelect',
    valueOptions: SingleSelect(StatusFarolFormat),
    field: 'statusFarol',
    headerName: 'Status',
    flex: 1.3,
    renderCell: cellValues => <StatusColumn value={`${cellValues.value}`} id={`${cellValues.row.id}`} />
  },
  {
    field: 'action',
    headerName: 'Ações',
    flex: 1,
    renderCell: cellValues => <ActionsColumn id={String(`${cellValues.row.id}`)} value={`${cellValues.value}`} />
  }
];
