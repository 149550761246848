import { Autocomplete, Box, Button, Chip, Container, FormControl, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { RegulatoryPrecedentsFilter } from '../RegulatoryPrecedentsFilter/RegulatoryPrecedentsFilter';
import { regulatoryPrecedentsBaseStyle } from './RegulatoryPrecedentsStyles';
import { useModalController } from '../../../hooks/useModalController/useModalController';
import { RegulatoryPrecedentsUpload } from '../Modal/RegulatoryPrecedentsUpload/RegulatoryPrecedentsUpload';
import { AccordionRegulatoryPrecedents } from './components/AccordionRegulatoryPrecedents';
import { useQuery } from 'react-query';
import { getVote } from '../../../services/Service';
import { InterfaceMapPrecedent } from '../../../interfaces/MapPrecedent/interfaceMapPrecedent';
import { LoadingError, LoadingProgress } from '../../../util/ProgressStatus';
import { FormatterMapPrecedent } from '../../../util/FormatterMapPrecedent';
import { filterArrayResults, searchResults } from '../RegulatoryPrecedentsFilter/RegulatoryPrecedentsFilterResult';
import { useEffect, useState } from 'react';
import { FilterProps } from './Type';
import { formatFilterFields, formatFilterValues } from './formatter';

export function RegulatoryPrecedentsBase() {
  const classes = regulatoryPrecedentsBaseStyle();
  const [search, setSearch] = useState<string[]>([]);
  const { data, isLoading, error } = useQuery<InterfaceMapPrecedent[], Error>('voto', getVote);
  const { regulatoryPrecedentsUploadModalState } = useModalController();
  const [renderData, setRenderData] = useState<InterfaceMapPrecedent[] | undefined>(data);

  const [precedentsFilters, setPrecedentsFilters] = useState({});

  const setFilterData = (value: FilterProps) => {
    setPrecedentsFilters(value as {});
    setRenderData(
      data?.filter(item => {
        return filterArrayResults(value, item);
      })
    );
  };

  const clear = () => {
    setPrecedentsFilters({});
    if (data) {
      setRenderData(data);
    } else {
      setRenderData([]);
    }
  };

  useEffect(() => {
    setRenderData(
      data?.filter(item => {
        return searchResults(item, search);
      })
    );
  }, [data, search]);

  return (
    <>
      <RegulatoryPrecedentsUpload />
      <div className={classes.container}>
        <Container maxWidth={false}>
          <Typography className={classes.title}>Precedentes Regulatórios</Typography>

          <Box
            py={1}
            className={classes.filterBox}
            sx={{
              display: Object.keys(precedentsFilters).length !== 0 ? 'flex' : 'none',
              alignItems: 'center',
              gap: '0.5rem'
            }}
          >
            <Typography fontWeight="bold" sx={{ padding: '1rem' }}>
              Filtros aplicados:
            </Typography>{' '}
            {Object.entries(precedentsFilters).map(([key, value]) => {
              if (value) {
                const render = [
                  <>
                    <strong>{formatFilterFields(key)}</strong>
                    <span>
                      {': '}
                      {formatFilterValues(String(value))}
                    </span>
                  </>
                ];
                return <Chip color="secondary" key={key} label={render[0]} />;
              }
            })}
            <Button sx={{ display: 'inherit' }} onClick={clear}>
              Limpar todos
            </Button>
          </Box>

          {data && (
            <Box className={classes.recordCount}>
              <Typography>
                {data.length} {data.length <= 1 ? ' voto registrado' : ' votos registrados'}
              </Typography>
            </Box>
          )}
          <Box className={classes.toolbar}>
            <div>
              <Button onClick={regulatoryPrecedentsUploadModalState} startIcon={<AddIcon />}>
                Adicionar Documentos
              </Button>
            </div>

            <div className={classes.toolbarItems}>
              <RegulatoryPrecedentsFilter setFilterData={setFilterData} clear={clear} />
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  freeSolo
                  onChange={(_event, value) => {
                    setSearch(value);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label="Pesquisa"
                      className={classes.labelColor}
                    />
                  )}
                  options={[]}
                />
              </FormControl>
            </div>
          </Box>
          <Box>
            {' '}
            {error && <LoadingError />}
            {isLoading || !renderData ? (
              <LoadingProgress />
            ) : (
              <AccordionRegulatoryPrecedents data={FormatterMapPrecedent(renderData)} />
            )}
          </Box>
        </Container>
      </div>
    </>
  );
}
