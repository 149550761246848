import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const EditDaprModalStyle = makeStyles<Theme>((theme: Theme) => ({
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '900px',
    maxWidth: '100%',
    maxHeight: '100%',
    background: theme.palette.background.paper,
    boxShadow: '24',
    padding: '40px',
    alignSelf: 'center',
    justifyContent: 'space-between'
  },

  labelColor: {
    '& .MuiInputLabel-formControl': {
      color: theme.palette.text.primary
    }
  },

  formControlLabelColor: {
    color: theme.palette.text.primary
  }
}));
