import { createContext, useEffect, useState } from 'react';
import { Tab } from '@mui/material';

import { SegmentedActivity } from '../SegmentedActivity/SegmentedActivity';
import { TabContext, TabList } from '@mui/lab';
import { navigationTabsStyle } from './NavigationTabsStyle';
import { useParams } from 'react-router-dom';

export const ClusterContext = createContext('');
export default function NavigationTabs() {
  const [cluster, setCluster] = useState('Chuí');

  const params = useParams() as { id: string; cluster2: string };

  useEffect(() => {
    if (params.id !== undefined && params.cluster2 !== undefined) {
      setCluster(params.cluster2);
    }
  }, []);

  const classes = navigationTabsStyle();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCluster(newValue);
  };

  return (
    <>
      <TabContext value={cluster}>
        <TabList
          className={classes.tabList}
          textColor="secondary"
          indicatorColor="secondary"
          onChange={handleChange}
          aria-label="lab API tabs example"
        >
          <Tab className={classes.tab} label="Chuí" value="Chuí" />
          <Tab className={classes.tab} label="Sudeste" value="Sudeste" />
          <Tab className={classes.tab} label="Bahia" value="Bahia" />
          <Tab className={classes.tab} label="Delta" value="Delta" />
        </TabList>
      </TabContext>
      <ClusterContext.Provider value={cluster}>
        <SegmentedActivity />
      </ClusterContext.Provider>
    </>
  );
}
