import { DesktopDatePicker } from '@mui/lab';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Box,
  Stack,
  Button,
  Chip,
  Autocomplete
} from '@mui/material';
import { TypeOfAuction } from '../../../../../interfaces/Active/enumActive';
import { createEditActiveModalStyle } from '../CreateEditActiveModalStyle';
import { useFormContext } from '../hook/FormActiveProvider';
import { PropsFormData } from '../Type';

export function Auction({ onClose }: PropsFormData): JSX.Element {
  const classes = createEditActiveModalStyle();
  const {
    dataForm,
    handleInput,
    handleSelectTipoDoLeilao,
    handleDate,
    formClear,
    continueForm,
    handleInputChip,
    auctionDataEnableOrDisable
  } = useFormContext();

  const { leiloes } = dataForm;
  function handleOnClose() {
    formClear();
    onClose();
  }
  return (
    <>
      <h4>Leilão</h4>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Mercado *</InputLabel>
            <OutlinedInput type="text" value={dataForm.mercado} onChange={event => handleInput('mercado', event)} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            multiple
            options={[]}
            freeSolo
            value={dataForm.leiloes ? [...dataForm.leiloes] : []}
            onChange={(_event, value) => {
              handleInputChip('leiloes', value);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={params => <TextField {...params} label="Leilão" className={classes.labelColor} />}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            multiple
            options={[]}
            freeSolo
            value={dataForm.editaisDosLeiloes ? [...dataForm.editaisDosLeiloes] : []}
            onChange={(_event, value) => {
              handleInputChip('editaisDosLeiloes', value);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={params => <TextField {...params} label="Edital Do Leilão" className={classes.labelColor} />}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Link Edital Do Leilão</InputLabel>
            <OutlinedInput
              type="url"
              value={dataForm.linkEditalDoLeilao}
              onChange={event => handleInput('linkEditalDoLeilao', event)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Tipo De Leilão</InputLabel>
            <Select
              value={dataForm.tipoDoLeilao}
              onChange={({ target }) => handleSelectTipoDoLeilao(target.value as TypeOfAuction)}
            >
              <MenuItem value={TypeOfAuction.DISPONIBILIDADE}>DISPONIBILIDADE</MenuItem>
              <MenuItem value={TypeOfAuction.QUANTIDADE}>QUANTIDADE</MenuItem>
              <MenuItem value={TypeOfAuction.RESERVA}>RESERVA</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <DesktopDatePicker
            inputFormat="dd/MM/yyyy"
            label="Início Sup. Leilao"
            value={dataForm.inicioSupLeilao}
            onChange={event => handleDate('inicioSupLeilao', event)}
            renderInput={params => <TextField fullWidth className={classes.labelColor} {...params} />}
          />
        </Grid>
        <Grid item xs={4}>
          <DesktopDatePicker
            inputFormat="dd/MM/yyyy"
            label="Fim Sup. Leilao"
            value={dataForm.fimSupLeilao}
            onChange={event => handleDate('fimSupLeilao', event)}
            renderInput={params => <TextField fullWidth className={classes.labelColor} {...params} />}
          />
        </Grid>
      </Grid>
      <Box className={classes.containerButton}>
        <Stack direction="row" spacing={2}>
          <Button onClick={() => handleOnClose()} variant="contained" color="error">
            Cancelar
          </Button>
          <Button disabled={auctionDataEnableOrDisable} onClick={continueForm} variant="contained">
            Continuar
          </Button>
        </Stack>
      </Box>
    </>
  );
}
