import {
  Modal,
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { updateDapr } from '../../../../services/dapr/dapr';
import { useAlerts } from '../../../../hooks/useAlerts/useAlerts';
import { getDaprById } from '../../../../services/dapr/dapr';
import { useQuery } from 'react-query';
import { InterfaceDapr } from '../../../../interfaces/Dapr/interfaceDapr';
import { TypeSituationIssue } from '../../../../interfaces/Dapr/enumDapr';
import { EditDaprModalStyle } from './EditDaprModalStyle';
import { queryClient } from '../../../../services/QueryClient';
import { AlertMessagesEnum, AlertSeverityEnum } from '../../../../hooks/useAlerts/useAlertsEnums';
import classNames from 'classnames';

interface EditDaprModalProps {
  open: boolean;
  onClose: () => void;
  id: string;
}

export function EditDaprModal({ open, onClose, id }: EditDaprModalProps) {
  const classes = EditDaprModalStyle();
  const { data, isLoading, isSuccess } = useQuery<InterfaceDapr>(['dapr', { id: id }], () => getDaprById(id));

  useEffect(() => {
    setLinkDapr(String(data?.linkDapr));
    setSituacaoEmissao(String(data?.situacaoDaEmissao));
  }, [isSuccess]);

  const [linkDapr, setLinkDapr] = useState('');
  const [situacaoEmissao, setSituacaoEmissao] = useState('');
  const { handleAlertCall } = useAlerts();

  function handleEditDapr() {
    const data = {
      linkDapr: linkDapr,
      situacaoDaEmissao: situacaoEmissao
    };

    updateDapr(id, data)
      .then(() => handleAlertCall(AlertSeverityEnum.SUCCESS, AlertMessagesEnum.SUCCESS))
      .then(onClose)
      .then(() => queryClient.invalidateQueries('DAPRs'))
      .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
  }
  const handleOnClose = () => {
    onClose();
    setLinkDapr(String(data?.linkDapr));
    setSituacaoEmissao(String(data?.situacaoDaEmissao));
  };

  return (
    <div>
      <Modal open={open}>
        <Box className={classes.modalBox}>
          <Grid container maxWidth={'xl'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Grid item xs={11}>
              <Typography id="modal-dapr-title" variant="h5" fontWeight="bold" component="h2" paddingBottom={4}>
                Edição de DAPR
              </Typography>
            </Grid>
            <Grid sx={{ textAlign: 'right' }} item xs={1}>
              <Button sx={{ minWidth: 0, padding: 0 }} onClick={onClose}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            maxWidth={'xl'}
            display={'flex'}
            spacing={2}
            alignItems={'center'}
            sx={{ marginBottom: '1rem' }}
          >
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="url"
                onChange={value => {
                  setLinkDapr(value.target.value);
                }}
                className={classes.labelColor}
                label="Link de DAPR"
                variant="outlined"
                value={linkDapr}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel className={classes.formControlLabelColor}>Situação da emissão</InputLabel>
                <Select
                  value={situacaoEmissao}
                  label="Situação da emissão"
                  onChange={value => {
                    setSituacaoEmissao(value.target.value as TypeSituationIssue);
                  }}
                >
                  <MenuItem value={'SOLICITADO'}>Solicitado</MenuItem>
                  <MenuItem value={'ATENDENDO_PENDENCIAS'}>Atendendo pendências</MenuItem>
                  <MenuItem value={'CONCLUIDO'}>Concluído</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'end' }} mt={'1rem'}>
            <Stack direction="row" spacing={1}>
              <Button onClick={handleOnClose} sx={{ height: '3.5rem' }} variant="contained" color="error">
                Cancelar
              </Button>

              <Button onClick={() => handleEditDapr()} sx={{ height: '3.5rem' }} variant="contained">
                Salvar
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
