import { CircularProgress, Container } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

export function LoadingError(): JSX.Element {
  return (
    <Container
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <ErrorIcon color="error" fontSize="large" />
      <p>Erro ao buscar dados</p>
    </Container>
  );
}

export function LoadingProgress(): JSX.Element {
  return (
    <Container
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <CircularProgress />
      <p>Aguardando dados</p>
    </Container>
  );
}
