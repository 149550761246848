import { Tooltip, Container, Typography } from '@mui/material';
import { InterfaceAcronyms } from '../../../../../interfaces/interfaceAcronyms';

export function KeywordAcronym(value: any) {
  const sigla: InterfaceAcronyms[] = value.value;

  const siglaFinal = sigla?.map(siglas => siglas.siglaDoAtivo).join(', ');
  return (
    <Container>
      <Tooltip title={siglaFinal} arrow>
        <Typography
          sx={{
            maxWidth: '30ch',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            position: 'relative',
            display: 'inline-block'
          }}
        >
          {siglaFinal}
        </Typography>
      </Tooltip>
    </Container>
  );
}
