import { PublicConsultationsBase } from '../../molecules/PublicConsultationsBase/PublicConsultationsBase';
import Navigation from '../Navigation/Navigation';
import { PublicConsultationsProps } from './Type';

export function PublicConsultations({ title, data }: PublicConsultationsProps) {
  return (
    <>
      <Navigation headerTitle={title} />
      <PublicConsultationsBase data={data} />
    </>
  );
}
