import { DesktopDatePicker } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { useAlerts } from '../../../../../hooks/useAlerts/useAlerts';
import { AlertSeverityEnum } from '../../../../../hooks/useAlerts/useAlertsEnums';
import { InterfaceSiglaDoAtivoCluster2 } from '../../../../../interfaces/Pendency/interfacePendency';
import { moneyFormatter } from '../../../../../util/FormatterNumber';
import { segmentedActivityFilterStyles } from './SegmentedActivityFilterStyles';
import { FilterProps, SegmentedActivityFilterProps } from './Type';

export function SegmentedActivityFilterContent({ clear, setFilterData, siglas }: SegmentedActivityFilterProps) {
  const classes = segmentedActivityFilterStyles();
  const [responsavel, setResponsavel] = useState('');
  const [dataInicial, setDataInicial] = useState<Date | null>(null);
  const [dataFinal, setDataFinal] = useState<Date | null>(null);
  const [prazoInicial, setPrazoInicial] = useState<Date | null>(null);
  const [prazoFinal, setPrazoFinal] = useState<Date | null>(null);
  const [status, setStatus] = useState('');
  const [prioridade, setPrioridade] = useState('');
  const [ativosEnvolvidos, setAtivosEnvolvidos] = useState<InterfaceSiglaDoAtivoCluster2[]>([]);
  const [valor, setValor] = useState<number[]>([0, 0]);

  const { handleAlertCall } = useAlerts();

  const filterData: FilterProps = {
    responsavel,
    dataInicial,
    dataFinal,
    prazoInicial,
    prazoFinal,
    status,
    prioridade,
    ativosEnvolvidos,
    valor
  };

  const limpar = () => {
    setResponsavel('');
    setDataInicial(null);
    setDataFinal(null);
    setPrazoInicial(null);
    setPrazoFinal(null);
    setStatus('');
    setPrioridade('');
    setAtivosEnvolvidos([]);
    setValor([0, 0]);
    clear();
  };

  const handleSetFilter = () => {
    if ((dataInicial === null && dataFinal) || (dataInicial && dataFinal === null)) {
      handleAlertCall(AlertSeverityEnum.WARNING, 'Você deve oferecer uma data inicial e final');
      return;
    }
    if ((prazoInicial && prazoFinal === null) || (prazoInicial === null && prazoFinal)) {
      handleAlertCall(AlertSeverityEnum.WARNING, 'Você deve oferecer um prazo inicial e final');
      return;
    } else {
      setFilterData(filterData);
    }
  };
  return (
    <Stack spacing={2} sx={{ padding: '0.5rem', minWidth: '400px', maxWidth: '450px' }} m="1rem 1rem 0 1rem">
      <div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DesktopDatePicker
              label="Data Inicial"
              inputFormat="dd/MM/yyyy"
              value={dataInicial}
              onChange={newValue => {
                setDataInicial(newValue!);
              }}
              renderInput={params => <TextField fullWidth size="small" className={classes.labelColor} {...params} />}
            />
          </Grid>
          <Grid item xs={6}>
            <DesktopDatePicker
              label="Data Final"
              inputFormat="dd/MM/yyyy"
              value={dataFinal}
              onChange={newValue => {
                setDataFinal(newValue!);
              }}
              renderInput={params => <TextField fullWidth size="small" className={classes.labelColor} {...params} />}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DesktopDatePicker
              label="Prazo Inicial"
              inputFormat="dd/MM/yyyy"
              value={prazoInicial}
              onChange={newValue => {
                setPrazoInicial(newValue!);
              }}
              renderInput={params => <TextField fullWidth size="small" className={classes.labelColor} {...params} />}
            />
          </Grid>
          <Grid item xs={6}>
            <DesktopDatePicker
              label="Prazo Final"
              inputFormat="dd/MM/yyyy"
              value={prazoFinal}
              onChange={newValue => {
                setPrazoFinal(newValue!);
              }}
              renderInput={params => <TextField fullWidth size="small" className={classes.labelColor} {...params} />}
            />
          </Grid>
        </Grid>
      </div>
      <FormControl fullWidth size="small">
        <Autocomplete
          multiple
          id="tags-filled"
          options={siglas ? siglas : []}
          getOptionLabel={option => option.siglaDoAtivo}
          freeSolo
          onChange={(_event, value) => {
            setAtivosEnvolvidos(value as []);
          }}
          value={ativosEnvolvidos}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              size="small"
              label="Ativos envolvidos"
              className={classes.labelColor}
            />
          )}
        />
      </FormControl>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography mr={2}>Valor:</Typography>
        <Slider
          getAriaLabel={() => 'Valor'}
          value={valor}
          max={10000000000}
          step={10000000}
          valueLabelFormat={moneyFormatter}
          onChange={(event, value) => {
            setValor(value as number[]);
          }}
          valueLabelDisplay="auto"
        />
      </Box>
      <TextField
        fullWidth
        className={classes.labelColor}
        id="input-responsavel"
        label="Responsável"
        variant="outlined"
        size="small"
        value={responsavel}
        onChange={event => setResponsavel(event.target.value)}
      />
      <FormControl fullWidth size="small">
        <InputLabel id="input-status-label" className={classes.formControlLabelColor}>
          Status
        </InputLabel>
        <Select
          labelId="input-status-label"
          id="input-status"
          value={status}
          label="status"
          onChange={event => setStatus(event.target.value)}
        >
          <MenuItem value={'ATIVO'}>Ativo</MenuItem>
          <MenuItem value={'STAND_BY'}>Stand By</MenuItem>
          <MenuItem value={'ENCERRADO'}>Encerrado</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth size="small">
        <InputLabel id="input-prioridade-label" className={classes.formControlLabelColor}>
          Prioridade
        </InputLabel>
        <Select
          labelId="input-prioridade-label"
          id="input-prioridade"
          value={prioridade}
          label="prioridade"
          onChange={event => setPrioridade(event.target.value)}
        >
          <MenuItem value={'ALTA'}>Alta</MenuItem>
          <MenuItem value={'MEDIA'}>Média</MenuItem>
          <MenuItem value={'BAIXA'}>Baixa</MenuItem>
        </Select>
      </FormControl>

      <Box display="flex" justifyContent="end">
        <Button
          color="warning"
          onClick={() => {
            limpar();
          }}
        >
          Limpar
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            handleSetFilter();
          }}
        >
          Aplicar
        </Button>
      </Box>
    </Stack>
  );
}
