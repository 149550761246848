import { Box, Container, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { StringNormalize } from '../../../../../util/FormatterActiveDetails';
import { useStyles } from '../../AccordionActiveDetailingStyles';
import { AccordionDetailsPublicationProps } from './Type';
import { Search } from '../../../../atoms';
import { useSearch } from '../../../../../hooks/useSearch/useSearch';
import { DiaryAneel } from './DiaryAneel';

export function AccordionDetailsPublication({ data }: AccordionDetailsPublicationProps) {
  const classes = useStyles();

  const { busca } = useSearch();

  const filterWords = useMemo(() => {
    const lowerBusca = StringNormalize(busca);
    return {
      diario: data!.materiasDiarioOficial.filter(
        materia =>
          StringNormalize(materia.dataPublicacao).includes(lowerBusca) ||
          StringNormalize(materia.link).includes(lowerBusca) ||
          StringNormalize(materia.sintese).includes(lowerBusca)
      ),
      aneel: data!.aneelPublicacoes.filter(
        materia =>
          StringNormalize(materia.titulo).includes(lowerBusca) ||
          StringNormalize(materia.data).includes(lowerBusca) ||
          StringNormalize(materia.link).includes(lowerBusca) ||
          StringNormalize(materia.noticia).includes(lowerBusca)
      )
    };
  }, [busca]);

  return (
    <Box>
      <Container maxWidth={false} sx={{ display: 'flex', justifyContent: 'end' }}>
        <Search />
      </Container>
      <Stack spacing={2}>
        <Box>
          <Typography sx={{ m: '0 1rem' }} className={classes.title}>
            Diário Oficial da União
          </Typography>
          <Box className={classes.contentContainer}>
            <DiaryAneel data={(filterWords.diario as unknown) as Record<string, string>[]} />
          </Box>
        </Box>
        <Box>
          <Typography sx={{ m: '0 1rem' }} className={classes.title}>
            ANEEL
          </Typography>
          <Box className={classes.contentContainer}>
            <DiaryAneel data={(filterWords.aneel as unknown) as Record<string, string>[]} />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}
