import { Box, Container, Typography } from '@mui/material';
import { useEffect } from 'react';
import { FormUploadBaseGeracao } from '../../molecules/FormUploadBaseGeracao/FormUploadBaseGeracao';
import { ModalUploadProvider } from '../../molecules/FormUploadBaseGeracao/hook/FileUploadModal';
import { baseGenerationStyles } from './BaseGenerationStyles';

export function BaseGeneration(): JSX.Element {
  const classes = baseGenerationStyles();

  return (
    <Container disableGutters className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        Base Geração Brasil
      </Typography>
      <ModalUploadProvider>
        <FormUploadBaseGeracao />
      </ModalUploadProvider>
    </Container>
  );
}
