import { Box, Button, Grid, Stack, TextField, Typography, Zoom } from '@mui/material';
import { ActivityAccordionEditDeleteStyles } from './ActivityAccordionEditDeleteStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ActivityAccordionEditDeleteProps } from './Type';
import { deleteActivityComment, updateActivityComment } from '../../../services/activityComment/activityComment';
import { DeleteDialog } from '../Modal/DeleteDialog/DeleteDialog';
import { useContext, useState } from 'react';
import { DesktopDatePicker } from '@mui/lab';
import { useAlerts } from '../../../hooks/useAlerts/useAlerts';
import { useSetEditable } from '../../../hooks/useSetEditable/useSetEditable';
import { ClusterContext } from '../../organisms/NavigationTabs/NavigationTabs';
import { queryClient } from '../../../services/QueryClient';
import { DateFormatter } from '../../../util/DateFormatter';
import { AlertMessagesEnum, AlertSeverityEnum } from '../../../hooks/useAlerts/useAlertsEnums';

export default function ActivityAccordionEditDelete({ comment, data, id }: ActivityAccordionEditDeleteProps) {
  const classes = ActivityAccordionEditDeleteStyles();

  const clusterContext = useContext(ClusterContext);
  const { handleAlertCall } = useAlerts();

  const [openDelete, setDeleteOpen] = useState(false);
  const [openEdit, setEditOpen] = useState(true);
  const [dateUpdate, setDateUpdate] = useState<Date | null>(new Date(data));
  const [commentUpdate, setCommentUpdate] = useState<string>(comment);

  const { editable } = useSetEditable();
  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleEdit = () => {
    setEditOpen(prev => !prev);
    formClear();
  };

  const handleUpdate = () => {
    if (dateUpdate !== null && commentUpdate !== '') {
      const data = {
        comentario: commentUpdate,
        data: dateUpdate
      };

      updateActivityComment(id, data)
        .then(() => handleAlertCall(AlertSeverityEnum.SUCCESS, AlertMessagesEnum.SUCCESS))
        .then(handleEdit)
        .then(() => queryClient.invalidateQueries(['ActivityManagement', { cluster: clusterContext }]))
        .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
    } else {
      handleAlertCall(AlertSeverityEnum.INFO, AlertMessagesEnum.INFO);
    }
  };

  const handleDelete = () => {
    deleteActivityComment(id)
      .then(() => queryClient.invalidateQueries(['ActivityManagement', { cluster: clusterContext }]))
      .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
  };

  const formClear = (): void => {
    setDateUpdate(new Date(data));
    setCommentUpdate(comment);
  };

  return (
    <>
      <Zoom in={openEdit}>
        <Grid item className={classes.comentContainer}>
          <Typography textAlign={'justify'}>
            <span className={classes.nameDetails}> Atualização: </span>
            {comment}
          </Typography>
          <Typography>
            <span className={classes.nameDetails}>Data: </span>
            {DateFormatter(data)}
          </Typography>

          <Stack direction="row" spacing={1} className={classes.containerButtonComent}>
            <Button
              variant="outlined"
              color="warning"
              startIcon={<EditIcon />}
              disabled={!editable}
              onClick={handleEdit}
            >
              Editar
            </Button>{' '}
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              disabled={!editable}
              onClick={handleDeleteOpen}
            >
              Excluir
            </Button>
          </Stack>
        </Grid>
      </Zoom>
      <Zoom in={openEdit === false}>
        <Box sx={{ display: openEdit === false ? 'inherit' : 'none', width: '100%' }}>
          <Grid container spacing={1} className={classes.commetContainerForm}>
            {' '}
            <Grid item xs={9}>
              <TextField
                required
                fullWidth
                className={classes.labelColor}
                onChange={event => setCommentUpdate(event.target.value)}
                value={commentUpdate}
                minRows={3.5}
                label="Atualização"
                multiline
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              {' '}
              <DesktopDatePicker
                label="Data de Atualização"
                inputFormat="dd/MM/yyyy"
                value={dateUpdate}
                onChange={newValue => {
                  setDateUpdate(newValue);
                }}
                renderInput={params => <TextField fullWidth className={classes.labelColor} {...params} />}
              />
              <Box className={classes.containerButton}>
                <Stack direction="row" spacing={1}>
                  <Button variant="contained" onClick={handleUpdate}>
                    Salvar
                  </Button>
                  <Button variant="outlined" color="error" onClick={handleEdit}>
                    Cancelar
                  </Button>
                </Stack>
              </Box>
            </Grid>{' '}
          </Grid>
        </Box>
      </Zoom>
      <DeleteDialog
        open={openDelete}
        onClose={handleDeleteClose}
        handleDelete={handleDelete}
        text="Essa ação não tem como ser desfeita."
      />
    </>
  );
}
