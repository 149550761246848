export const capitalizeFirstLetter = (word: string) => {
  const lowercase = word.toLowerCase();
  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

export const formatPriority = (priority: string) => {
  let priorityFormatted = capitalizeFirstLetter(priority);
  if (priorityFormatted === 'Media') {
    priorityFormatted = 'Média';
  }
  return priorityFormatted;
};

export const formatValue = (value: string | null) => {
  if (typeof value === 'string') {
    var parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join(',');
  }
};

export const formatStatus = (status: string) => {
  let priorityFormatted = capitalizeFirstLetter(status);
  if (priorityFormatted === 'Stand_by') {
    priorityFormatted = 'Stand by';
  }
  return priorityFormatted;
};
