import { CommentActivityStyles } from './ActivityCommentStyle';
import { useState } from 'react';
import { DesktopDatePicker } from '@mui/lab';
import { Button, Grid, Stack, TextField } from '@mui/material';
import { useAlerts } from '../../../hooks/useAlerts/useAlerts';
import { createActivityComment } from '../../../services/activityComment/activityComment';
import { CommentActivityProps } from './Type';
import { queryClient } from '../../../services/QueryClient';
import { useContext } from 'react';
import { ClusterContext } from '../../organisms/NavigationTabs/NavigationTabs';
import { AlertMessagesEnum, AlertSeverityEnum } from '../../../hooks/useAlerts/useAlertsEnums';

export default function CommentActivity({ id }: CommentActivityProps) {
  const classes = CommentActivityStyles();

  const { handleAlertCall } = useAlerts();

  const clusterContext = useContext(ClusterContext);
  const [dateUpdate, setDateUpdate] = useState<Date | null>(new Date());
  const [comment, setComment] = useState<string>('');

  const handleCreate = () => {
    if (dateUpdate !== null && comment !== '') {
      const data = {
        comentario: comment,
        data: dateUpdate
      };

      createActivityComment(id, data)
        .then(() => handleAlertCall(AlertSeverityEnum.SUCCESS, AlertMessagesEnum.SUCCESS))
        .then(() => queryClient.invalidateQueries(['ActivityManagement', { cluster: clusterContext }]))
        .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
      formClear();
    } else {
      handleAlertCall(AlertSeverityEnum.INFO, AlertMessagesEnum.INFO);
    }
  };

  const formClear = () => {
    setDateUpdate(new Date());
    setComment('');
  };
  return (
    <>
      <Grid container spacing={1} className={classes.containerCommet}>
        {' '}
        <Grid item xs={9}>
          <TextField
            required
            fullWidth
            className={classes.labelColor}
            value={comment}
            onChange={event => setComment(event.target.value)}
            minRows={3.5}
            label="Atualização"
            multiline
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          {' '}
          <DesktopDatePicker
            label="Data de Atualização"
            inputFormat="dd/MM/yyyy"
            value={dateUpdate}
            onChange={newValue => {
              setDateUpdate(newValue);
            }}
            renderInput={params => <TextField fullWidth className={classes.labelColor} {...params} />}
          />
        </Grid>{' '}
      </Grid>

      <Grid container spacing={1} className={classes.containerCommet}>
        <Grid item>
          <Stack direction="row" spacing={1} className={classes.containerButton}>
            <Button variant="outlined" onClick={handleCreate}>
              {' '}
              + Adicionar Atualização
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
