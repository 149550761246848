import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const editConsultationUpdateModalStyle = makeStyles<Theme>((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100%',
    maxHeight: '90%',
    backgroundColor: '#fff',
    boxShadow: '24px',
    padding: '2.5rem',
    alignSelf: 'center',
    justifyContent: 'space-between',
    minWidth: '70vw',
    overflowY: 'auto'
  },

  title: {
    fontWeight: 'bold',
    fontSize: '20px'
  },

  closeIcon: {
    minWidth: '0px',
    padding: '0px'
  },

  labelColor: {
    '& .MuiInputLabel-formControl': {
      color: theme.palette.text.primary
    }
  }
}));
