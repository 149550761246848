import { Box, CircularProgress, Typography } from '@mui/material';
import { useModalUploads } from '../FormUploadBaseGeracao/hook/FileUploadModal';

export function LoadingProgress() {
  const { file } = useModalUploads();

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size="4rem" variant="determinate" color="info" value={file.progress} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {file.progress ? (
          <Typography variant="caption" component="div" color="text.primary" fontSize="1rem">{`${Math.round(
            file.progress
          )}%`}</Typography>
        ) : (
          <Typography variant="caption" component="div" color="text.primary" fontSize="1rem">
            0%
          </Typography>
        )}
      </Box>
    </Box>
  );
}
