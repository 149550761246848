import { useQuery } from 'react-query';
import { PublicConsultations } from '../components/organisms/PublicConsultations/PublicConsultations';
import { InterfacePublicConsultations } from '../interfaces/PublicConsultations/interfacePublicConsultations';
import { getConsultations } from '../services/Service';
import { LoadingError, LoadingProgress } from '../util/ProgressStatus';

export default function DocumentsPage(): JSX.Element {
  const { data, isLoading, error, isSuccess } = useQuery<InterfacePublicConsultations[], Error>(
    'consultations',
    getConsultations
  );
  return (
    <>
      {error && <LoadingError />}
      {isLoading || !data ? <LoadingProgress /> : <PublicConsultations title="Consultas Públicas" data={data} />}
    </>
  );
}
