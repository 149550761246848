import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const accordionDetailsActivityStyles = makeStyles((theme: Theme) => ({
  box: {
    background: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '520px',
    height: '100%',
    overflowY: 'auto',
    margin: '1rem',
    paddingBottom: '1rem'
  },

  boxItem: {
    margin: '1rem 1rem 0rem 1rem',
    background: theme.palette.background.paper,
    padding: '2rem',
    display: 'flex',

    '& div': {
      marginLeft: '1rem',
      '&:nth-child(1)': {
        marginTop: '0rem',
        display: 'flex',

        '& a': {
          fontWeight: 'normal',
          margin: '0rem auto',
          textDecoration: 'none',
          cursor: 'pointer'
        },
        '& p': {
          wordBreak: 'break-all'
        }
      },

      '& p': {
        fontWeight: 'bold',
        textAlign: 'justify',
        '& span': {
          fontWeight: 'normal'
        }
      }
    }
  },

  link: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    display: 'flex',
    width: '4rem',
    padding: '0.1rem',

    '& svg': {
      margin: ' auto 0px'
    }
  }
}));
