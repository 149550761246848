import * as React from 'react';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';

import TableCell from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormatData } from '../../../../util/FormatterActiveDetails';
import { FormatNumberColumn } from '../../Columns/utils/ColumnsFormatting';
import { RowActiveCollapse } from './RowActiveCollapse';
import { InterfaceActive } from '../../../../interfaces/Active/interfaceActive';
import { EditDeleteButtonsGeneric } from '../../ButtonsModals/EditDeleteButtonsGeneric';
import { deleteActive } from '../../../../services/active/active';

export function RowActive({ row }: { row: InterfaceActive }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row" align="center">
          {row.siglaDoAtivo}
        </TableCell>
        <TableCell align="center">{row.fonte}</TableCell>
        <TableCell align="center">{row.cluster2}</TableCell>
        <TableCell align="center">{row.empresa}</TableCell>
        <TableCell align="center">{row.uf}</TableCell>
        <TableCell align="center">{FormatNumberColumn(row.potencia, 2)}</TableCell>
        <TableCell align="center">{FormatNumberColumn(row.gf, 2)}</TableCell>
        <TableCell align="center">{row.pontoDeConexao}</TableCell>
        <TableCell align="center">{row.tensaoDoPontoDeConexao}</TableCell>
        <TableCell align="center">{FormatData(row.fimOutorga)}</TableCell>
        <TableCell>
          <Box sx={{ display: 'flex' }}>
            <EditDeleteButtonsGeneric
              queryKey={'Ativos Operacionais'}
              element={'Ativo'}
              id={row.id ? row.id : ''}
              deleteRequest={deleteActive}
            />
            <Link to={`/detalhamento-ativo/${row.id}`}>
              <Button>
                <ContentPasteSearchIcon />
              </Button>
            </Link>
          </Box>
        </TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="large" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {open ? <RowActiveCollapse open={open} row={row} /> : null}
    </>
  );
}
