import * as React from 'react';
import { menuWrapperStyle } from './MenuWrapperStyle';
import Grid from '@mui/material/Grid';

type MenuWrapperProps = {
  children?: React.ReactChild | React.ReactChild[];
};

export function MenuWrapper({ children }: MenuWrapperProps): JSX.Element {
  const classes = menuWrapperStyle();
  return (
    <Grid container spacing={0} className={classes.grid}>
      {children}
    </Grid>
  );
}
