import { Box, Container, Grid, Link, Stack, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { DisplayLimit } from '../../../../../util/FormatterActiveDetails';
import { useStyles } from '../../AccordionActiveDetailingStyles';
import { DiaryAneelProps } from './Type';

export function DiaryAneel({ data }: DiaryAneelProps) {
  const classes = useStyles();

  return (
    <>
      {data.length === 0 ? (
        <Container disableGutters sx={{ margin: '3rem' }}>
          <Typography variant="h6">Nada consta</Typography>
        </Container>
      ) : (
        data.map(item => {
          return (
            <Box key={item.id} className={classes.contentContainer}>
              <Grid className={classes.grid} container spacing={16} alignItems="center">
                <Grid item lg={4}>
                  <Typography>
                    Publicação: <span>{item.titulo}</span>
                  </Typography>
                </Grid>
                <Grid item lg={4}>
                  <Typography>
                    Link:
                    <span>
                      {''}
                      <Link target="_blank" href={item.link}>
                        {' '}
                        {DisplayLimit(item.link)}
                      </Link>
                    </span>
                  </Typography>
                </Grid>
                <Grid item lg={4} textAlign="center">
                  <Typography>
                    Data:
                    <span> {item.data ? item.data : item.dataPublicacao}</span>
                  </Typography>
                </Grid>
              </Grid>
              <Container disableGutters maxWidth={false} sx={{ paddingTop: '1rem' }}>
                <Grid item lg={12}>
                  {!isNil(item.noticia) ? (
                    <Typography>
                      Notícia: <span>{item.noticia}</span>
                    </Typography>
                  ) : (
                    <Typography>
                      Síntese: <span>{item.sintese}</span>
                    </Typography>
                  )}
                </Grid>
              </Container>
            </Box>
          );
        })
      )}
    </>
  );
}
