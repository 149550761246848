import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Link from '@mui/material/Link';
import { headerStyle } from './HeaderStyle';
import { HeaderProps } from './Type';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';

function handleLogout(instance: IPublicClientApplication) {
  instance.logoutRedirect().catch((e: Event) => {
    console.error(e);
  });
}
export default function Header({ title, setOpen }: HeaderProps): JSX.Element {
  const { instance } = useMsal();
  const classes = headerStyle();
  const history = useHistory();

  return (
    <Toolbar>
      <IconButton onClick={history.goBack} className={classes.menuButton}>
        <ArrowBackIcon />
      </IconButton>
      <IconButton onClick={() => setOpen(true)} className={classes.menuButton}>
        <MenuIcon className={classes.menuIcon} />
      </IconButton>
      <Typography component="div" className={classes.pageTitle}>
        {title}
      </Typography>
      <Typography component="div" position="absolute" className={classes.siteTitle}>
        <Link href="/" className={classes.siteLink}>
          RegulaTech
        </Link>
      </Typography>
      <Button className={classes.logoutButton} onClick={() => handleLogout(instance)}>
        <LogoutIcon />
      </Button>
    </Toolbar>
  );
}
