import { DateTime } from 'luxon';

export const formatFilterFields = (value: string) => {
  type ObjectType = {
    [key: string]: string;
  };

  const formatter: ObjectType = {
    relator: 'Relator',
    area: 'Área Responsável',
    data: 'Data',
    interessado: 'Interessado'
  };

  return formatter[value];
};

export const formatFilterValues = (value: string) => {
  const data = DateTime.fromJSDate(new Date(value)).toFormat('dd/MM/yyyy', { locale: 'pt-br' });
  return data !== 'Invalid DateTime' ? data : value;
};
