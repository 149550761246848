import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    padding: '2rem'
  },

  accordion: {
    minHeight: '100%'
  },

  title: {
    fontWeight: 'bold',
    fontSize: '24px'
  },

  name: {
    fontWeight: 'normal'
  },

  divider: {
    border: `1px solid ${theme.palette.background.default}`
  },

  grid: {
    padding: '1rem',
    display: 'flex',
    '& div': {
      alignItems: 'center',
      '& p': {
        fontSize: '1.2em',
        fontWeight: '600',
        lineHeight: '2em',
        '& span': {
          fontWeight: 'normal'
        }
      }
    }
  },

  contentContainer: {
    maxHeight: '520px',
    margin: '1rem',
    border: `1px solid ${theme.palette.background.default}`,
    borderRadius: '4px',
    height: '100%',
    overflowY: 'auto'
  },

  /*EntryOperation*/
  tableCell: {
    background: theme.palette.secondary.main,
    color: theme.palette.text.secondary
  },

  toggle: {
    display: 'flex',
    justifyContent: 'end',
    marginRight: '2rem'
  },

  buttonEdit: {
    fontSize: '1rem',
    border: `1px solid  ${theme.palette.background.default}`,
    borderRadius: '4px'
  }
}));
