import { Box, Button, Modal, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';

import { LoadingProgress } from './components/LoadingProgress/LoadingProgress';

import { useModalController } from '../../../../hooks/useModalController/useModalController';
import { useAlerts } from '../../../../hooks/useAlerts/useAlerts';
import { AlertSeverityEnum } from '../../../../hooks/useAlerts/useAlertsEnums';
import { regulatoryPrecedentsUploadStyle } from './RegulatoryPrecedentsUploadStyle';
import { RegulatoryPrecedentsUploadField } from '../../RegulatoryPrecedentsUploadField/RegulatoryPrecedentsUploadField';
import { voteUpload } from '../../../../services/Service';

let percentage: number;

export const getPercentage = (value: number) => {
  percentage = value;
};

enum ModalState {
  INITIAL = 'initial',
  SUCCESS = 'success',
  ERROR = 'error'
}

export function RegulatoryPrecedentsUpload() {
  const { regulatoryPrecedentsUploadModal, regulatoryPrecedentsUploadModalState } = useModalController();

  const { handleAlertCall } = useAlerts();

  const classes = regulatoryPrecedentsUploadStyle();

  const [modalState, setModalState] = useState(ModalState.INITIAL);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [totalProgress, setTotalProgress] = useState(0);

  const handleFiles = (value: [] | File[]) => {
    setFiles(value);
  };

  const handleUpload = () => {
    if (files.length < 1) {
      handleAlertCall(AlertSeverityEnum.WARNING, 'Insira um arquivo para continuar');
    } else {
      setLoading(true);
      voteUpload(files[0])
        .then(() => setLoading(false))
        .then(() => setModalState(ModalState.SUCCESS))
        .catch(() => {
          setLoading(false);
          setModalState(ModalState.ERROR);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    setModalState(ModalState.INITIAL);
    setFiles([]);
  }, [regulatoryPrecedentsUploadModal]);

  useEffect(() => {
    setTotalProgress(percentage);
  }, [percentage]);

  return (
    <div>
      <Modal
        open={regulatoryPrecedentsUploadModal}
        onClose={regulatoryPrecedentsUploadModalState}
        aria-labelledby="upload-documento-apolices"
        aria-describedby="upload-documento-apolices"
      >
        <Box className={classes.modal}>
          <Box className={classes.closeButton}>
            <IconButton onClick={regulatoryPrecedentsUploadModalState}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={classes.content}>
            {loading ? (
              <LoadingProgress porcentagem={totalProgress || 0} />
            ) : (
              <CloudUploadIcon className={classes.icon} htmlColor="#03A9F4" />
            )}
            <Typography fontWeight="bold" mt="1rem">
              Upload de Arquivo
            </Typography>
            <Typography fontWeight="bold" variant="h6" mt="1rem">
              Faça o upload para iniciar o processamento
            </Typography>

            <Box>
              {modalState === 'initial' && (
                <Box className={classes.content}>
                  <Typography m="1rem 0">O arquivo a ser enviado deve estar no formato ZIP</Typography>
                  <RegulatoryPrecedentsUploadField loading={loading} handleFiles={handleFiles} files={files} />
                  <Box className={classes.buttonsContainer}>
                    {!loading && (
                      <Button
                        onClick={regulatoryPrecedentsUploadModalState}
                        size="large"
                        color="error"
                        variant="outlined"
                      >
                        Cancelar
                      </Button>
                    )}
                    <LoadingButton
                      loading={loading}
                      size="large"
                      disableElevation
                      color="primary"
                      variant="contained"
                      onClick={handleUpload}
                    >
                      Enviar
                    </LoadingButton>
                  </Box>
                </Box>
              )}

              {modalState === 'success' && (
                <Box className={classes.content}>
                  <Box m="2rem" className={classes.content}>
                    <CheckCircleIcon sx={{ margin: '1rem' }} color="success" className={classes.icon} />
                    <Typography fontWeight="bold" mb="2rem">
                      Upload concluído com sucesso!
                    </Typography>
                  </Box>
                  <Button
                    onClick={regulatoryPrecedentsUploadModalState}
                    size="large"
                    disableElevation
                    color="success"
                    variant="contained"
                  >
                    Concluir
                  </Button>
                </Box>
              )}

              {modalState === 'error' && (
                <Box className={classes.content}>
                  <Box m="2rem" className={classes.content}>
                    <CancelIcon sx={{ margin: '1rem' }} color="error" className={classes.icon} />
                    <Typography fontWeight="bold" mb="2rem">
                      Ocorreu um erro com o envio <br />
                      do arquivo, tente novamente.
                    </Typography>
                  </Box>
                  <Button
                    onClick={() => {
                      setModalState(ModalState.INITIAL);
                      setFiles([]);
                    }}
                    size="large"
                    disableElevation
                    color="error"
                    variant="contained"
                  >
                    Tentar novamente
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
