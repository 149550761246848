import { Button, IconButton, Tooltip } from '@mui/material';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import { regulatoryPrecedentsUploadFieldStyles } from './RegulatoryPrecedentsUploadFieldStyles';
import { PoliciesDocumentUploadFieldProps } from './Type';

export function RegulatoryPrecedentsUploadField({ handleFiles, files, loading }: PoliciesDocumentUploadFieldProps) {
  const classes = regulatoryPrecedentsUploadFieldStyles();
  const onDrop = useCallback(acceptedFiles => {
    handleFiles(acceptedFiles);
  }, []);

  const { open, getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDrop,
    accept: 'application/zip, application/x-zip-compressed, .zip',
    maxFiles: 1,
    noClick: true,
    noKeyboard: true
  });

  const fileStates = {
    empty: 'Arraste e solte o arquivo aqui ou pressione o botão abaixo',
    error: 'O formato de arquivo deve ser ZIP',
    file: acceptedFiles.map(file => file.name).join('\n')
  };

  return (
    <div {...getRootProps()} className={classes.uploadArea}>
      <input accept="application/zip, application/x-zip-compressed, .zip" {...getInputProps()} />
      {isDragActive && <p className={classes.text}>Solte o arquivo aqui...</p>}
      {files.length > 0 ? (
        files.map(file => (
          <div key={file.name}>
            <p className={classes.text}>{file.name}</p>
            {!loading && (
              <Tooltip title="Remover arquivo">
                <IconButton onClick={() => handleFiles([])}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        ))
      ) : (
        <p className={classes.text}>{fileStates.empty}</p>
      )}
      {files.length < 1 && (
        <Button variant="outlined" color="info" onClick={open}>
          Escolha um arquivo para fazer upload
        </Button>
      )}
    </div>
  );
}
