import { makeStyles } from '@mui/styles';

export const navigationStyle = makeStyles({
  navigationBox: {
    flexGrow: 1
  },

  appBar: {
    elevation: '0',
    marginBottom: '0'
  }
});
