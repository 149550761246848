import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    margin: '0px auto',
    height: '100%',
    maxWidth: '100%',
    border: 'none',
    borderColor: theme.palette.background.default,
    backgroundColor: theme.palette.background.paper
  },

  root: {
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.secondary
    },
    '& .MuiDataGrid-cell ': {
      textOverflow: 'inherit'
    },
    '& .MuiBox-root': {
      backgroundColor: theme.palette.background.default
    },
    '& .MuiDataGrid-sortIcon': {
      color: theme.palette.text.secondary
    },
    '&  .MuiDataGrid-iconSeparator path': {
      display: 'none'
    },
    '& .css-ptiqhd-MuiSvgIcon-root': {
      margin: '0px'
    },
    '& .MuiInputBase-formControl': {
      backgroundColor: theme.palette.background.default,
      height: '30px',
      width: '200px',
      borderRadius: '0px'
    },
    '&. .MuiDataGrid-windowContainer': {
      backgroundColor: theme.palette.background.default
    },
    '& .MuiSvgIcon-fontSizeMedium': {
      transform: 'scale(1.5)'
    },
    '& .MuiDataGrid-cell--textLeft': {
      textAlign: 'center'
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      margin: '0 auto'
    },
    '& .MuiDataGrid-cell--textRight': {
      textAlign: 'center'
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'center'
    },
    '& .css-z2aqmj-MuiTypography-root-MuiLink-root': {
      color: theme.palette.secondary.main
    },
    '& .MuiDataGrid-checkboxInput': {
      '& svg': {
        fontSize: '1rem',
        color: theme.palette.primary.main
      }
    }
  },

  customToolbar: {
    backgroundColor: theme.palette.background.default,
    '& .MuiButtonBase-root': {
      color: theme.palette.secondary.main
    }
  }
}));
