import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const createEditActivityModalStyle = makeStyles<Theme>((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1120px',
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '24px',
    padding: '40px',
    alignSelf: 'center',
    justifyContent: 'space-between'
  },

  titleIcon: {
    marginBottom: '1rem'
  },

  closeIcon: {
    minWidth: '0px',
    padding: '0px'
  },

  title: {
    fontWeight: 'bold',
    fontSize: '20px'
  },

  inputPrazoError: {
    '& .css-1epxqk-MuiInputBase-root-MuiOutlinedInput-root': {
      color: theme.palette.error.dark,
      width: '100%'
    },
    '& .css-1t22g0q-MuiFormHelperText-root': {
      color: theme.palette.error.dark
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.error.dark
      },
      '&:hover fieldset': {
        borderColor: theme.palette.error.dark
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.error.dark
      }
    },
    width: '100%'
  },

  inputPrazoSuccess: {
    '& .css-1epxqk-MuiInputBase-root-MuiOutlinedInput-root': {
      color: theme.palette.success.main,
      width: '100%'
    },
    '& .css-1t22g0q-MuiFormHelperText-root': {
      color: theme.palette.success.main
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.success.main
      },
      '&:hover fieldset': {
        borderColor: theme.palette.success.main
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.success.main
      }
    },
    width: '100%'
  },

  inputContainer: {
    marginBottom: '21px'
  },

  inputTextearea: {
    height: '0.8rem'
  },

  helperText: {
    color: theme.palette.text.secondary
  },

  containerButton: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '1rem',
    '& .MuiButton-root': {
      height: '2.5rem'
    }
  },

  labelColor: {
    '& .MuiInputLabel-formControl': {
      color: theme.palette.text.primary
    }
  },

  formControlLabelColor: {
    color: theme.palette.text.primary
  }
}));
