import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Box, Button, Typography } from '@mui/material';
import { loginRequest } from '../../../auth/getAuthConfig';
import { loginStyles } from './LoginStyles';
import omegaLogo from './omegaLogo.svg';
import textLogo from './textLogo.svg';

function handleLogin(instance: IPublicClientApplication) {
  instance.loginRedirect(loginRequest).catch((e: Event) => {
    console.error(e);
  });
}
export function Login(): JSX.Element {
  const { instance } = useMsal();
  const classes = loginStyles();
  return (
    <div>
      <div className={classes.container}>
        <div className={classes.imageContainer} />
        <div className={classes.loginContainer}>
          <Box boxShadow={3} className={classes.loginContent}>
            <div className={classes.logoHeader}>
              <img src={omegaLogo} alt="" width="64" />
            </div>
            <div className={classes.logoText}>
              <Typography variant="h5" fontWeight="bold" textAlign="center">
                Boas vindas ao RegulaTech
              </Typography>
              <Typography textAlign="center">
                Faça login com a sua conta corporativa <br />
                Microsoft para continuar
              </Typography>
              <Button onClick={() => handleLogin(instance)}>Fazer login</Button>
            </div>
          </Box>
          <img src={textLogo} className={classes.textLogo} alt="" width="160" />
        </div>
      </div>
    </div>
  );
}
