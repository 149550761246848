import {
  Modal,
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Autocomplete,
  Stack,
  OutlinedInput
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SetStateAction, useState } from 'react';

import { useQuery } from 'react-query';
import { useAlerts } from '../../../../hooks/useAlerts/useAlerts';
import { InterfaceActiveDaprType } from '../../../../interfaces/Active/interfaceActiveTypeDapr';
import { createDapr } from '../../../../services/dapr/dapr';
import { CreateDaprModalProps } from './Type';
import { createDaprModalStyle } from './CreateDaprModalStyle';
import { getActiveTypeDapr } from '../../../../services/Service';
import { queryClient } from '../../../../services/QueryClient';
import { AlertMessagesEnum, AlertSeverityEnum } from '../../../../hooks/useAlerts/useAlertsEnums';

export function CreateDaprModal({ open, onClose }: CreateDaprModalProps) {
  const classes = createDaprModalStyle();
  const { data, isLoading, error } = useQuery<InterfaceActiveDaprType[], Error>('type-dapr', getActiveTypeDapr);

  const [ativos, setAtivos] = useState<InterfaceActiveDaprType[]>([]);

  const [listAtivos, setListAtivos] = useState<InterfaceActiveDaprType[] | undefined>([]);

  const { handleAlertCall } = useAlerts();

  const [linkDapr, setLinkDapr] = useState('');
  const handleLinkDapr = (event: { target: { value: SetStateAction<string> } }) => {
    setLinkDapr(event?.target.value);
  };

  const [disabled, setDisabled] = useState(true);

  const [tipoDapr, setTipoDapr] = useState('');

  const handleTipoDapr = (event: SelectChangeEvent) => {
    setTipoDapr(event.target.value);
    listAtivosFilter(event.target.value);
    setDisabled(false);
  };

  const listAtivosFilter = (tipo: string) => {
    const dataAtivo = data?.filter(function(ativo) {
      ativo.isDisabled = false;

      return ativo.daprs.map(function(item) {
        if (item.tipo === tipo) {
          ativo.isDisabled = true;
          return ativo;
        }
      });
    });

    dataAtivo!.sort((a, b) => Number(a.isDisabled) - Number(b.isDisabled));
    setListAtivos(dataAtivo);
  };

  const [situacaoEmissao, setSituacaoEmissao] = useState('');
  const handleSituacaoEmissao = (event: SelectChangeEvent) => {
    setSituacaoEmissao(event.target.value);
  };
  const formClear = () => {
    setAtivos([]);
    setListAtivos([]);
    setDisabled(true);
    setTipoDapr('');
    setSituacaoEmissao('');
  };

  const handleOnCloseDapr = () => {
    onClose();
    formClear();
  };

  function handleCreateDapr() {
    if (ativos.length > 0 && linkDapr != '' && tipoDapr != '' && situacaoEmissao != '') {
      const data = {
        ativosOperacionais: ativos.map(ativo => ativo.id),
        linkDapr: linkDapr,
        tipo: tipoDapr,
        situacaoDaEmissao: situacaoEmissao
      };

      createDapr(data)
        .then(() => handleAlertCall(AlertSeverityEnum.SUCCESS, AlertMessagesEnum.SUCCESS))
        .then(onClose)
        .then(() => queryClient.invalidateQueries('DAPRs'))
        .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
      formClear();
    } else {
      handleAlertCall(AlertSeverityEnum.INFO, AlertMessagesEnum.INFO);
    }
  }

  return (
    <div>
      <Modal open={open}>
        <Box className={classes.container}>
          <Grid container maxWidth={'xl'} display="flex">
            <Grid item xs={11}>
              <Typography className={classes.title} id="modal-dapr-title">
                Cadastro de DAPR
              </Typography>
            </Grid>

            <Grid sx={{ textAlign: 'right' }} item xs={1}>
              <Button className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.inputContainer}>
            <Grid item xs={6}>
              <FormControl className={classes.formInput}>
                <InputLabel className={classes.formControlLabelColor}>Tipo de DAPR</InputLabel>
                <Select value={tipoDapr} label="Tipo de DAPR" onChange={handleTipoDapr}>
                  <MenuItem value={'TESTE'}>Teste</MenuItem>
                  <MenuItem value={'PROVISORIO'}>Provisório</MenuItem>
                  <MenuItem value={'DEFINITIVO'}>Definitivo</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                disabled={disabled}
                fullWidth
                id="listaAtivos"
                multiple
                onChange={(event, value) => setAtivos(value as [])}
                options={listAtivos ? listAtivos : []}
                getOptionLabel={options => options?.siglaDoAtivo}
                getOptionDisabled={option => option.isDisabled}
                renderInput={params => <TextField {...params} className={classes.labelColor} label="Ativos" />}
              />
            </Grid>

            <Grid container spacing={2} className={classes.inputContainer + ' ' + classes.formInputLeft}>
              <Grid item xs={6}>
                <FormControl className={classes.formInput}>
                  <InputLabel className={classes.inputLabelOutlined}>Link de DAPR</InputLabel>
                  <OutlinedInput type="url" onChange={handleLinkDapr} />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl className={classes.formInput}>
                  <InputLabel className={classes.formControlLabelColor}>Situação da emissão</InputLabel>
                  <Select value={situacaoEmissao} label="Situação da emissão" onChange={handleSituacaoEmissao}>
                    <MenuItem value={'SOLICITADO'}>Solicitado</MenuItem>
                    <MenuItem value={'ATENDENDO_PENDENCIAS'}>Atendendo pendências</MenuItem>
                    <MenuItem value={'CONCLUIDO'}>Concluído</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Box className={classes.containerButton}>
            <Stack direction="row" spacing={1}>
              <Button onClick={() => handleOnCloseDapr()} variant="contained" color="error">
                Cancelar
              </Button>

              <Button onClick={() => handleCreateDapr()} variant="contained">
                Salvar
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
