import { Grid, FormControl, InputLabel, OutlinedInput, Select, MenuItem, Box, Button, Stack } from '@mui/material';
import { InputFieldMask } from '../../../../atoms';
import { createEditActiveModalStyle } from '../CreateEditActiveModalStyle';
import { PropsFormData } from '../Type';
import { SourceActive, UF } from '../../../../../interfaces/Active/enumActive';
import { useFormContext } from '../hook/FormActiveProvider';

export function CompanyPlant({ onClose }: PropsFormData): JSX.Element {
  const classes = createEditActiveModalStyle();
  const {
    dataForm,
    handleInput,
    handleSelectUf,
    handleSelectFonte,
    handleNumbersTwoPlaces,
    handleDecimalNumbersTwoPlaces,
    companyPlantDataEnableOrDisable,
    continueForm,
    formClear,
    IsCpnjValid
  } = useFormContext();

  function handleOnClose() {
    formClear();
    onClose();
  }

  return (
    <>
      <h4>Empresa</h4>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Empresa *</InputLabel>
            <OutlinedInput type="text" value={dataForm.empresa} onChange={event => handleInput('empresa', event)} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputFieldMask
              mask="99.999.999/9999-99"
              label={'CNPJ *'}
              value={dataForm.cnpj}
              className={/\d+/g.test(dataForm.cnpj) && !IsCpnjValid ? classes.inputPrazoError : ''}
              onChange={event => handleInput('cnpj', event)}
              variant={'outlined'}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Município *</InputLabel>
            <OutlinedInput type="text" value={dataForm.municipio} onChange={event => handleInput('municipio', event)} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>UF *</InputLabel>
            <Select value={dataForm.uf} onChange={({ target }) => handleSelectUf(target.value as UF)}>
              {Object.values(UF).map(value => {
                return (
                  <MenuItem key={value} value={UF[value]}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <h4>Usina</h4>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Fonte *</InputLabel>
            <Select value={dataForm.fonte} onChange={({ target }) => handleSelectFonte(target.value as SourceActive)}>
              <MenuItem value={SourceActive.EOL}>Eólica</MenuItem>
              <MenuItem value={SourceActive.PCH}>Hidrelétrica</MenuItem>
              <MenuItem value={SourceActive.UFV}>Fotovoltaica</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Número UGs*</InputLabel>
            <OutlinedInput
              type="number"
              value={dataForm.numeroDeUgs}
              onChange={event => handleNumbersTwoPlaces('numeroDeUgs', event)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Potência MW *</InputLabel>
            <OutlinedInput
              type="number"
              value={dataForm.potencia}
              onChange={event => {
                handleDecimalNumbersTwoPlaces('potencia', event);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>GF MWm*</InputLabel>
            <OutlinedInput
              type="number"
              value={dataForm.gf}
              onChange={event => handleDecimalNumbersTwoPlaces('gf', event)}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box className={classes.containerButton}>
        <Stack direction="row" spacing={2}>
          <Button onClick={() => handleOnClose()} variant="contained" color="error">
            Cancelar
          </Button>
          <Button disabled={companyPlantDataEnableOrDisable} onClick={continueForm} variant="contained">
            Continuar
          </Button>
        </Stack>
      </Box>
    </>
  );
}
