import { InteractionType } from '@azure/msal-browser';
import { useAccount, useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { useState, ReactNode, useContext, useEffect } from 'react';
import { loginRequest } from '../../auth/getAuthConfig';
import { callMsGraph } from '../../auth/graph';
import { UserInfoInterface } from '../../interfaces/Auth/interfaceUserInfo';
import api from '../../services/ServiceBase';
import { AuthContext } from './useAuthContext';
interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  useMsalAuthentication(InteractionType.Redirect);
  const { instance, accounts } = useMsal();
  const [superUser, setSuperUser] = useState(false);
  const [graphData, setGraphData] = useState<UserInfoInterface | null>(null);
  const [editor, setEditor] = useState(false);
  const account = useAccount(accounts[0] || {});

  const isAuthenticated = useIsAuthenticated();

  const getEditors = async () => {
    const { data } = await api.get<string[]>('user/editors');
    return data;
  };

  const getDevs = async () => {
    const { data } = await api.get<string[]>('user/devs');
    return data;
  };

  useEffect(() => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };
    if (account && isAuthenticated) {
      instance
        .acquireTokenSilent({
          scopes: ['User.Read'],
          account
        })
        .then(response => {
          if (response) {
            api.defaults.headers.common['Authorization'] = `${response.tokenType} ${response.idToken}`;
          }
        });
      instance
        .acquireTokenSilent(request)
        .then(response => {
          callMsGraph(response.accessToken).then(async (response: UserInfoInterface) => {
            setGraphData(response);
            const editorsList: string[] = await getEditors();
            const devList: string[] = await getDevs();
            const list = editorsList.concat(devList);
            setEditor(list.includes(response.userPrincipalName));
          });
        })
        .catch(e => {
          instance.acquireTokenRedirect(request);
          instance.acquireTokenSilent(request).then(response => {
            callMsGraph(response.accessToken).then(response => setGraphData(response));
          });
        });
    }
  }, [account, instance]);

  return <AuthContext.Provider value={{ superUser, editor }}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
