import { DownloadButton, MenuWrapper, MenuButton } from '../components/atoms';
import Navigation from '../components/organisms/Navigation/Navigation';
import { useAuth } from '../hooks/useAuth/useAuth';

export default function MenuPage(): JSX.Element {
  const { editor } = useAuth();
  return (
    <div>
      <Navigation headerTitle="Dashboard" />
      <MenuWrapper>
        <MenuButton title="Ativos Operacionais" link="/lista-ativos" />
        <MenuButton title="Projetos em Desenvolvimento" link="/projetos-desenvolvimento" />
        <MenuButton title="DAPR" link="/lista-dapr" />
        {editor ? <MenuButton title="Palavras-chave" link="/palavras-chave" /> : ''}
        <MenuButton title="Gestão de Atividades" link="/gestao-atividades" />
        <MenuButton title="Consultas Públicas" link="/consultas-publicas" />
        {editor ? <MenuButton title="Precedentes Regulatórios" link="/precedentes-regulatorios" /> : ''}
        {editor ? <MenuButton title="Base Geração Brasil" link="/base-geracao" /> : ''}
        {editor ? <DownloadButton title="Base de Geração" /> : ''}
      </MenuWrapper>
    </div>
  );
}
