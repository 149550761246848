import { Button, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useContextModalFunction } from './hook/ButtonsModalProvider';
import { useAuth } from '../../../hooks/useAuth/useAuth';

type AddElementButtonProps = {
  element: string;
  children: JSX.Element;
};

export function AddButton({ element, children }: AddElementButtonProps) {
  const { editor } = useAuth();
  const { onOpen } = useContextModalFunction();
  return (
    <>
      <Tooltip title={editor ? '' : 'Você não tem permissão para esta ação'}>
        <div>
          <Button disabled={!editor} sx={{ mr: 8, color: 'primary' }} onClick={() => onOpen()}>
            <AddIcon sx={{ mr: 1 }} />
            <Typography fontSize={'0.8125rem'} fontWeight={500}>
              Adicionar {element}
            </Typography>
          </Button>
        </div>
      </Tooltip>
      {children}
    </>
  );
}
