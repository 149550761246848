import { Box, Button, Container } from '@mui/material';
import Dropzone, { useDropzone } from 'react-dropzone';
import { formUploadBaseGeracaoStyle } from '../FormUploadBaseGeracaoStyles';
import { Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useModalUploads } from '../hook/FileUploadModal';
import { InterfaceBaseGeneration } from '../../../../interfaces/BaseGeneration/interfaceBaseGeneration';

export type InputUploadSpreadsheetProps = {
  data: InterfaceBaseGeneration;
};

export function InputUploadSpreadsheet({ data }: InputUploadSpreadsheetProps): JSX.Element {
  const classes = formUploadBaseGeracaoStyle();
  const { title, nomeArquivo, statusJob, updatedAt, endpoint, type } = data;
  const { handleUpload, modalState, testUpload } = useModalUploads();
  const [file, setFile] = useState<File[] | undefined>(undefined);
  const [buttonState, setButtonState] = useState<boolean>(true);

  const onDrop = useCallback(
    file => {
      if (file[0] !== undefined) {
        setFile(file);
      }
    },
    [file]
  );

  useEffect(() => {
    if (file) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  }, [file]);

  useEffect(() => {
    if (modalState === 'success' || modalState === 'error') {
      setButtonState(true);
      setFile(undefined);
    }
  }, [modalState]);

  const {
    getRootProps: getRootPropsDrag,
    getInputProps: getInputPropsDrag,
    isDragActive: isDragActiveDrag,
    isDragReject: isDragRejectDrag
  } = useDropzone({
    accept: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel.sheet.macroEnabled.12',
      'application/vnd.ms-excel',
      'application/vnd.ms-excel.template.macroEnabled.12',
      '.xlsm',
      '.csv'
    ],
    onDrop
  });

  const deleteFile = () => {
    setFile(undefined);
  };

  const renderDragMessage = useCallback(() => {
    if (!isDragActiveDrag) {
      return <span className={classes.textInput}>Arraste e solte arquivo ou aperte o botão ao lado</span>;
    }

    if (isDragRejectDrag) {
      return <span className={classes.textInput}>Tipo de arquivo não suportado</span>;
    }
    return <span className={classes.textInput}>Solte o arquivo aqui</span>;
  }, [isDragRejectDrag, isDragActiveDrag]);

  return (
    <>
      <Container className={classes.container}>
        <Box className={classes.box}>
          <Box className={classes.boxInput}>
            <Box className={classes.boxLabel}>
              <Typography>{title}</Typography>
              <Typography className={classes.typographySmall}>
                {statusJob} - {nomeArquivo} -{updatedAt}
              </Typography>
            </Box>
            <div className={classes.inputUpload}>
              <input {...getInputPropsDrag()} />
              {file === undefined ? (
                <div className={classes.boxInputText} {...getRootPropsDrag()}>
                  {renderDragMessage()}
                  <Button size="small" className={classes.buttonUpload} variant="outlined">
                    Adicionar arquivo
                  </Button>
                </div>
              ) : (
                <Box className={classes.boxMessageDocument}>
                  <div className={classes.textInput}>{file[0].name}</div>
                  <CloseIcon color="error" className={classes.deleteDocument} onClick={() => deleteFile()} />
                </Box>
              )}
            </div>
            {buttonState === true && (
              <Typography className={classes.messageDocumentType}>É permitido apenas arquivo do tipo {type}</Typography>
            )}
          </Box>
          <Button
            variant="contained"
            color="secondary"
            sx={{ width: '100px', height: '45px', marginLeft: '10px' }}
            onClick={() => testUpload(file, endpoint)}
            disabled={buttonState}
          >
            ENVIAR
          </Button>
        </Box>
      </Container>
    </>
  );
}
