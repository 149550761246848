import { AddDaprButton } from '../components/atoms/AddDaprButton/AddDaprButton';
import { Dapr } from '../components/organisms/Dapr/Dapr';
import { AlertProvider } from '../hooks/useAlerts/useAlerts';
import { AcronymsProvider } from '../hooks/useGetAcronyms/useGetAcronyms';

export default function DocumentsPage(): JSX.Element {
  return (
    <AlertProvider>
      <AcronymsProvider>
        <Dapr title="DAPRs" hasAddModal={<AddDaprButton element="DAPR" />} />
      </AcronymsProvider>
    </AlertProvider>
  );
}
