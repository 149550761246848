import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionsActivityStyles } from './ActivityAccordionStyle';
import { Box, Button, Grid, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CommentActivity from '../ActivityComment/ActivityComment';
import { deleteActivity, getActivityByCluster } from '../../../services/activityManagement/activityManagement';
import { InterfaceSegmentedActivity } from '../../../interfaces/SegmentedActivity/interfaceSegmentedActivity';
import { useQuery } from 'react-query';
import { useContext, useEffect, useMemo, useState } from 'react';
import { CreateEditActivityModal } from '../Modal/CreateEditActivityModal/CreateEditActivityModal';
import { getSiglaCluster } from '../../../services/Service';
import { InterfaceSiglaDoAtivoCluster2 } from '../../../interfaces/Pendency/interfacePendency';
import ActivityAccordionEditDelete from '../ActivityAccordionEditDelete/ActivityAccordionEditDelete';
import { useSetEditable } from '../../../hooks/useSetEditable/useSetEditable';
import { ClusterContext } from '../../organisms/NavigationTabs/NavigationTabs';
import { DeleteDialog } from '../Modal/DeleteDialog/DeleteDialog';
import { useAlerts } from '../../../hooks/useAlerts/useAlerts';
import { queryClient } from '../../../services/QueryClient';
import { useSearch } from '../../../hooks/useSearch/useSearch';
import { DateFormatter } from '../../../util/DateFormatter';
import { StringNormalize } from '../../../util/FormatterActiveDetails';
import { AlertMessagesEnum, AlertSeverityEnum } from '../../../hooks/useAlerts/useAlertsEnums';
import { capitalizeFirstLetter, formatPriority, formatStatus, formatValue } from './Formatters';
import { useParams } from 'react-router-dom';

interface ActivityAccordionProps {
  data: InterfaceSegmentedActivity[];
}

export function ActivityAccordion({ data }: ActivityAccordionProps): JSX.Element {
  const classes = AccordionsActivityStyles();
  const params = useParams() as { id: string };

  const { busca } = useSearch();

  const [expanded, setExpanded] = useState<string | false>(params.id !== undefined ? params.id : false);
  // Busca de dados para exibição
  const clusterContext = useContext(ClusterContext);
  const { handleAlertCall } = useAlerts();

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [openUpdate, setOpenUpdate] = useState(false);
  const [dataUpdate, setDataUpdate] = useState<InterfaceSegmentedActivity>();
  const [idDelete, setIdDelete] = useState<string>('');

  const handleUpdate = (item: InterfaceSegmentedActivity) => {
    setDataUpdate(item);
    setOpenUpdate(true);
  };

  const { data: dataSigla, isLoading: isLoadingSiglas } = useQuery<InterfaceSiglaDoAtivoCluster2[], Error>(
    ['siglasCluster', { cluster: clusterContext }],
    () => getSiglaCluster(clusterContext)
  );

  const handleOnCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const [openDelete, setDeleteOpen] = useState(false);

  const handleDeleteOpen = (id: string) => {
    setIdDelete(id);
    setDeleteOpen(true);
  };

  const handleDeleteOnClose = () => {
    setDeleteOpen(false);
  };

  const handleDelete = () => {
    deleteActivity(idDelete)
      .then(() => queryClient.invalidateQueries(['ActivityManagement', { cluster: clusterContext }]))
      .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
  };

  const { editable } = useSetEditable();

  const orderFirst = (current: InterfaceSegmentedActivity) => {
    return current.id === params.id ? -1 : 1;
  };

  const filterWords = useMemo(() => {
    const formatData = data ? data : [];
    const lowerBusca = busca?.toLowerCase();

    const result = formatData.filter(atividade => {
      return (
        StringNormalize(atividade.titulo).includes(lowerBusca) ||
        StringNormalize(atividade.responsavel).includes(lowerBusca) ||
        StringNormalize(atividade.status).includes(lowerBusca) ||
        StringNormalize(atividade.prioridade).includes(lowerBusca) ||
        StringNormalize(atividade.prazo ? atividade.prazo : '').includes(lowerBusca)
      );
    });

    if (params.id !== undefined) {
      return result.sort((current, after) => orderFirst(current));
    } else {
      return result;
    }
  }, [data, busca]);

  return (
    <>
      {data?.length === 0 ? (
        'Informações não encontradas'
      ) : (
        <div className={classes.activityDiv}>
          {filterWords.map(item => (
            <Accordion
              key={item.id}
              expanded={expanded === item.id}
              onChange={handleChange(item.id)}
              className={classes.accordion}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container spacing={1} className={classes.accordionSummary}>
                  <Grid item xs={8}>
                    <Typography className={classes.title}>{item.titulo}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className={classes.datePrazo} align="right">
                      Prazo: {item.prazo != null ? DateFormatter(item.prazo) : 'Prazo indeterminado'}
                      {'  '}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} className={classes.accordionDetails}>
                  <Grid item>
                    <Typography>
                      <span className={classes.nameDetails}>Data: </span>
                      {DateFormatter(item.data)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <span className={classes.nameDetails}>Descrição: </span>
                      {item.descricao}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <span className={classes.nameDetails}> Ativos envolvidos: </span>
                      {item.ativosOperacionais.map(sigla => sigla.siglaDoAtivo).join(', ')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <span className={classes.nameDetails}>Valor envolvido: </span> R${' '}
                      {formatValue(item.valorEnvolvido)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <span className={classes.nameDetails}>Responsável: </span>{' '}
                      {capitalizeFirstLetter(item.responsavel)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <span className={classes.nameDetails}>Status: </span>
                      {formatStatus(item.status)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <span className={classes.nameDetails}>Prioridade: </span> {formatPriority(item.prioridade)}
                    </Typography>
                  </Grid>

                  <Grid item>
                    {item.atualizacoesDaAtividade.length === 0 && <span>Sem atualizações cadastradas</span>}

                    {item.atualizacoesDaAtividade.map(comment => (
                      <ActivityAccordionEditDelete
                        key={comment.id}
                        comment={comment.comentario}
                        data={comment.data}
                        id={comment.id}
                      />
                    ))}
                  </Grid>
                </Grid>
                <CommentActivity id={item.id} />
                <Box className={classes.containerButton}>
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="outlined"
                      color="warning"
                      startIcon={<EditIcon />}
                      onClick={() => handleUpdate(item)}
                      disabled={!editable}
                    >
                      Editar
                    </Button>{' '}
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDeleteOpen(item.id)}
                      disabled={!editable}
                    >
                      Excluir
                    </Button>
                  </Stack>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
      <DeleteDialog
        open={openDelete}
        onClose={handleDeleteOnClose}
        handleDelete={handleDelete}
        text="Se você excluir, não poderá mais reaver essa informação."
      />

      <CreateEditActivityModal
        open={openUpdate}
        onClose={handleOnCloseUpdate}
        cluster={dataSigla}
        data={dataUpdate}
        title="Editar processo"
        typeEdit={true}
      />
    </>
  );
}
