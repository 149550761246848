import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const toggleEditDeleteStyle = makeStyles<Theme>((theme: Theme) => ({
  button: {
    marginLeft: '5px'
  },
  unlock: {
    color: theme.palette.secondary.main,
    fontSize: '20px'
  },
  lock: {
    color: theme.palette.primary.main,
    fontSize: '20px'
  },
  disabled: {
    color: theme.palette.grey[500]
  }
}));
