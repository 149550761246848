import { Button, Grid, Link } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import WarningIcon from '@mui/icons-material/Warning';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import { useState } from 'react';
import { EditDeleteButtons } from './utils/EditDeleteButtons';
import { DateFormatter } from '../../../util/DateFormatter';
import { DetailingDaprModal } from '../Modal/DetailingDaprModal/DetailingDaprModal';
import { DateColumnProps, LinkColumnProps, StatusColumnProps } from './Type';

export const DateColumn = ({ value }: DateColumnProps) => {
  return value !== undefined ? (
    <Grid container justifyContent="center">
      {DateFormatter(value)}
    </Grid>
  ) : (
    <Grid container justifyContent="center"></Grid>
  );
};

export const LinkColumn = ({ value, link }: LinkColumnProps) => {
  return value !== '' ? (
    <Grid container>
      {link !== null ? (
        <Link target="_blank" href={`${link}`}>
          {value}
        </Link>
      ) : (
        value
      )}
    </Grid>
  ) : (
    <Grid container justifyContent="center"></Grid>
  );
};

export const StatusColumn = ({ id, value }: StatusColumnProps) => {
  let buttonColor: any;
  const buttonIcon =
    value === 'Pendências atendidas'
      ? ((buttonColor = 'success'), (<CheckTwoToneIcon fontSize="small" color="success" />))
      : value === 'Pendências não atendidas'
      ? ((buttonColor = 'warning'), (<AutorenewIcon fontSize="small" color="warning" />))
      : ((buttonColor = 'error'), (<WarningIcon fontSize="small" color="error" />));

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container justifyContent="center">
      <Button
        variant="outlined"
        size="small"
        color={buttonColor}
        startIcon={buttonIcon}
        sx={{
          borderRadius: '20px',
          textTransform: 'none',
          height: '24px'
        }}
        onClick={handleOpen}
      >
        {value}
      </Button>
      <DetailingDaprModal key={id} open={open} onClose={handleClose} id={id} />
    </Grid>
  );
};

export const ActionsColumn = ({ id }: StatusColumnProps) => {
  return <EditDeleteButtons id={id} context="daprs" />;
};
