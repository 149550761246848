import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const accordionRegulatoryPrecedentsStyles = makeStyles((theme: Theme) => ({
  box: {
    marginTop: '1rem',
    maxHeight: '65vh',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  accordion: {
    minHeight: '100%',
    marginBottom: '1rem',
    '& .Mui-expanded': {
      marginBottom: '0rem'
    }
  },

  accordionSummary: {
    margin: '0rem 0rem 0rem 1rem',
    paddingBottom: '0rem'
  },

  gridSummary: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginTop: '1rem',

    '& p': {
      fontWeight: 'bold',
      textAlign: 'justify',
      '& span': {
        fontWeight: 'normal'
      }
    }
  },
  link: {
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  typography: {
    display: 'flex',
    flexDirection: 'column',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    position: 'absolute',
    zIndex: '9',
    paddingBottom: '1rem'
  },
  grid: {
    margin: '0rem 0rem 1rem 1rem',
    paddingTop: '0rem',
    '& p': {
      fontWeight: 'bold',
      textAlign: 'justify',
      '& span': {
        fontWeight: 'normal'
      }
    },
    '& > div': {
      paddingTop: '0rem'
    }
  }
}));
