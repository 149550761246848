import { makeStyles } from '@mui/styles';

export const regulatoryPrecedentsUploadStyle = makeStyles({
  modal: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '750px',
    maxWidth: '90vw',
    background: '#fff',
    padding: '3rem',
    boxShadow: '24px',
    p: 4,
    overflowY: 'hidden'
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    fontSize: '72px'
  },
  buttonsContainer: {
    display: 'flex',
    gap: '1rem',
    marginTop: '1.5rem'
  }
});
