import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const tableActiveStyle = makeStyles<Theme>((theme: Theme) => ({
  container: {
    height: '84vh',
    justifyContent: 'center',
    borderRadius: '0px'
  },

  titleTableHeader: {
    background: theme.palette.secondary.main,
    minHeight: '70px',
    textAlign: 'center',
    margin: '0px',
    color: theme.palette.text.secondary,
    alignItems: 'center',
    cursor: 'pointer'
  },

  titletablesCollapse: {
    fontSize: '1rem',
    fontWeight: '500'
  },

  box: {
    margin: '30px 50px 30px 50px',
    borderBottom: '1px solid #D5DBE4',
    paddingBottom: '13px'
  },

  opacityArrow: {
    '& .css-tqymag': {
      display: '',
      opacity: 1,
      color: theme.palette.text.secondary
    },
    '& .css-s6n6v6': {
      display: '',
      opacity: 1,
      color: theme.palette.text.secondary
    },
    '& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon': {
      opacity: 1
    },
    '& .css-3l415a-MuiSvgIcon-root-MuiTableSortLabel-icon': {
      opacity: 1
    }
  },

  title: {
    display: 'flex',
    justifyContent: 'center',
    '& span:first-child': {
      display: 'none'
    },
    '&:hover': {
      '& div:first-child': {
        textIndent: '-20px',
        marginBottom: '-5px',
        position: 'relative'
      },
      '& .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon': {
        display: '',
        opacity: 0.5,
        color: theme.palette.text.secondary
      },
      '& .css-tqymag': {
        display: '',
        opacity: 0.5,
        color: theme.palette.text.secondary
      }
    }
  },

  titlePosition: {
    whiteSpace: 'nowrap',
    position: 'absolute'
  },

  titlePositionMove: {
    whiteSpace: 'nowrap',
    textIndent: '-20px',
    marginBottom: '-5px',
    position: 'relative'
  },

  opacityArrowDisplay: {},
  boxTablePagination: {
    marginTop: '50px'
  },

  tablePagination: {
    background: theme.palette.background.paper,
    width: '93vw',
    position: 'absolute',
    height: '8vh',
    bottom: '9px',
    right: '60px',
    marginTop: '20px'
  }
}));
