import { Button, Menu } from '@mui/material';
import { useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ActivesFilterContent } from './ActivesFilterContent';
import { ActivesFilterProps } from './Type';

export function ActivesFilter({ setFilterData, clear }: ActivesFilterProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        color="secondary"
        startIcon={<FilterListIcon />}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ margin: '0 0.5rem' }}
      >
        Filtros
      </Button>
      <Menu disableEnforceFocus id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <ActivesFilterContent clear={clear} close={handleClose} setFilterData={setFilterData} />
      </Menu>
    </div>
  );
}
