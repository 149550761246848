import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const createEditActiveModalStyle = makeStyles<Theme>((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1120px',
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '24px',
    padding: '40px',
    alignSelf: 'center',
    justifyContent: 'space-between'
  },

  title: {
    fontWeight: 'bold',
    paddingBottom: '4px',
    fontSize: '1.5rem',
    marginBottom: '0.5rem'
  },

  closeButton: { minWidth: 0, padding: 0 },

  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '10px'
  },

  inputLabel: {
    color: theme.palette.text.primary
  },

  inputLabelOutlined: {
    variant: 'outlined',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    padding: '0 7px',
    top: '-1px'
  },

  formInput: {
    width: '100%'
  },

  formInputLeft: {
    marginLeft: '0.09rem'
  },

  containerButton: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '1rem',
    '& .MuiButton-root': {
      height: '2.5rem'
    }
  },

  inputPrazoError: {
    '& .css-1epxqk-MuiInputBase-root-MuiOutlinedInput-root': {
      color: theme.palette.error.dark,
      width: '100%'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.error.dark
      },
      '&:hover fieldset': {
        borderColor: theme.palette.error.dark
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.error.dark
      }
    },
    '& .MuiInputLabel-formControl': {
      color: theme.palette.text.primary
    },
    width: '100%'
  },

  inputNumber: {
    color: theme.palette.text.primary
  },

  helpTextNone: {
    display: 'none'
  },

  helpTextError: {
    position: 'absolute',
    zIndex: 5,
    fontSize: '0.7rem',
    marginTop: '3.3rem',
    color: theme.palette.error.main
  },

  labelColor: {
    '& .MuiInputLabel-formControl': {
      color: theme.palette.text.primary
    }
  }
}));
