import api from '../ServiceBase';

const endpoint = 'ativo-operacional';

export const createAtivoOperacional = async (data: {}) => {
  await api.post(endpoint, data);
};

export const deleteActive = async (id: string) => {
  await api.delete(`${endpoint}/${id}`);
};

export const updateActive = async (id: string, data: {}) => {
  return await api.put(`${endpoint}/${id}`, data);
};
