import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { menuLateralStyle } from './MenuLateralStyle';
import { MenuLateralProps } from './Type';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth/useAuth';

export default function MenuLateral({ open, setOpen }: MenuLateralProps): JSX.Element {
  const { editor } = useAuth();
  const navigationLinks = [
    { name: 'Dashboard', href: '/' },
    { name: 'Ativos Operacionais', href: '/lista-ativos' },
    { name: 'DAPRs', href: '/lista-dapr' },
    { name: editor ? 'Palavras-chave' : '', href: editor ? '/palavras-chave' : '' },
    { name: 'Gestão de Atividades', href: '/gestao-atividades' },
    { name: 'Consultas Públicas', href: '/consultas-publicas' },
    { name: editor ? 'Precedentes Regulatórios' : '', href: editor ? '/precedentes-regulatorios' : '' },
    { name: 'Projetos em Desenvolvimento', href: '/projetos-desenvolvimento' }
  ];
  const classes = menuLateralStyle();
  return (
    <SwipeableDrawer
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      className={classes.swipeableDrawer}
    >
      <Box className={classes.closeDrawerBackground}>
        <IconButton onClick={() => setOpen(false)} className={classes.closeDrawer}>
          <ChevronLeftIcon className={classes.closeDrawerIcon} />
        </IconButton>
      </Box>
      <Divider className={classes.drawerListDivider} />
      <List>
        {navigationLinks.map(item =>
          item.name ? (
            <Link key={item.name} to={item.href} className={classes.drawerListLink}>
              <ListItem>
                <Typography variant="h6" className={classes.drawerListItem}>
                  • {item.name}
                </Typography>
              </ListItem>
            </Link>
          ) : null
        )}
      </List>
    </SwipeableDrawer>
  );
}
