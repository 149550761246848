import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const AccordionsActivityStyles = makeStyles<Theme>((theme: Theme) => ({
  activityDiv: {
    backgroundColor: theme.palette.background.default,
    width: '100%'
  },

  accordion: { margin: '20px' },
  accordionSummary: {
    minHeight: '80px',
    display: 'flex',
    alignItems: 'center'
  },

  title: {
    marginTop: '9px',
    fontSize: '18px',
    fontWeight: 'bold',
    wordWrap: 'break-word',
    marginLeft: '0.5rem'
  },

  datePrazo: {
    marginTop: '8px',
    marginRight: '1rem',
    fontSize: '16px',
    fontWeight: 'bold',
    wordWrap: 'break-word'
  },

  accordionDetails: {
    width: '98%',
    marginTop: '-40px',
    marginLeft: '0.2rem',
    display: 'flex',
    flexDirection: 'column'
  },

  nameDetails: {
    fontSize: '16px',
    fontWeight: 'bold',
    wordWrap: 'break-word'
  },

  containerButton: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '1rem'
  },

  '& .MuiButton-root': {
    height: '2.5rem'
  }
}));
