import { Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import MenuPage from './pages/Menu';
import KeywordsPage from './pages/Keywords';
import ActivesPage from './pages/Actives';
import ActivesDetailsPage from './pages/ActiveDetails';
import PublicConsultations from './pages/PublicConsultations';
import RegulatoryPrecedents from './pages/RegulatoryPrecedents';
import DaprPage from './pages/Dapr';
import BaseGenerationPage from './pages/BaseGeneration';
import ProjectsInDevelopmentPage from './pages/ProjectsInDevelopment';
import { MainTheme } from './util/Themes/MainTheme';
import { EditableProvider } from './hooks/useSetEditable/useSetEditable';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './services/QueryClient';
import { AlertProvider } from './hooks/useAlerts/useAlerts';
import { SearchProvider } from './hooks/useSearch/useSearch';
import ActivityManagementPage from './pages/ActivityManagement';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Login from './pages/Login';
import { useAuth } from './hooks/useAuth/useAuth';

export default function App(): JSX.Element {
  const { editor } = useAuth();
  return (
    <>
      <AuthenticatedTemplate>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={MainTheme}>
            <CssBaseline />
            <AlertProvider>
              <SearchProvider>
                <EditableProvider>
                  <Switch>
                    <Route path="/" exact={true}>
                      <MenuPage />
                    </Route>
                    <Route path="/lista-ativos" exact={true}>
                      <ActivesPage />
                    </Route>
                    <Route path="/lista-dapr" exact={true}>
                      <DaprPage />
                    </Route>
                    <Route path={editor ? '/palavras-chave' : '/'} exact={true}>
                      <KeywordsPage />
                    </Route>
                    <Route exact={true} path="/gestao-atividades">
                      <ActivityManagementPage />
                    </Route>
                    <Route exact={true} path="/gestao-atividades/:cluster2/:id">
                      <ActivityManagementPage />
                    </Route>
                    <Route exact={true} path="/detalhamento-ativo/:id">
                      <ActivesDetailsPage />
                    </Route>
                    <Route exact={true} path="/consultas-publicas">
                      <PublicConsultations />
                    </Route>
                    <Route path={editor ? '/base-geracao' : '/'} exact={true}>
                      <BaseGenerationPage />
                    </Route>
                    <Route path="/precedentes-regulatorios" exact={true}>
                      <RegulatoryPrecedents />
                    </Route>
                    <Route path="/projetos-desenvolvimento" exact={true}>
                      <ProjectsInDevelopmentPage />
                    </Route>
                  </Switch>
                </EditableProvider>
              </SearchProvider>
            </AlertProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <CssBaseline />
        <ThemeProvider theme={MainTheme}>
          <Login />
        </ThemeProvider>
      </UnauthenticatedTemplate>
    </>
  );
}
