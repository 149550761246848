import { isNil } from 'lodash';
import { DateTime } from 'luxon';

export function DateFormatter(date: string) {
  return DateTime.fromISO(date)
    .toUTC()
    .toFormat('dd/MM/yyyy', { locale: 'pt-br' });
}

export function DateCompare(dateFilter: Date | null, dateCurrent: Date | null) {
  if (isNil(dateFilter) || isNil(dateCurrent)) {
    return false;
  } else {
    return (
      dateFilter.getDate() === dateCurrent.getDate() &&
      dateFilter.getMonth() === dateCurrent.getMonth() &&
      dateFilter.getFullYear() === dateCurrent.getFullYear()
    );
  }
}
