export enum SourceActive {
  EOL = 'EOL',
  PCH = 'PCH',
  UFV = 'UFV'
}

export enum UF {
  AC = 'AC',
  AL = 'AL',
  AP = 'AP',
  AM = 'AM',
  BA = 'BA',
  CE = 'CE',
  DF = 'DF',
  ES = 'ES',
  GO = 'GO',
  MA = 'MA',
  MT = 'MT',
  MS = 'MS',
  MG = 'MG',
  PA = 'PA',
  PB = 'PB',
  PR = 'PR',
  PE = 'PE',
  PI = 'PI',
  RJ = 'RJ',
  RN = 'RN',
  RS = 'RS',
  RO = 'RO',
  RR = 'RR',
  SC = 'SC',
  SP = 'SP',
  SE = 'SE',
  TO = 'TO'
}

export enum TypeOfAuction {
  DISPONIBILIDADE = 'DISPONIBILIDADE',
  QUANTIDADE = 'QUANTIDADE',
  RESERVA = 'RESERVA'
}

export enum TypeOfAuctionFormat {
  DISPONIBILIDADE = 'Disponibilidade',
  QUANTIDADE = 'Quantidade',
  RESERVA = 'Reserva',
  NAO_DEFINIDO = 'Não Definido'
}

export enum TypeOfAccessed {
  DISTRIBUIDORA = 'DISTRIBUIDORA',
  TRANSMISSORA = 'TRANSMISSORA'
}

export enum TypeOfAccessedFormat {
  DISTRIBUIDORA = 'Distribuidora',
  TRANSMISSORA = 'Transmissora'
}

export enum TypeOperationalSituation {
  EM_CONSTRUCAO = 'EM_CONSTRUCAO',
  EM_TESTE = 'EM_TESTE',
  EM_OPERACAO = 'EM_OPERACAO',
  EM_PRE_IMPLANTACAO = 'EM_PRE_IMPLANTACAO'
}

export enum TypeOperationalSituationFormat {
  EM_CONSTRUCAO = 'Em construção',
  EM_TESTE = 'Em teste',
  EM_OPERACAO = 'Em operação',
  EM_PRE_IMPLANTACAO = 'Em pré-implantação'
}

export enum Cluster2 {
  BAHIA = 'Bahia',
  CHUI = 'Chuí',
  DELTA = 'Delta',
  SUDESTE = 'Sudeste'
}
