import Modal from '@mui/material/Modal';
import { Box, Grid, Typography } from '@mui/material';
import { createEditActiveModalStyle } from './CreateEditActiveModalStyle';
import { Tab, TabsList, TabPanel } from './TabsMenuCompnents';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import { FormMainData } from './components/FormMainData';
import { CompanyPlant } from './components/CompanyPlant';
import { Connection } from './components/Connection';
import { Auction } from './components/Auction';
import { Grant } from './components/Grant';
import { useFormContext } from './hook/FormActiveProvider';
import { useContextModalFunction } from '../../ButtonsModals/hook/ButtonsModalProvider';

export function CreateEditActiveModal(): JSX.Element {
  const { valueTabsUnstyled, changeTab } = useFormContext();
  const { open, onClose } = useContextModalFunction();
  const classes = createEditActiveModalStyle();

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box className={classes.container}>
          <Grid container maxWidth={'xl'} display="flex">
            <Grid item xs={11}>
              <Typography className={classes.title}>Cadastro de Ativo</Typography>
            </Grid>
          </Grid>
          <TabsUnstyled
            defaultValue={0}
            value={valueTabsUnstyled}
            onChange={(_event, value) => {
              changeTab(value);
            }}
          >
            <TabsList>
              <Tab>Dados Principais</Tab>
              <Tab>Empresa e Usina</Tab>
              <Tab>Conexão</Tab>
              <Tab>Leilão</Tab>
              <Tab>Outorga</Tab>
            </TabsList>
            <TabPanel value={0}>
              <FormMainData onClose={onClose} />
            </TabPanel>
            <TabPanel value={1}>
              <CompanyPlant onClose={onClose} />
            </TabPanel>
            <TabPanel value={2}>
              <Connection onClose={onClose} />
            </TabPanel>
            <TabPanel value={3}>
              <Auction onClose={onClose} />
            </TabPanel>
            <TabPanel value={4}>
              <Grant onClose={onClose} />
            </TabPanel>
          </TabsUnstyled>
        </Box>
      </Modal>
    </>
  );
}
