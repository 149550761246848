import { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { downloadButtonStyle } from './DownloadButtonStyle';
import { downloadSpreadsheet } from '../../../services/Service';
import { CircularProgress } from '@mui/material';
import { useAlerts } from '../../../hooks/useAlerts/useAlerts';

import { DownloadButtonProps } from './Type';
import { AlertSeverityEnum, AlertMessagesEnum } from '../../../hooks/useAlerts/useAlertsEnums';

export function DownloadButton({ title }: DownloadButtonProps): JSX.Element {
  const [loading, setLoading] = useState(false);
  const { handleAlertCall } = useAlerts();

  const classes = downloadButtonStyle();

  function handleDownload() {
    setLoading(true);
    downloadSpreadsheet()
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Base Geração Brasil.xlsx');
        document.body.appendChild(link);
        link.click();
        if (response.dateConsultaCeg === 'null') {
          handleAlertCall(AlertSeverityEnum.WARNING, AlertMessagesEnum.WARNING_DONWLOAD_BASE_GERACAO);
        } else if (/^\d{2}\/\d{2}\/\d{4}$/.test(response.dateConsultaCeg)) {
          handleAlertCall(
            AlertSeverityEnum.SUCCESS,
            AlertMessagesEnum.SUCCESS_DONWLOAD_BASE_GERACAO + ` ${response.dateConsultaCeg}`
          );
        }
      })
      .then(() => setLoading(false))
      .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR_DONWLOAD_BASE_GERACAO))
      .finally(() => setLoading(false));
  }

  return (
    <Card
      className={classes.card}
      sx={{ cursor: loading ? 'wait' : 'pointer' }}
      onClick={() => (loading ? null : handleDownload())}
    >
      <CardContent>
        <div className={classes.iconDiv}>
          {loading ? <CircularProgress sx={{ margin: '25px 0' }} /> : <FileDownloadIcon className={classes.icon} />}
        </div>
        <Typography variant="h5" component="div" className={classes.title}>
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
}
