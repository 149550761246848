import { useState, ReactNode, useContext } from 'react';
import { EditableContext } from './useSetEditableContext';

interface SetEditableProviderProps {
  children: ReactNode;
}

export function EditableProvider({ children }: SetEditableProviderProps) {
  const [editable, setEditable] = useState(false);

  function setEditableState() {
    setEditable(!editable);
  }

  return <EditableContext.Provider value={{ editable, setEditableState }}>{children}</EditableContext.Provider>;
}

export function useSetEditable() {
  const context = useContext(EditableContext);
  return context;
}
