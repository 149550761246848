import { createTheme } from '@mui/material/styles';

export const MainTheme = createTheme({
  palette: {
    primary: {
      main: '#26395F'
    },
    secondary: {
      main: '#5979F2'
    },
    text: {
      primary: '#000',
      secondary: '#FFFFFF'
    },
    background: {
      paper: '#FFFFFF',
      default: '#E5E5E5'
    },
    warning: {
      main: '#FD8B3F'
    }
  }
});
