import { useState, ReactNode, useContext } from 'react';
import { SearchContext } from './useSearchContext';

interface SetSearchProviderProps {
  children: ReactNode;
}

export function SearchProvider({ children }: SetSearchProviderProps) {
  const [busca, setBusca] = useState('');

  function setBuscaState(value: string) {
    setBusca(value);
  }

  return <SearchContext.Provider value={{ busca, setBuscaState }}>{children}</SearchContext.Provider>;
}

export function useSearch() {
  const context = useContext(SearchContext);
  return context;
}
