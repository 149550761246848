import { RegulatoryPrecedents } from '../components/organisms/RegulatoryPrecedents/RegulatoryPrecedents';
import { ModalControllerProvider } from '../hooks/useModalController/useModalController';

export default function DocumentsPage(): JSX.Element {
  return (
    <>
      <ModalControllerProvider>
        <RegulatoryPrecedents title="Precedentes Regulatórios" />
      </ModalControllerProvider>
    </>
  );
}
