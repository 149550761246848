import { useQuery } from 'react-query';
import { InterfaceDapr } from '../../../interfaces/Dapr/interfaceDapr';
import { LoadingError, LoadingProgress } from '../../../util/ProgressStatus';
import { DaprProps } from './Type';
import Navigation from '../Navigation/Navigation';
import { daprStyles } from './DaprStyles';
import { Container } from '@mui/material';
import { DataGridTable } from '../../molecules/DataGridTable/DataGridTable';
import { getDaprs } from '../../../services/dapr/dapr';
import { FormatTableDapr } from './formatting/FormatTableDapr';
import { ColumnDapr } from '../../molecules/DataGridTable/Columns/ColumnsDapr';

export function Dapr({ title, hasAddModal }: DaprProps): JSX.Element {
  const classes = daprStyles();
  const { data, isLoading, error } = useQuery<InterfaceDapr[], Error>(title, getDaprs);

  return (
    <div>
      <Navigation headerTitle={title} />
      {error && <LoadingError />}
      {isLoading || !data ? (
        <LoadingProgress />
      ) : (
        <Container disableGutters className={classes.container}>
          <DataGridTable dataRows={FormatTableDapr(data)} dataColumns={ColumnDapr} hasAddModal={hasAddModal} />
        </Container>
      )}
    </div>
  );
}
