import { DateTime } from 'luxon';
import { Cluster2 } from '../../../../interfaces/Active/enumActive';
import { InterfaceActive } from '../../../../interfaces/Active/interfaceActive';
import { StringNormalize } from '../../../../util/FormatterActiveDetails';
import { DataForm } from '../../../molecules/Modal/CreateEditActiveModal/Type';

function createArrayString(element: string): string[] {
  if (element) {
    if (element.includes(' e ')) {
      return element.split(' e ');
    } else {
      return [element];
    }
  } else {
    return [] as string[];
  }
}
export function formatToEdit(data: InterfaceActive): DataForm {
  const {
    id,
    Dapr,
    LinkDapr,
    updateAT,
    leilao,
    editalDoLeilao,
    fimOutorga,
    potencia,
    gf,
    tensaoDoPontoDeConexao,
    mustMusd,
    inicioSupLeilao,
    fimSupLeilao,
    inicioOutorga,
    cluster2,
    ...rest
  } = data;
  const leiloes = createArrayString(leilao),
    editaisDosLeiloes = createArrayString(editalDoLeilao),
    fimOutorgaJs = new Date(fimOutorga),
    cluster2Enum = Object.values(Cluster2).find(value => value === cluster2);
  return {
    ...rest,
    leiloes,
    editaisDosLeiloes,
    fimOutorga: fimOutorgaJs,
    fimOutorgaString: DateTime.fromJSDate(fimOutorgaJs).toFormat('dd/MM/yyyy'),
    potencia: Number(potencia),
    gf: Number(gf),
    tensaoDoPontoDeConexao: Number(tensaoDoPontoDeConexao),
    mustMusd: Number(mustMusd),
    inicioSupLeilao: new Date(inicioSupLeilao),
    fimSupLeilao: new Date(fimSupLeilao),
    inicioOutorga: new Date(inicioOutorga),
    cluster2: cluster2Enum ? cluster2Enum : ''
  };
}
