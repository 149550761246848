import { createContext } from 'react';
import { InterfaceGeneratingUnit } from '../../interfaces/Active/interfaceActiveDetails';

interface ModalContext {
  id: string;
  handleId: (value: string) => void;
  open: boolean;
  handleOpenCloseModal: () => void;
}

export const EditModalContext = createContext<ModalContext>({} as ModalContext);
