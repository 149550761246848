import { Box } from '@mui/material';
import { Search, ToggleEditDelete } from '../../../atoms';
import { ActivesFilter } from '../../ActivesFilter/ActivesFilter';
import { FilterProps } from '../../ActivesFilter/Type';
import { useEffect } from 'react';
import { InterfaceActive } from '../../../../interfaces/Active/interfaceActive';
import { useContextModalFunction } from '../../ButtonsModals/hook/ButtonsModalProvider';
import { useFormContext } from '../../Modal/CreateEditActiveModal/hook/FormActiveProvider';
import { formatToEdit } from '../../../organisms/Active/auxiliaryFunctions/auxiliaryFunctions';
import { AddButton } from '../../ButtonsModals/AddButton';
import { CreateEditActiveModal } from '../../Modal/CreateEditActiveModal/CreateEditActiveModal';

type TableHeaderProps = {
  setFilterData: (value: FilterProps) => void;
  clear: () => void;
  elements: InterfaceActive[];
};

export default function TableActiveHeader({ clear, elements, setFilterData }: TableHeaderProps): JSX.Element {
  const { idElementToEdit, isEdit } = useContextModalFunction();
  const { setAllDataForm, updateCegOnly, dataForm } = useFormContext();

  useEffect(() => {
    if (elements && isEdit === true) {
      const element = elements.find(active => active.id === idElementToEdit);
      if (element) {
        const itemToEdit = formatToEdit(element);
        setAllDataForm(itemToEdit);
      }
    }
  }, [isEdit]);

  useEffect(() => {
    if (!isEdit) {
      if (dataForm.ceg.length > 20) {
        const element = elements.find(active => active.ceg === dataForm.ceg);
        if (element) {
          updateCegOnly(true);
        } else {
          updateCegOnly(false);
        }
      }
    }
  }, [dataForm.ceg, isEdit]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <AddButton element="Ativo Operacional">
            <CreateEditActiveModal />
          </AddButton>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
          <ActivesFilter setFilterData={setFilterData} clear={clear} />
          <Search />
          <ToggleEditDelete />
        </Box>
      </Box>
    </>
  );
}
