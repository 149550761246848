import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { propsTypes, ValidationProps } from './Type';
import { DesktopDatePicker } from '@mui/lab';
import { useEditModalContext } from '../../../../hooks/useEditModal/useEditModal';
import { updateGeneratingUnit } from '../../../../services/Service';
import { queryClient } from '../../../../services/QueryClient';
import { useAlerts } from '../../../../hooks/useAlerts/useAlerts';
import { AlertMessagesEnum, AlertSeverityEnum } from '../../../../hooks/useAlerts/useAlertsEnums';
import { EditEntryOperationModalStyle } from './EditEntryOperationModalStyle';

export function EditEntryOperationModal({ data, idActive }: propsTypes): JSX.Element {
  const { handleAlertCall } = useAlerts();
  const classes = EditEntryOperationModalStyle();

  const { open, handleOpenCloseModal } = useEditModalContext();
  const [validation, setValidation] = useState<ValidationProps>({} as ValidationProps);
  const [generatorUnit, setGeneratorUnit] = useState<string>('');
  const [testOperation, setTestOperation] = useState<null | Date>(null);
  const [dispatchOperationTest, setDispatchOperationTest] = useState<string>('');
  const [testOperationDispatchLink, setTestOperationDispatchLink] = useState<string>('');
  const [commercialOperation, setCommercialOperation] = useState<null | Date>(null);
  const [dispatchCommercialOperation, setDispatchCommercialOperation] = useState<string>('');
  const [commercialOrderLink, setCommercialOrderLink] = useState<string>('');

  React.useEffect(() => {
    setGeneratorUnit(data.unidadeGeradora);
    setDispatchOperationTest(data.despachoOperacaoEmTeste);
    setTestOperation(data.dataOperacaoEmTeste);
    setCommercialOperation(data.dataOperacaoComercial);
    setTestOperationDispatchLink(data.linkDespachoOperacaoEmTeste);
    setDispatchCommercialOperation(data.despachoOperacaoComercial);
    setCommercialOrderLink(data.linkDespachoOperacaoComercial);
  }, [data]);

  const handleOnClose = () => {
    setValidation({} as ValidationProps);
    handleOpenCloseModal();
  };

  const handleUpdate = () => {
    if (
      testOperation !== null &&
      dispatchOperationTest !== '' &&
      testOperationDispatchLink !== '' &&
      commercialOperation !== null &&
      dispatchCommercialOperation !== '' &&
      commercialOrderLink !== ''
    ) {
      const dataGeneratingUnit = {
        dataOperacaoEmTeste: testOperation,
        despachoOperacaoEmTeste: dispatchOperationTest,
        linkDespachoOperacaoEmTeste: testOperationDispatchLink,
        dataOperacaoComercial: commercialOperation,
        despachoOperacaoComercial: dispatchCommercialOperation,
        linkDespachoOperacaoComercial: commercialOrderLink
      };
      updateGeneratingUnit(dataGeneratingUnit, data.id)
        .then(() => queryClient.invalidateQueries(['active', { id: idActive }]))
        .then(() => handleAlertCall(AlertSeverityEnum.SUCCESS, AlertMessagesEnum.SUCCESS))
        .then(() => handleOpenCloseModal())
        .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
    } else {
      handleAlertCall(AlertSeverityEnum.INFO, AlertMessagesEnum.INFO);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleOpenCloseModal}
      aria-labelledby="entryOperation"
      aria-describedby="entryOperation"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '673px',
          maxHeight: '100%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          alignSelf: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Grid container maxWidth={'xl'} justifyContent="space-between">
          <Grid item xs={11}>
            <Typography id="modal-dapr-title" variant="h5" fontWeight="bold" component="h2" paddingBottom={4}>
              Edição de Campo:
            </Typography>
          </Grid>
          <Grid sx={{ textAlign: 'right' }} item xs={1}>
            <Button sx={{ minWidth: 0, padding: 0 }} onClick={handleOpenCloseModal}>
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} mt={2}>
            <TextField
              required
              disabled
              multiline
              fullWidth
              className={classes.labelColor}
              label="Unidade Geradora"
              variant="outlined"
              value={generatorUnit}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} mt={2}>
            <DesktopDatePicker
              label="Operação em Teste"
              value={testOperation}
              onChange={newValue => {
                setTestOperation(newValue);
              }}
              renderInput={params => (
                <TextField
                  required
                  onBlur={() =>
                    testOperation
                      ? setValidation({ ...validation, testOperation: true })
                      : setValidation({ ...validation, testOperation: false })
                  }
                  helperText={validation.testOperation ? 'Campo obrigatório' : null}
                  className={classes.labelColor}
                  fullWidth
                  {...params}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} mt={2}>
            <TextField
              required
              onBlur={() =>
                dispatchOperationTest === ''
                  ? setValidation({ ...validation, dispatchOperationTest: true })
                  : setValidation({ ...validation, dispatchOperationTest: false })
              }
              error={validation.dispatchOperationTest}
              helperText={validation.dispatchOperationTest ? 'Campo obrigatório' : null}
              multiline
              fullWidth
              className={classes.labelColor}
              label="Despacho Operação em Teste"
              variant="outlined"
              value={dispatchOperationTest}
              onChange={event => setDispatchOperationTest(event.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            <TextField
              required
              onBlur={() =>
                testOperationDispatchLink === ''
                  ? setValidation({ ...validation, testOperationDispatchLink: true })
                  : setValidation({ ...validation, testOperationDispatchLink: false })
              }
              error={validation.testOperationDispatchLink}
              helperText={validation.testOperationDispatchLink ? 'Campo obrigatório' : null}
              multiline
              fullWidth
              className={classes.labelColor}
              label="Link do Despacho de Operação em Teste"
              variant="outlined"
              value={testOperationDispatchLink}
              onChange={event => setTestOperationDispatchLink(event.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} mt={2}>
            <DesktopDatePicker
              label="Operação Comercial"
              value={commercialOperation}
              onChange={newValue => {
                setCommercialOperation(newValue);
              }}
              renderInput={params => (
                <TextField
                  required
                  onBlur={() =>
                    commercialOperation
                      ? setValidation({ ...validation, commercialOperation: true })
                      : setValidation({ ...validation, commercialOperation: false })
                  }
                  helperText={validation.commercialOperation ? 'Campo obrigatório' : null}
                  className={classes.labelColor}
                  fullWidth
                  {...params}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} mt={2}>
            <TextField
              required
              onBlur={() =>
                dispatchCommercialOperation === ''
                  ? setValidation({ ...validation, dispatchCommercialOperation: true })
                  : setValidation({ ...validation, dispatchCommercialOperation: false })
              }
              error={validation.dispatchCommercialOperation}
              helperText={validation.dispatchCommercialOperation ? 'Campo obrigatório' : null}
              multiline
              fullWidth
              className={classes.labelColor}
              label="Despacho Operação Comercial"
              variant="outlined"
              value={dispatchCommercialOperation}
              onChange={event => setDispatchCommercialOperation(event.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            <TextField
              required
              onBlur={() =>
                commercialOrderLink === ''
                  ? setValidation({ ...validation, commercialOrderLink: true })
                  : setValidation({ ...validation, commercialOrderLink: false })
              }
              error={validation.commercialOrderLink}
              helperText={validation.commercialOrderLink ? 'Campo obrigatório' : null}
              multiline
              fullWidth
              className={classes.labelColor}
              label="Link do Despacho Comercial"
              variant="outlined"
              value={commercialOrderLink}
              onChange={event => setCommercialOrderLink(event.target.value)}
            />
          </Grid>
        </Grid>

        <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'end' }}>
          <Stack direction="row" spacing={1}>
            <Button onClick={handleOnClose} variant="contained" color="error">
              Cancelar
            </Button>

            <Button variant="contained" onClick={() => handleUpdate()}>
              Salvar
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
