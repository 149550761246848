import * as React from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import DescriptionIcon from '@mui/icons-material/Description';
import KeyIcon from '@mui/icons-material/VpnKey';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { menuButtonStyle } from './MenuButtonStyle';
import { MenuButtonProps, IconListProps } from './Type';

export function MenuButton({ title, link }: MenuButtonProps): JSX.Element {
  const classes = menuButtonStyle();
  const IconList: IconListProps = {
    '/lista-ativos': <BatteryChargingFullIcon className={classes.icon} />,
    '/projetos-desenvolvimento': <AssignmentIcon className={classes.icon} />,
    '/lista-dapr': <DescriptionIcon className={classes.icon} />,
    '/palavras-chave': <KeyIcon className={classes.icon} />,
    '/gestao-atividades': <FactCheckIcon className={classes.icon} />,
    '/consultas-publicas': <ContentPasteSearchIcon className={classes.icon} />,
    '/base-geracao': <UploadFileIcon className={classes.icon} />,
    '/precedentes-regulatorios': <HowToVoteIcon className={classes.icon} />
  };

  let iconComponent: JSX.Element = IconList[link];

  return (
    <Card className={classes.card}>
      <Link to={link} className={classes.link}>
        <CardContent>
          <div className={classes.iconDiv}>{iconComponent}</div>
          <Typography variant="h5" component="div" className={classes.cardTitle}>
            {title}
          </Typography>
        </CardContent>
      </Link>
    </Card>
  );
}
