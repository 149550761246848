import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const pageWrapperStyle = makeStyles<Theme>((theme: Theme) => ({
  box: {
    backgroundColor: theme.palette.background.default,
    minHeight: '95vh',
    margin: '15px'
  }
}));
