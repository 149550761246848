import { makeStyles } from '@mui/styles';
import loginImage from './login.webp';
import { Theme } from '@mui/material/styles';

export const loginStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  imageContainer: {
    maxWidth: '50vw',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    backgroundImage: `url(${loginImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.secondary.main
  },
  loginContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    height: '50%'
  },
  logoHeader: {
    height: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.main
  },
  logoText: {
    display: 'flex',
    height: '100%',
    gap: '2rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    backgroundColor: theme.palette.background.paper
  },
  textLogo: {
    marginTop: '4rem'
  }
}));
