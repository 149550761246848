import { MUIDataTableTextLabels, MUIDataTableColumn } from 'mui-datatables';

export const localization: MUIDataTableTextLabels = {
  body: {
    noMatch: 'Desculpe, nenhum registro correspondente encontrado',
    toolTip: 'Ordenar',
    columnHeaderTooltip: (column: MUIDataTableColumn) => `Ordenar por ${column.label}`
  },
  toolbar: {
    search: 'Pesquisar',
    downloadCsv: 'Baixar CSV',
    print: 'Imprimir',
    viewColumns: 'Ver colunas',
    filterTable: 'Filtrar tabela'
  },
  pagination: {
    next: 'Próxima página',
    previous: 'Página anterior',
    rowsPerPage: 'Linhas por página:',
    displayRows: 'de'
  },
  filter: {
    all: 'Todos',
    title: 'FILTROS',
    reset: 'LIMPAR'
  },
  viewColumns: {
    title: 'Exibir colunas',
    titleAria: 'Mostrar/ocultar colunas da tabela'
  },
  selectedRows: {
    text: 'linha(s) selecionadas',
    delete: 'Deletar',
    deleteAria: 'Deletar linhas selecionadas'
  }
};
