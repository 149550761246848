import { DateTime } from 'luxon';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  Cluster2,
  SourceActive,
  TypeOfAccessed,
  TypeOfAuction,
  TypeOperationalSituation,
  UF
} from '../../../../../interfaces/Active/enumActive';
import { DataForm, PropsFormActiveProvider } from '../Type';
import { FormActiveContext } from './useModalContext';
import { cnpj as CNPJ } from 'cpf-cnpj-validator';
import { dataFormObjectState } from '../utils/auxiliaryElement';

export function FormActiveProvider({ children }: PropsFormActiveProvider): JSX.Element {
  const [valueTabsUnstyled, setValueTabsUnstyled] = useState(0),
    [lastTabFinish, setLastTabFinish] = useState(0),
    [mainDataEnableOrDisable, setMainDataEnableOrDisable] = useState(true),
    [companyPlantDataEnableOrDisable, setCompanyPlantDataEnableOrDisable] = useState(true),
    [connectionDataEnableOrDisable, setConnectionDataEnableOrDisable] = useState(true),
    [auctionDataEnableOrDisable, setAuctionDataEnableOrDisable] = useState(true),
    [grantDataEnableOrDisable, setGrantDataEnableOrDisable] = useState(true),
    [IsCpnjValid, setIsCpnjValid] = useState(false),
    [dataForm, setMaindData] = useState<DataForm>({ ...dataFormObjectState });

  const [cegOnly, setCegOnly] = useState<boolean>(false);

  const updateCegOnly = (state: boolean) => {
    setCegOnly(state);
  };

  function formClear(): void {
    setMaindData({ ...dataFormObjectState });
    setValueTabsUnstyled(0);
    setLastTabFinish(0);
    setMainDataEnableOrDisable(true);
    setCompanyPlantDataEnableOrDisable(true);
    setConnectionDataEnableOrDisable(true);
    setAuctionDataEnableOrDisable(true);
    setGrantDataEnableOrDisable(true);
  }

  function handleInput(input: string, event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
    const { value } = event.target,
      match = value.match(/\d{6}-\d{1}/g);
    if (input === 'ceg') {
      if (match) {
        return setMaindData({ ...dataForm, [input]: value.toUpperCase(), ['cegResumido']: match ? match[0] : '' });
      } else {
        return setMaindData({ ...dataForm, [input]: value.toUpperCase(), ['cegResumido']: '' });
      }
    } else {
      if (input === 'tensaoDoPontoDeConexao') {
        return setMaindData({ ...dataForm, [input]: Number(value) });
      } else {
        return setMaindData({ ...dataForm, [input]: value });
      }
    }
  }

  function handleInputChip(input: string, value: string[]): void {
    if (input === 'leiloes' || input === 'editaisDosLeiloes') {
      setMaindData({ ...dataForm, [input]: value });
    }
  }

  function handleSelectSituacaoOperacional(value: TypeOperationalSituation): void {
    return setMaindData({ ...dataForm, situacaoOperacional: value });
  }

  function handleSelectCluster2(value: Cluster2): void {
    return setMaindData({ ...dataForm, cluster2: value });
  }

  function handleSelectUf(value: UF): void {
    return setMaindData({ ...dataForm, uf: value });
  }

  function handleSelectFonte(value: SourceActive): void {
    return setMaindData({ ...dataForm, fonte: value });
  }

  function handleSelectTipoDoAcessado(value: TypeOfAccessed): void {
    return setMaindData({ ...dataForm, tipoDeAcessado: value });
  }

  function handleSelectTipoDoLeilao(value: TypeOfAuction): void {
    return setMaindData({ ...dataForm, tipoDoLeilao: value });
  }

  function handleNumbersTwoPlaces(input: string, event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
    const { value } = event.target,
      stringNum = String(value);
    if (stringNum.length >= 3) {
      const numValue = Number(stringNum.substring(0, 2));
      return setMaindData({ ...dataForm, [input]: numValue });
    } else {
      return setMaindData({ ...dataForm, [input]: Number(value) });
    }
  }

  function setAllDataForm(data: DataForm): void {
    setMaindData(data);
  }

  function handleDisabledButtonGrant(state: DataForm) {
    const { duracaoAnos, outorga, processoAneel } = state,
      matchProcesso = processoAneel ? processoAneel.match(/^\d{5}\.\d{6}\/\d{4}/g) : null;
    if (outorga && outorga.length >= 3 && matchProcesso && duracaoAnos && duracaoAnos > 0) {
      setLastTabFinish(4);
      setGrantDataEnableOrDisable(false);
    } else {
      setGrantDataEnableOrDisable(true);
    }
  }

  function handleDisabledButtonAuction(state: DataForm) {
    const { mercado } = state;
    if (mercado && mercado.length >= 3) {
      setAuctionDataEnableOrDisable(false);
      setLastTabFinish(4);
      handleDisabledButtonGrant(state);
    } else {
      setLastTabFinish(3);
      setAuctionDataEnableOrDisable(true);
    }
  }

  function handleDisabledButtonConnection(state: DataForm) {
    const {
      agenteAcessado,
      cctCcd,
      custCusd,
      parecerDeAcesso,
      pontoDeConexao,
      mustMusd,
      tensaoDoPontoDeConexao
    } = state;
    if (
      agenteAcessado &&
      agenteAcessado.length >= 3 &&
      cctCcd &&
      cctCcd.length >= 3 &&
      custCusd &&
      custCusd.length >= 3 &&
      parecerDeAcesso &&
      parecerDeAcesso.length >= 3 &&
      pontoDeConexao &&
      pontoDeConexao.length >= 3 &&
      mustMusd &&
      mustMusd > 0 &&
      tensaoDoPontoDeConexao &&
      tensaoDoPontoDeConexao > 0
    ) {
      setConnectionDataEnableOrDisable(false);
      setLastTabFinish(3);
      handleDisabledButtonAuction(state);
    } else {
      setLastTabFinish(2);
      setConnectionDataEnableOrDisable(true);
    }
  }

  function handleDisabledButtonCompanyPlant(state: DataForm) {
    const { cnpj, empresa, municipio, numeroDeUgs, potencia, gf } = state,
      cnpjValidate = cnpj ? CNPJ.isValid(cnpj) : false;
    setIsCpnjValid(cnpjValidate);

    if (
      cnpjValidate &&
      empresa &&
      empresa.length >= 3 &&
      municipio &&
      municipio.length >= 3 &&
      numeroDeUgs &&
      numeroDeUgs > 0 &&
      potencia &&
      potencia > 0 &&
      gf &&
      gf > 0
    ) {
      setCompanyPlantDataEnableOrDisable(false);
      setLastTabFinish(2);
      handleDisabledButtonConnection(state);
    } else {
      setLastTabFinish(1);
      setCompanyPlantDataEnableOrDisable(true);
    }
  }

  function handleDisabledButtonMainForm(state: DataForm) {
    const regexCeg = /[A-Z]{3}\.[A-Z]{2}\.[A-Z]{2}\.\d{6}-\d{1}\.\d{2}/gi,
      { ceg, siglaDoAtivo, cluster1, cluster2, situacaoOperacional } = state,
      doesCegTest = ceg ? regexCeg.test(ceg) : false;
    if (
      doesCegTest &&
      siglaDoAtivo &&
      siglaDoAtivo.length >= 3 &&
      cluster1 &&
      cluster1.length >= 3 &&
      cluster2 &&
      situacaoOperacional &&
      !cegOnly
    ) {
      setMainDataEnableOrDisable(false);
      setLastTabFinish(1);
      handleDisabledButtonCompanyPlant(state);
    } else {
      setValueTabsUnstyled(0);
      setLastTabFinish(0);
      setMainDataEnableOrDisable(true);
    }
  }

  function handleDate(input: string, value: Date | null): void {
    return setMaindData({ ...dataForm, [input]: value });
  }

  function handleDecimalNumbersTwoPlaces(
    input: string,
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    const { value } = event.target,
      stringNum = String(value);
    if (stringNum.includes('.')) {
      const listStringNum = stringNum.split('.');
      if (listStringNum[1].length >= 3) {
        const newValue = Number(listStringNum[0] + '.' + listStringNum[1].substring(0, 2));
        return setMaindData({ ...dataForm, [input]: newValue });
      } else if (listStringNum[1].length <= 2) {
        return setMaindData({ ...dataForm, [input]: Number(value) });
      }
    } else {
      return setMaindData({ ...dataForm, [input]: Number(value) });
    }
  }

  function handleDecimalNumbersThreePlaces(
    input: string,
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    const { value } = event.target,
      stringNum = String(value);
    if (stringNum.includes('.')) {
      const listStringNum = stringNum.split('.');
      if (listStringNum[1].length >= 4) {
        const newValue = Number(listStringNum[0] + '.' + listStringNum[1].substring(0, 3));
        return setMaindData({ ...dataForm, [input]: newValue });
      } else if (listStringNum[1].length <= 3) {
        return setMaindData({ ...dataForm, [input]: Number(value) });
      }
    } else {
      return setMaindData({ ...dataForm, [input]: Number(value) });
    }
  }

  function handleInicioOutorga(value: Date | null): void {
    const { duracaoAnos } = dataForm;

    if (duracaoAnos && duracaoAnos > 0 && value) {
      const fimOutorgaDate = DateTime.fromJSDate(value).plus({
        years: duracaoAnos
      });
      return setMaindData({
        ...dataForm,
        inicioOutorga: value ? value : null,
        fimOutorga: fimOutorgaDate.toJSDate(),
        fimOutorgaString: fimOutorgaDate.toFormat('dd/MM/yyyy')
      });
    } else {
      return setMaindData({
        ...dataForm,
        inicioOutorga: value ? value : null,
        fimOutorga: null,
        fimOutorgaString: ''
      });
    }
  }

  function handleDuracaoAnos(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
    const valueNum = Number(event.target.value),
      valueInt = valueNum >= 100 ? Math.floor(valueNum / 10) : valueNum,
      { inicioOutorga } = dataForm;
    if (inicioOutorga && Number(event.target.value) > 0) {
      const fimOutorgaDate = DateTime.fromJSDate(inicioOutorga).plus({ years: valueInt });
      return setMaindData({
        ...dataForm,
        duracaoAnos: valueInt,
        fimOutorga: fimOutorgaDate.toJSDate(),
        fimOutorgaString: fimOutorgaDate.toFormat('dd/MM/yyyy')
      });
    } else {
      return setMaindData({
        ...dataForm,
        duracaoAnos: valueInt,
        fimOutorga: null,
        fimOutorgaString: ''
      });
    }
  }

  function continueForm() {
    setValueTabsUnstyled(valueTabsUnstyled + 1);
  }

  function changeTab(value: number) {
    if (value <= lastTabFinish) {
      setValueTabsUnstyled(value);
    }
  }

  useEffect(() => {
    handleDisabledButtonMainForm(dataForm);
  }, [dataForm, cegOnly]);

  const context = {
    handleInput,
    updateCegOnly,
    cegOnly,
    handleSelectSituacaoOperacional,
    formClear,
    mainDataEnableOrDisable,
    companyPlantDataEnableOrDisable,
    connectionDataEnableOrDisable,
    auctionDataEnableOrDisable,
    grantDataEnableOrDisable,
    dataForm,
    valueTabsUnstyled,
    continueForm,
    changeTab,
    handleSelectUf,
    handleSelectFonte,
    handleNumbersTwoPlaces,
    handleSelectTipoDoAcessado,
    handleSelectTipoDoLeilao,
    handleSelectCluster2,
    handleDate,
    handleDecimalNumbersTwoPlaces,
    handleDecimalNumbersThreePlaces,
    handleDuracaoAnos,
    handleInicioOutorga,
    handleInputChip,
    IsCpnjValid,
    setAllDataForm,
    setLastTabFinish
  };

  return <FormActiveContext.Provider value={context}>{children}</FormActiveContext.Provider>;
}

export function useFormContext() {
  const context = useContext(FormActiveContext);
  return context;
}
