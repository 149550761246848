import axios from 'axios';
import { ResponseLogsBaseGeneration } from '../../interfaces/BaseGeneration/interfaceBaseGeneration';
import { formatterBaseGeneration } from '../../util/FormatterBaseGeneration';
import api from '../ServiceBase';

const endpoint = 'base-geracao-brasil';

export const updateBase = async (typeUpload: string, data: File[], infoUpload: {}) => {
  const dataUpload = new FormData();
  if (data[0]) {
    dataUpload.append('file', data[0]);
  }

  await api.post(`${endpoint}/upload/${typeUpload}`, dataUpload, infoUpload);
};

export const getLogPlanilha = async () => {
  const { data } = await api.get<ResponseLogsBaseGeneration>(`${endpoint}/logs`);
  return formatterBaseGeneration(data);
};

export const uploadBase = async (file: File, path: string) => {
  const res = await api.get<string>(`${endpoint}/pre-signed-url/${file.name}`);

  await axios.put(res.data, file, {
    headers: {
      'Content-Type': 'UTF-8'
    }
  });

  await api.post(`${endpoint}/upload/${path}/${file.name}`);
};
