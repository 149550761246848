import { makeStyles } from '@mui/styles';

export const pendenciaStyle = makeStyles({
  container: {
    maxWidth: 'xl',
    marginTop: '1rem'
  },
  iconPendencia: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  namePendencia: {
    fontWeight: 'bold',
    fontSize: '1.25rem'
  }
});
