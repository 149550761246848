import { createContext } from 'react';

type StateModal = {
  open: boolean;
  isEdit: boolean;
  idElementToEdit: string;
  onClose: () => void;
  onOpen: () => void;
  openAndEdit: (id: string) => void;
};

export const useContextModal = createContext<StateModal>({} as StateModal);
