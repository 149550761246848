export enum StatusFarol {
  FAROL_VERMELHO = 'FAROL_VERMELHO',
  FAROL_VERDE = 'FAROL_VERMELHO',
  FAROL_AMARELO = 'FAROL_VERMELHO'
}
export enum StatusFarolFormat {
  FAROL_VERMELHO = 'Pendências vencidas',
  FAROL_VERDE = 'Pendências atendidas',
  FAROL_AMARELO = 'Pendências não atendidas'
}

export enum Type {
  TESTE = 'TESTE',
  PROVISORIO = 'PROVISORIO',
  DEFINITIVO = 'DEFINITIVO'
}
export enum TypeFormat {
  TESTE = 'Teste',
  PROVISORIO = 'Provisório',
  DEFINITIVO = 'Definitivo'
}

export enum TypeSituationIssue {
  SOLICITADO = 'SOLICITADO',
  ATENDENDO_PENDENCIAS = 'ATENDENDO_PENDENCIAS',
  CONCLUIDO = 'CONCLUIDO'
}

export enum TypeSituationIssueFormat {
  SOLICITADO = 'Solicitado',
  ATENDENDO_PENDENCIAS = 'Atendendo pendências',
  CONCLUIDO = 'Concluído'
}
