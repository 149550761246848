import { makeStyles } from '@mui/styles';

export const regulatoryPrecedentsUploadFieldStyles = makeStyles({
  uploadArea: {
    margin: '1rem',
    minHeight: '120px',
    padding: '2rem',
    minWidth: '95%',
    textAlign: 'center',
    border: '2px dashed #C4C4C4',
    borderRadius: '4px'
  },
  text: {
    color: '#818181',
    maxWidth: '80ch',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});
