import {
  InterfaceBaseGeneration,
  ResponseLogsBaseGeneration
} from '../interfaces/BaseGeneration/interfaceBaseGeneration';
import { DateFormatter } from './DateFormatter';

let listUploads: InterfaceBaseGeneration[] = [
  {
    title: 'Infomercado CCEE',
    nomeTabela: 'INFOMERCADO',
    nomeArquivo: '',
    statusJob: '',
    updatedAt: '',
    endpoint: 'infomercado',
    type: '.xlsx'
  },
  {
    title: 'Infoleilão CCEE',
    nomeTabela: 'LEILAO',
    nomeArquivo: '',
    statusJob: '',
    updatedAt: '',
    endpoint: 'leilao',
    type: '.xlsx'
  },
  {
    title: 'Nodal',
    nomeTabela: 'NODAL',
    nomeArquivo: '',
    statusJob: '',
    updatedAt: '',
    endpoint: 'nodal',
    type: '.xlsm'
  },
  {
    title: 'Ralie ANEEL',
    nomeTabela: 'RALIE',
    nomeArquivo: '',
    statusJob: '',
    updatedAt: '',
    endpoint: 'ralie',
    type: '.xlsx'
  },
  {
    title: 'Siga Operação ANEEL',
    nomeTabela: 'SIGA',
    nomeArquivo: '',
    statusJob: '',
    updatedAt: '',
    endpoint: 'siga',
    type: '.xlsx'
  },
  {
    title: 'Siga Desenvolvimento ANEEL',
    nomeTabela: 'SIGA_DRO',
    nomeArquivo: '',
    statusJob: '',
    updatedAt: '',
    endpoint: 'siga-dro',
    type: '.xlsx'
  },
  {
    title: 'Dados Mapeamento Regulatório',
    nomeTabela: 'BASE_REGULATORIO',
    nomeArquivo: '',
    statusJob: '',
    updatedAt: '',
    endpoint: 'base-regulatorio',
    type: '.xlsx'
  },
  {
    title: 'Dados do ePowerBay',
    nomeTabela: 'EPOWERBAY',
    nomeArquivo: '',
    statusJob: '',
    updatedAt: '',
    endpoint: 'epowerbay',
    type: '.xls'
  }
];

export const formatterBaseGeneration = (data: ResponseLogsBaseGeneration) => {
  const dataFormat = listUploads.map(itemListUpload => {
    data.logs.forEach(itemData => {
      if (itemListUpload.nomeTabela === itemData.nomeTabela) {
        itemListUpload.nomeArquivo = itemData.nomeArquivo;
        itemListUpload.updatedAt = DateFormatter('' + itemData.updatedAt);
      }
    });
    data.status.forEach(itemData => {
      if (itemListUpload.nomeTabela === itemData.nomeTabela) {
        itemListUpload.statusJob = itemData.statusJob;
      }
    });

    return itemListUpload;
  });

  return dataFormat;
};
