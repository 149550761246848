import * as React from 'react';
import { TablePagination, TableSortLabel } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { useSearch } from '../../../hooks/useSearch/useSearch';
import { StringNormalize } from '../../../util/FormatterActiveDetails';
import { filterArrayResults } from '../ActivesFilter/ActivesFilterFormatter';
import { FilterProps } from '../ActivesFilter/Type';
import { sortByKey } from './SortByKey';
import { RowActive } from './components/RowActive';
import { tableActiveStyle } from './TableActiveStyle';
import { TableProps } from './Type';
import TableActiveHeader from './components/TableActiveHeader';
import { InterfaceActive } from '../../../interfaces/Active/interfaceActive';
import { ButtonsModalProvider } from '../ButtonsModals/hook/ButtonsModalProvider';

interface HeadCell {
  id: string;
  label: any;
}

const columnsHeaders: HeadCell[] = [
  {
    id: 'siglaDoAtivo',
    label: 'Sigla'
  },
  {
    id: 'fonte',
    label: 'Fonte'
  },
  {
    id: 'cluster2',
    label: 'Cluster2'
  },
  {
    id: 'empresa',
    label: 'Empresa'
  },
  {
    id: 'uf',

    label: 'UF'
  },
  {
    id: 'potencia',
    label: 'Potência'
  },
  {
    id: 'gf',
    label: 'GF'
  },
  {
    id: 'pontoDeConexao',
    label: 'Ponto de Conexão'
  },
  {
    id: 'tensaoDoPontoDeConexao',
    label: 'Tensão'
  },
  {
    id: 'fimOutorga',
    label: 'Fim da Outorga'
  }
];

export default function TableActive({ data }: TableProps): JSX.Element {
  const classes = tableActiveStyle();

  const { busca } = useSearch();
  const [renderData, setRenderData] = useState<InterfaceActive[]>(data);

  const searchResults = (iteration: Object, search: string) => {
    const formattedSearch = StringNormalize(search);

    const stringfiedItem = StringNormalize(JSON.stringify(iteration));

    return stringfiedItem.includes(formattedSearch);
  };

  useEffect(() => {
    setRenderData(
      data.filter(item => {
        return searchResults(item, busca);
      })
    );
  }, [busca, data]);

  const [ordem, setOrdem] = useState<'desc' | 'asc' | undefined>(undefined);

  const [orderByColumnId, setOrderByColumnId] = useState<string>('');

  const createSortHandler = (columnId: string) => {
    if (orderByColumnId === columnId) {
      if (ordem === 'asc') {
        setOrdem('desc');
      } else {
        setOrderByColumnId('');
        setOrdem(undefined);
      }
    } else {
      setOrderByColumnId(columnId);
      setOrdem('asc');
    }
  };

  useEffect(() => {
    if (ordem !== undefined) {
      setRenderData(sortByKey(orderByColumnId, renderData, ordem));
    } else {
      setRenderData(data);
    }
  }, [ordem, orderByColumnId]);

  const filterData = (value: FilterProps) => {
    setRenderData(
      data.filter(dado => {
        return filterArrayResults(value, dado);
      })
    );
  };

  const clearFilter = () => {
    setRenderData(data);
  };
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 100;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <ButtonsModalProvider>
        <TableActiveHeader setFilterData={filterData} clear={clearFilter} elements={data} />
        <TableContainer className={classes.container} component={Paper}>
          <Table stickyHeader aria-label="collapsible table">
            <TableHead>
              <TableRow sx={{ height: '70px' }}>
                {columnsHeaders.map(columHeader => (
                  <TableCell
                    key={columHeader.id}
                    className={classes.titleTableHeader}
                    onClick={() => createSortHandler(columHeader.id)}
                  >
                    <div className={classes.title}>
                      <div
                        className={
                          columHeader.id === orderByColumnId ? classes.titlePositionMove : classes.titlePosition
                        }
                      >
                        {columHeader.label}
                      </div>
                      <TableSortLabel
                        direction={ordem}
                        className={
                          columHeader.id === orderByColumnId ? classes.opacityArrow : classes.opacityArrowDisplay
                        }
                      ></TableSortLabel>
                    </div>
                  </TableCell>
                ))}
                <TableCell className={classes.titleTableHeader}>Ações</TableCell>
                <TableCell className={classes.titleTableHeader} />
              </TableRow>
            </TableHead>
            <TableBody>
              {renderData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <RowActive key={`${index}`} row={row} />
              ))}

              {renderData.length > rowsPerPage && (
                <TableRow>
                  <TablePagination
                    className={classes.tablePagination}
                    rowsPerPageOptions={[]}
                    component="div"
                    count={renderData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ButtonsModalProvider>
    </>
  );
}
