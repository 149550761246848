import { ReactNode, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { InterfaceAcronyms } from '../../interfaces/interfaceAcronyms';
import { getAcronyms } from '../../services/Service';
import { AcronymsContext } from './useGetAcronymsContext';

interface AlertsProps {
  children: ReactNode;
}

export function AcronymsProvider({ children }: AlertsProps) {
  const { data, isLoading, error } = useQuery<InterfaceAcronyms[], Error>('siglas', getAcronyms);
  const acronyms = data;

  return <AcronymsContext.Provider value={{ acronyms }}>{children}</AcronymsContext.Provider>;
}

export function useGetAcronyms() {
  const context = useContext(AcronymsContext);
  return context;
}
