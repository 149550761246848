import { Container, Typography, Stack, Grid, Box } from '@mui/material';
import { EditEntryOperationModal } from '../Modal/EditEntryOperationModal/EditEntryOperationModal';
import { EditModalProvider } from '../../../hooks/useEditModal/useEditModal';
import { AccordionDetailsPublicationProps } from './Type';
import { useState } from 'react';
import { InterfaceGeneratingUnit } from '../../../interfaces/Active/interfaceActiveDetails';
import { useStyles } from './AccordionActiveDetailingStyles';

import { AccordionGeneric } from '../../atoms/AccordionGeneric/AccordionGeneric';
import { AccordionDetailsEntryOperation } from './components/AccordionDetailsEntryOperation/AccordionDetailsEntryOperation';
import { AccordionDetailsPublication } from './components/AccordionDetailsPublication/AccordionDetailsPublication';
import { AccordionDetailsActivity } from './components/AccordionDetailsActivity/AccordionDetailsActivity';
import { AccordionDetailsActive } from './components/AccordionDetailsActive/AccordionDetailsActive';

export function AccordionActiveDetailing({ id, data }: AccordionDetailsPublicationProps): JSX.Element {
  const classes = useStyles();
  const [generatingUnit, setGeneratingUnit] = useState<InterfaceGeneratingUnit>({} as InterfaceGeneratingUnit);

  const handleGeneratingUnit = (data: InterfaceGeneratingUnit) => {
    setGeneratingUnit(data);
  };

  return (
    <Container disableGutters maxWidth={false} className={classes.container}>
      <Stack spacing={2}>
        <AccordionGeneric
          title={
            <>
              Detalhamento do Ativo: <span className={classes.name}>{data?.siglaDoAtivo}</span>
            </>
          }
          open
          identifier="detalhamentoAtivo"
        >
          <AccordionDetailsActive data={data} />
        </AccordionGeneric>

        <AccordionGeneric title="Publicações:" identifier="publicacao">
          <AccordionDetailsPublication data={data} />
        </AccordionGeneric>

        <EditModalProvider>
          <AccordionGeneric title="Entrada em operação:" identifier="entradaEmOperacao">
            <AccordionDetailsEntryOperation
              rows={data!.unidadesGeradoras}
              handleGeneratingUnit={handleGeneratingUnit}
            />
          </AccordionGeneric>
          <EditEntryOperationModal idActive={id} data={generatingUnit} />
        </EditModalProvider>

        <AccordionGeneric title="Atividades Vinculadas:" identifier="atividadesVinculadas">
          {data?.atividades && data.atividades.length > 0 ? (
            <AccordionDetailsActivity data={data.atividades} cluster={data.cluster2} />
          ) : (
            <Typography className={classes.title} sx={{ textAlign: 'center' }}>
              Esse ativo não possui atividades vinculadas
            </Typography>
          )}
        </AccordionGeneric>
      </Stack>
    </Container>
  );
}
