export function VerifyStatus(status: string) {
  switch (status) {
    case 'CONCLUIDO':
      return 'Concluído';
      break;
    case 'PARALISADO':
      return 'Paralisado';
      break;
    case 'EM_ANDAMENTO':
      return 'Em andamento';
      break;
    case 'NAO_HA':
      return 'Não há';
      break;
    case 'FORA_DO_PRAZO':
      return 'Fora do prazo';
      break;
    default:
      return 'Erro: Dado indisponível';
  }
}

export function StringColumn(value: string) {
  const letraMaiuscula = value[0];
  const frase = value
    .substr(1)
    .toLocaleLowerCase()
    .replace('_', ' ');
  return `${letraMaiuscula + frase}`;
}

export function StringColumnSituationIssue(value: string) {
  if (value === 'CONCLUIDO') {
    return 'Concluído';
  } else if (value === 'ATENDENDO_PENDENCIAS') {
    return 'Atendendo pendências';
  } else {
    return StringColumn(value);
  }
}

export function StringColumnTypeDapr(value: string) {
  if (value === 'PROVISORIO') {
    return 'Provisório';
  } else {
    return StringColumn(value);
  }
}

export function StringColumnOperationalSituation(value: string) {
  if (value === 'EM_CONSTRUCAO') {
    return 'Em construção';
  } else if (value === 'EM_OPERACAO') {
    return 'Em operação';
  } else if (value === 'EM_PRE_IMPLANTACAO') {
    return 'Em pré implantação';
  } else {
    return StringColumn(value);
  }
}
