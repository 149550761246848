import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const navigationTabsStyle = makeStyles<Theme>((theme: Theme) => ({
  tab: {
    color: theme.palette.text.primary,
    fontStyle: 'roboto'
  },
  tabList: {
    borderBottom: '1px solid #D5DBE4'
  }
}));
