import { ReactNode, useContext, useState } from 'react';

import { queryClient } from '../../../../services/QueryClient';
import { updateBase, uploadBase } from '../../../../services/uploadGenerationBase/uploadGenerationBase';
import { InterfaceFile, ModalUploadContext } from './useUploadModal';
interface MessageProps {
  children: ReactNode;
}

export function ModalUploadProvider({ children }: MessageProps) {
  const [modalState, setModalState] = useState('initial');
  const [open, setOpen] = useState(false);
  const [messageError, setMessageError] = useState('');

  const [file, setFile] = useState<InterfaceFile>({} as InterfaceFile);

  const handleOpenCloseModal = () => {
    setOpen(pre => !pre);
  };

  const formClear = () => {
    setFile({} as InterfaceFile);
    setMessageError('');
    setModalState('initial');
  };

  const handleLoading = (progress: number, file: string, success?: boolean) => {
    if (success === true) {
      setFile({ progress: 100, file });
    } else if (progress === 100) {
      setFile({ progress: 95, file });
    } else {
      setFile({ progress: 0, file });
    }
  };

  const handleModalState = (typeStateModal: string) => {
    setModalState(typeStateModal);
  };

  const addMessageError = (value: string) => {
    setMessageError(value);
  };

  const handleUpload = (file: File[] | undefined | null, endepoint: string) => {
    handleOpenCloseModal();
    if (file !== undefined && file !== null) {
      window.addEventListener('beforeunload', alertUser, true);
      updateBase(endepoint, file, {
        onUploadProgress: (event: { loaded: number; total: number }) => {
          let progress: number = Math.round((event.loaded * 100) / event.total);
          handleLoading(progress, file[0].name);
        }
      })
        .then(response => {
          window.removeEventListener('beforeunload', alertUser, true);
          handleLoading(100, file[0].name, true);
          setTimeout(function() {
            handleModalState('success');
          }, 1000);
        })
        .then(() => queryClient.invalidateQueries('logBaseGeneration'))
        .catch(error => {
          handleModalState('error');
          window.removeEventListener('beforeunload', alertUser, true);
          selectMessageError(error);
        });
    }
  };

  const testUpload = (file: File[] | undefined, path: string) => {
    handleOpenCloseModal();
    if (file !== undefined && file !== null) {
      window.addEventListener('beforeunload', alertUser, true);
      uploadBase(file[0], path)
        .then(response => {
          window.removeEventListener('beforeunload', alertUser, true);
          handleLoading(100, file[0].name, true);
          setTimeout(function() {
            handleModalState('success');
          }, 1000);
        })
        .then(() => queryClient.invalidateQueries('logBaseGeneration'))
        .catch(error => {
          handleModalState('error');
          window.removeEventListener('beforeunload', alertUser, true);
          selectMessageError(error);
        });
    }
  };

  const selectMessageError = (error: any) => {
    var errorObjeto = JSON.parse(error.response.request.response);
    if (error.response.status === 400) {
      if (errorObjeto.error.message) {
        addMessageError(errorObjeto.error.message);
      } else {
        addMessageError(errorObjeto.error);
      }
    } else if (error.response.status === 422) {
      addMessageError(errorObjeto.error.message);
    } else if (error.response.status === 404) {
      addMessageError(errorObjeto.error.message);
    } else {
      addMessageError('Não foi possivel realizar o upload da planilha');
    }
  };

  const alertUser = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const context = {
    open,
    handleOpenCloseModal,
    modalState,
    file,
    handleUpload,
    handleLoading,
    handleModalState,
    addMessageError,
    messageError,
    formClear,
    testUpload
  };

  return <ModalUploadContext.Provider value={context}>{children}</ModalUploadContext.Provider>;
}

export function useModalUploads() {
  const context = useContext(ModalUploadContext);
  return context;
}
