import { CreateEditActivityModal } from '../../molecules/Modal/CreateEditActivityModal/CreateEditActivityModal';
import { useContext, useState } from 'react';
import { Box, Button, Chip, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ActivityAccordion } from '../../molecules/ActivityAccordion/ActivityAccordion';
import { segmentedActivityStyle } from './SegmentedActivityStyle';
import { useQuery } from 'react-query';
import { getSiglaCluster } from '../../../services/Service';
import { InterfaceSiglaDoAtivoCluster2 } from '../../../interfaces/Pendency/interfacePendency';
import { ClusterContext } from '../NavigationTabs/NavigationTabs';
import { Search, ToggleEditDelete } from '../../atoms';
import { useAuth } from '../../../hooks/useAuth/useAuth';
import { SegmentedActivityFilter } from './components/SegmentedActivityFilter/SegmentedActivityFilter';
import { getActivityByCluster } from '../../../services/activityManagement/activityManagement';
import { InterfaceSegmentedActivity } from '../../../interfaces/SegmentedActivity/interfaceSegmentedActivity';
import { FilterProps } from './components/SegmentedActivityFilter/Type';
import {
  filterArrayResults,
  formatFilterFields,
  formatFilterValues,
  removeEmptyFields
} from './components/SegmentedActivityFilter/Formatter';

export function SegmentedActivity(): JSX.Element {
  const classes = segmentedActivityStyle();
  const [open, setOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const clusterContext = useContext(ClusterContext);

  const { data, isLoading, error } = useQuery<InterfaceSiglaDoAtivoCluster2[], Error>(
    ['siglasCluster', { cluster: clusterContext }],
    () => getSiglaCluster(clusterContext)
  );

  const { data: accordionData } = useQuery<InterfaceSegmentedActivity[], Error>(
    ['ActivityManagement', { cluster: clusterContext }],
    () => getActivityByCluster(clusterContext)
  );

  const siglaFinal = data?.map(item => item.siglaDoAtivo).join(', ');

  const [renderData, setRenderData] = useState(accordionData);

  const setFilterData = (value: FilterProps) => {
    setActiveFilters(removeEmptyFields(value as {}));
    setRenderData(
      accordionData?.filter(iteration => {
        return filterArrayResults(value, iteration);
      })
    );
  };

  const clear = () => {
    setActiveFilters({});
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { editor } = useAuth();

  return (
    <>
      <Box className={classes.box}>
        <Typography className={classes.subtitulo}>Ativos relacionados:</Typography>
        <div>
          <Typography className={classes.siglas}>{siglaFinal}</Typography>
        </div>
      </Box>
      <div className={classes.box}>
        <div>
          <Typography className={classes.subtitulo}>Processos e atividades:</Typography>
        </div>
        <Box className={classes.toolbar}>
          <Tooltip title={editor ? '' : 'Você não tem permissão para esta ação'}>
            <Box display="flex">
              <Button disabled={!editor} onClick={handleOpen} startIcon={<AddIcon />}>
                Adicionar Atividade
              </Button>
            </Box>
          </Tooltip>
          <Box display="flex" gap={1}>
            <SegmentedActivityFilter setFilterData={setFilterData} clear={clear} siglas={data!} />
            <Search />
            <ToggleEditDelete />
          </Box>
        </Box>
        <Box
          py={1}
          className={classes.filterBox}
          sx={{
            display: Object.keys(activeFilters).length !== 0 ? 'flex' : 'none',
            alignItems: 'center',
            gap: '0.5rem'
          }}
        >
          <Typography fontWeight="bold" sx={{ padding: '1rem' }}>
            Filtros aplicados:
          </Typography>{' '}
          {Object.entries(activeFilters).map(([key, value]) => {
            const render = [
              <>
                <strong>{formatFilterFields(key)}</strong>
                <span>
                  {': '}
                  {formatFilterValues(value as string)}
                </span>
              </>
            ];
            return <Chip color="secondary" key={key} label={render[0]} />;
          })}
          <Button sx={{ display: 'inherit' }} onClick={clear}>
            Limpar todos
          </Button>
        </Box>
      </div>

      <CreateEditActivityModal open={open} onClose={handleClose} cluster={data} title="Adicionar processo" />
      <Box className={classes.activityBox}>
        <ActivityAccordion data={Object.keys(activeFilters).length === 0 ? accordionData! : renderData!} />
      </Box>
    </>
  );
}
