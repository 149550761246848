import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const regulatoryPrecedentsFilterStyle = makeStyles<Theme>((theme: Theme) => ({
  labelColor: {
    '& .MuiInputLabel-formControl': {
      color: theme.palette.text.primary
    }
  }
}));
