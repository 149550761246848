import { Box, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { SectionsProps } from './Type';

export function TableSection(props: SectionsProps): JSX.Element {
  const { title, headers, data } = props;
  return (
    <Box sx={{ margin: 1 }}>
      <Typography style={{ fontWeight: 'bold' }}>{title}</Typography>

      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((header, indexHeader) => (
              <TableCell key={indexHeader}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, indexRow) => (
            <TableRow key={indexRow}>
              {row.map((cell, indexCell) => (
                <TableCell key={indexCell}>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
