import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { StyledEngineProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './auth/getAuthConfig';
import { AuthProvider } from './hooks/useAuth/useAuth';

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
