import { useQuery } from 'react-query';

import Navigation from '../Navigation/Navigation';
import { Container } from '@mui/material';

import { InterfaceActive } from '../../../interfaces/Active/interfaceActive';
import { LoadingError, LoadingProgress } from '../../../util/ProgressStatus';
import { getActive } from '../../../services/Service';
import TableActive from '../../molecules/TableActive/TableActive';
import { activeStyle } from './ActiveStyles';
import { FormActiveProvider } from '../../molecules/Modal/CreateEditActiveModal/hook/FormActiveProvider';

export function Active(): JSX.Element {
  const { data, isLoading, error } = useQuery<InterfaceActive[], Error>('Ativos Operacionais', getActive);
  const classes = activeStyle();
  return (
    <div>
      <Navigation headerTitle={'Ativos Operacionais'} />
      {error && <LoadingError />}
      {isLoading || !data ? (
        <LoadingProgress />
      ) : (
        <FormActiveProvider>
          <Container disableGutters className={classes.container}>
            <TableActive data={data} />
          </Container>
        </FormActiveProvider>
      )}
    </div>
  );
}
