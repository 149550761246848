import { Box, Typography, CircularProgress } from '@mui/material';
import { LoadingProgressProps } from './LoadingProgressProps';

export function LoadingProgress({ porcentagem }: LoadingProgressProps) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size="4rem" variant="determinate" color="info" value={porcentagem} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.primary"
          fontSize="1rem"
        >{`${porcentagem}%`}</Typography>
      </Box>
    </Box>
  );
}
