import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const downloadButtonStyle = makeStyles<Theme>((theme: Theme) => ({
  card: {
    minWidth: '180px',
    minHeight: '180px',
    margin: '0px 10px 0px 10px',
    maxWidth: '180px',
    maxHeight: '180px'
  },

  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  },

  iconDiv: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },

  icon: {
    fontSize: '50px',
    marginTop: '20px',
    marginBottom: '20px'
  },

  title: {
    fontSize: '20px',
    textAlign: 'center',
    wordWrap: 'break-word'
  }
}));
