import { makeStyles } from '@mui/styles';

export const headerStyle = makeStyles({
  menuButton: {
    size: 'large',
    edge: 'start',
    color: 'inherit',
    marginRight: '2',
    left: '-15px'
  },

  menuIcon: {
    fontSize: '35px'
  },

  pageTitle: {
    fontWeight: 700,
    fontSize: '22px',
    flexGrow: 1
  },

  siteTitle: {
    fontWeight: 700,
    fontSize: '28px',
    right: '48%'
  },

  siteLink: {
    color: 'inherit',
    underline: 'none'
  },

  logoutButton: {
    color: 'inherit'
  }
});
