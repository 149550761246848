import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const regulatoryPrecedentsBaseStyle = makeStyles<Theme>((theme: Theme) => ({
  container: {
    margin: '1rem',
    display: 'flex',
    flexWrap: 'wrap'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    margin: '1rem 0'
  },
  filterBox: { margin: '1rem 0', border: `1px solid ${theme.palette.background.default}`, borderRadius: '4px' },
  toolbar: {
    padding: '0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  toolbarItems: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: ''
  },
  labelColor: {
    minWidth: '15rem',
    '& .MuiInputLabel-formControl': {
      color: theme.palette.text.primary
    }
  },
  recordCount: {
    display: 'flex',
    flexDirection: 'row-reverse'
  }
}));
