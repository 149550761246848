import { useQuery } from 'react-query';

import { LoadingError, LoadingProgress } from '../../../util/ProgressStatus';

import Navigation from '../Navigation/Navigation';
import { activeDetailsStyles } from './ActiveDetailsStyles';
import { Container } from '@mui/material';
import { ActiveDetailsProps } from './Type';
import { AccordionActiveDetailing } from '../../molecules/AccordionActiveDetailing/AccordionActiveDetailing';
import { useParams } from 'react-router-dom';

import { getActiveById } from '../../../services/Service';

import { formatActiveData } from '../../../util/FormatterActiveDetails';
import { InterfaceActiveDetails } from '../../../interfaces/Active/interfaceActiveDetails';
interface ActiveId {
  id: string;
}

export function ActiveDetails({ title }: ActiveDetailsProps): JSX.Element {
  const { id } = useParams<ActiveId>();
  const { data, isLoading, error } = useQuery(['active', { id: id }], () => getActiveById(id));
  const dataFormatted = formatActiveData(data);
  const classes = activeDetailsStyles();

  return (
    <div>
      <Navigation headerTitle={title} />
      {error && <LoadingError />}
      {isLoading || !data ? (
        <LoadingProgress />
      ) : (
        <Container disableGutters className={classes.container}>
          <AccordionActiveDetailing id={id} data={dataFormatted as InterfaceActiveDetails} />
        </Container>
      )}
    </div>
  );
}
