import { InterfaceActiveDetails } from '../interfaces/Active/interfaceActiveDetails';
import { DateFormatter } from './DateFormatter';

interface ObjectKeys {
  [key: string]: string;
}

export function formatActiveData(data?: InterfaceActiveDetails) {
  const tipoDapr: ObjectKeys = {
    TESTE: 'Teste',
    PROVISORIO: 'Provisório',
    DEFINITIVO: 'Definitivo'
  };

  const tipoLeilão: ObjectKeys = {
    DISPONIBILIDADE: 'Disponibilidade',
    QUANTIDADE: 'Quantidade'
  };

  let formattedDaprArray = [];

  formattedDaprArray.push(
    data?.daprs && data?.daprs.length <= 0 ? (
      <span key={0}> Não há</span>
    ) : (
      data?.daprs.map((dapr, index) => {
        const formatDapr = { ...dapr, tipo: tipoDapr[dapr.tipo] };
        index++;
        return (
          <span key={dapr.id}>
            {' '}
            <a target="_blank" href={formatDapr.linkDapr}>
              {formatDapr.tipo}
            </a>
            {index < data?.daprs.length ? ', ' : ''}
          </span>
        );
      })
    )
  );

  const formattedData = {
    ...data,

    acati: FormatLink(data?.linkAcati, data?.acati),

    cctCcd: FormatLink(data?.linkCctCcd, data?.cctCcd),

    custCusd: FormatLink(data?.linkCustCusd, data?.custCusd),

    outorga: FormatLink(data?.linkOutorga, data?.outorga),

    parecerDeAcesso: FormatLink(data?.linkParecerDeAcesso, data?.parecerDeAcesso),

    processoAneel: FormatLink(data?.linkProcessoAneel, data?.processoAneel),

    inicioOutorga: data?.inicioOutorga ? DateFormatter(data?.inicioOutorga as string) : ' Não consta',

    fimOutorga: data?.fimOutorga ? DateFormatter(data?.fimOutorga as string) : ' Não consta',

    gf: data?.gf.toString().replace('.', ','),

    mustMusd: data?.mustMusd.toString().replace('.', ','),

    tensaoDoPontoDeConexao: data?.tensaoDoPontoDeConexao.toString().replace('.', ','),

    potencia: data?.potencia.toString().replace('.', ','),

    leilao: data?.leilao === null ? 'Não há' : data?.leilao,

    tipoDoLeilao: tipoLeilão[data?.tipoDoLeilao as string] || 'Não consta',

    inicioSupLeilao: data?.inicioSupLeilao ? DateFormatter(data?.inicioSupLeilao as string) : ' Não consta',

    fimSupLeilao: data?.fimSupLeilao ? DateFormatter(data?.fimSupLeilao as string) : ' Não consta',

    cnpj: data?.cnpj ? FormatCnpj(data.cnpj) : 'Não consta',

    daprs: formattedDaprArray,

    materiasDiarioOficial: data?.materiasDiarioOficial.map(materia => {
      return {
        ...materia,
        dataPublicacao: DateFormatter(materia.dataPublicacao)
      };
    }),

    aneelPublicacoes: data?.aneelPublicacoes.map(materia => {
      return {
        ...materia,
        data: DateFormatter(materia.data)
      };
    })
  };
  return formattedData;
}

export function StringNormalize(value: string) {
  return value
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

export const FormatData = (data: any) => {
  const dateFormat = DateFormatter(data);
  const dataValue = dateFormat !== 'Invalid DateTime' ? dateFormat : ' Não consta';

  return dataValue;
};

export const FormatLink = (link?: string | null, data?: string) => {
  return link ? (
    <a href={link} target="_blank">
      {data}
    </a>
  ) : (
    data
  );
};

export const FormatCnpj = (value: string) => {
  return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

export const DisplayLimit = (value: string) => {
  return value
    .toString()
    .slice(0, 35)
    .concat('...');
};

export const Component = (value: string, nome: string) => {
  return (
    <>
      {nome}:<span>{value}</span>
    </>
  );
};
