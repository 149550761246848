import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const ActivityAccordionEditDeleteStyles = makeStyles<Theme>((theme: Theme) => ({
  nameDetails: {
    fontSize: '16px',
    fontWeight: 'bold',
    wordWrap: 'break-word'
  },

  comentContainer: {
    border: 'solid 2px',
    width: '100%',
    borderColor: theme.palette.background.default,
    borderRadius: '4px',
    padding: '1rem ',
    marginTop: '1rem',

    '& p:first-child': {
      marginTop: '0.5rem'
    },

    '& p:last-child': {
      marginTop: '0.3rem'
    }
  },

  containerButtonComent: {
    marginTop: '-2rem',
    display: 'flex',
    justifyContent: 'end'
  },

  '& .MuiButton-root': {
    height: '2.5rem'
  },

  commetContainerForm: {
    width: '100%',
    marginTop: '-5rem',
    marginLeft: '0.2rem'
  },

  containerButton: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '1rem'
  },

  labelColor: {
    '& .MuiInputLabel-formControl': {
      color: theme.palette.text.primary
    }
  }
}));
