import { Button, Container } from '@mui/material';
import { useAlerts } from '../../../hooks/useAlerts/useAlerts';
import { useSetEditable } from '../../../hooks/useSetEditable/useSetEditable';
import { queryClient } from '../../../services/QueryClient';
import { useContextModalFunction } from './hook/ButtonsModalProvider';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteDialog } from '../Modal/DeleteDialog/DeleteDialog';
import { useState } from 'react';
import { AlertMessagesEnum, AlertSeverityEnum } from '../../../hooks/useAlerts/useAlertsEnums';

interface ColumnProps {
  queryKey: string;
  element: string;
  id: string;
  deleteRequest: (id: string) => Promise<void>;
}

export function EditDeleteButtonsGeneric({ queryKey, element, id, deleteRequest }: ColumnProps) {
  const { isEdit, openAndEdit } = useContextModalFunction();
  const { handleAlertCall } = useAlerts();
  const [dialogOpen, setDialogOpen] = useState(false);

  function onDialogClose() {
    setDialogOpen(false);
  }

  function onDialogOpen() {
    setDialogOpen(true);
  }

  const handleDelete = () => {
    deleteRequest(id)
      .then(() => queryClient.invalidateQueries(queryKey))
      .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
  };

  const { editable } = useSetEditable();

  return (
    <>
      <Container disableGutters sx={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
        <Button
          disabled={!editable}
          onClick={() => openAndEdit(id)}
          color="warning"
          startIcon={<EditIcon fontSize="inherit" />}
        >
          Editar
        </Button>
        <Button disabled={!editable} onClick={onDialogOpen} color="error" startIcon={<DeleteIcon fontSize="inherit" />}>
          Excluir
        </Button>
        <DeleteDialog
          open={dialogOpen}
          onClose={onDialogClose}
          handleDelete={handleDelete}
          text={`Se você excluir, todos os dados desse ${element} também serão excluídos.`}
        />
      </Container>
    </>
  );
}
