import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useSetEditable } from '../../../../../hooks/useSetEditable/useSetEditable';
import { ToggleEditDelete } from '../../../../atoms';
import { InterfaceGeneratingUnit } from '../../../../../interfaces/Active/interfaceActiveDetails';
import { FormatData } from '../../../../../util/FormatterActiveDetails';
import { useEditModalContext } from '../../../../../hooks/useEditModal/useEditModal';
import { Column, EntryOperationProps } from './Type';
import { accordionDetailsEntryOperationStyles } from './AccordionDetailsEntryOperationStyles';

const columns: readonly Column[] = [
  { id: 'unidadeGeradora', label: 'UG', minWidth: 60 },
  {
    id: 'dataOperacaoEmTeste',
    label: 'Operação em Teste',
    minWidth: 100,
    format: (value: string) => FormatData(value)
  },
  {
    id: 'despachoOperacaoEmTeste',
    label: 'Despacho Operação em Teste',
    minWidth: 170,
    link: 'linkDespachoOperacaoEmTeste',
    align: 'center'
  },
  {
    id: 'dataOperacaoComercial',
    label: 'Operação Comercial',
    minWidth: 170,
    format: (value: string) => FormatData(value)
  },
  {
    id: 'despachoOperacaoComercial',
    label: 'Despacho Operação Comercial',
    minWidth: 170,
    link: 'linkDespachoOperacaoComercial',
    align: 'center'
  },
  {
    id: 'id',
    label: 'Ações',
    minWidth: 120
  }
];

export function AccordionDetailsEntryOperation({ handleGeneratingUnit, rows }: EntryOperationProps) {
  const classes = accordionDetailsEntryOperationStyles();

  const { editable } = useSetEditable();
  const { handleOpenCloseModal } = useEditModalContext();

  const handleOpenModal = (data: InterfaceGeneratingUnit) => {
    handleGeneratingUnit(data);
    handleOpenCloseModal();
  };

  return (
    <>
      <div className={classes.toggle}>
        <ToggleEditDelete />
      </div>
      <Container maxWidth={false} sx={{ display: 'flex', justifyContent: 'end' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    className={classes.tableCell}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                return (
                  <TableRow key={row.id}>
                    {columns.map(column => {
                      return (
                        <TableCell key={`${row.id}-${column.id}`} align={column.align}>
                          {column.id === 'id' ? (
                            <Button
                              variant="outlined"
                              color="warning"
                              disabled={!editable}
                              onClick={() => handleOpenModal(row)}
                              className={classes.buttonEdit}
                            >
                              <EditIcon sx={{ fontSize: 24, marginRight: '0.5rem', marginLeft: '-0.5rem' }} /> Editar
                            </Button>
                          ) : column?.link ? (
                            <Link href={row[column.link]} target="_blank" sx={{ cursor: 'pointer' }}>
                              {row[column.id]}
                            </Link>
                          ) : (
                            <div>{column.format ? column.format(String(row[column.id])) : row[column.id]}</div>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}
