import { Box, Container, ThemeProvider } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { useQuery } from 'react-query';
import { InterfaceProjectsInDevelopment } from '../../../interfaces/ProjectsInDevelopment/interfaceProjectsInDevelopment';
import { getProjectsInDevelopment } from '../../../services/projectsInDevelopment/projectsInDevelopment';
import { LoadingError, LoadingProgress } from '../../../util/ProgressStatus';
import { TableTheme } from '../../../util/Themes/TableThemes';
import Navigation from '../Navigation/Navigation';
import { projectsInDevelopmentStyle } from './ProjectsInDevelopmentStyles';
import {
  projectsInDevelopmentColumns,
  projectsInDevelopmentTableOptions
} from './components/OptionsColumns/OptionColumn';
import { createContext } from 'react';
import { formatProject } from '../../../util/FormatterProjectsInDevelopment';

export const DataContext = createContext([] as InterfaceProjectsInDevelopment[]);

export function ProjectsInDevelopment(): JSX.Element {
  const { data, isLoading, error, isSuccess } = useQuery<InterfaceProjectsInDevelopment[], Error>(
    'getProjectsInDevelopment',
    getProjectsInDevelopment,
    {
      refetchOnWindowFocus: false
    }
  );
  const classes = projectsInDevelopmentStyle();

  return (
    <div>
      <Navigation headerTitle="Projetos em Desenvolvimento" />
      {error && <LoadingError />}
      {isLoading || !data ? (
        <LoadingProgress />
      ) : (
        <Container className={classes.container} disableGutters>
          {isSuccess && (
            <Box>
              <ThemeProvider theme={TableTheme}>
                <DataContext.Provider value={formatProject(data)}>
                  <MUIDataTable
                    data={formatProject(data)}
                    columns={projectsInDevelopmentColumns}
                    title=""
                    options={projectsInDevelopmentTableOptions}
                  />
                </DataContext.Provider>
              </ThemeProvider>
            </Box>
          )}
        </Container>
      )}
    </div>
  );
}
