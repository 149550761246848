import { useContext, useState } from 'react';
import { useContextModal } from './useContextButtonsModals';

export function ButtonsModalProvider({ children }: React.PropsWithChildren<unknown>): JSX.Element {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [idElementToEdit, setIdElementToEdit] = useState('');

  function openAndEdit(id: string) {
    setOpen(true);
    setIsEdit(true);
    setIdElementToEdit(id);
  }

  function onClose() {
    setOpen(false);
    setIsEdit(false);
  }

  function onOpen() {
    if (open === false) {
      return setOpen(true);
    }
  }

  const context = {
    open,
    isEdit,
    idElementToEdit,
    onClose,
    onOpen,
    openAndEdit
  };

  return <useContextModal.Provider value={context}>{children}</useContextModal.Provider>;
}

export function useContextModalFunction() {
  const context = useContext(useContextModal);
  return context;
}
