import { InterfacePublicConsultations } from '../../../interfaces/PublicConsultations/interfacePublicConsultations';
import { DateFormatter } from '../../../util/DateFormatter';

export const capitalizeFirstLetter = (word: string) => {
  const lowercase = word.toLowerCase();
  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

export const formatPriority = (priority: string) => {
  let priorityFormatted = capitalizeFirstLetter(priority);
  if (priorityFormatted === 'Media') {
    priorityFormatted = 'Média';
  }
  return priorityFormatted;
};

export const formatValue = (value: string | null) => {
  if (typeof value === 'string') {
    var parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join(',');
  }
};

export const removeContribution = (value: InterfacePublicConsultations) => {
  const { numeroConsulta, dataAbertura, dataConclusao, ementa, ementaLink, responsavel, status, prioridade } = value;

  const newObj = {
    numeroConsulta,
    dataAbertura,
    dataConclusao,
    ementa,
    ementaLink,
    responsavel,
    status,
    prioridade
  };

  return newObj;
};

export const checkFields = (value: {}) => {
  const isEmpty = Object.values(value).some(field => field === '' || field === null || field === undefined);
  return isEmpty;
};

export const removeEmptyFields = (value: { [key: string]: string | null }) => {
  const newObj = { ...value };
  Object.keys(newObj).forEach(key => {
    if (value['prazo'] !== null) {
      const data = new Date(value['prazo']).toDateString();
      newObj['prazo'] = DateFormatter(new Date(data).toISOString());
    }
    if (newObj[key] === '' || value[key] === null) {
      delete newObj[key];
    }
  });

  return newObj;
};

export const formatFilterFields = (value: string) => {
  type ObjectType = {
    [key: string]: string;
  };

  const formatter: ObjectType = {
    status: 'Status',
    responsavel: 'Responsável',
    prazo: 'Prazo',
    prioridade: 'Prioridade',
    organ: 'Órgão'
  };

  return formatter[value];
};

export const formatFilterValues = (value: string) => {
  type ObjectType = {
    [key: string]: string;
  };

  const formatter: ObjectType = {
    ABERTA: 'Aberta',
    FECHADA: 'Fechada',
    REABERTA: 'Reaberta',
    ALTA: 'Alta',
    MEDIA: 'Média',
    BAIXA: 'Baixa'
  };

  return formatter[value] || value;
};
