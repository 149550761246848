import { MUIDataTableOptions } from 'mui-datatables';
import { localization } from './TableTranslation';

export const tableOptions: MUIDataTableOptions = {
  textLabels: { ...localization },
  elevation: 0,
  rowsPerPageOptions: [10, 15, 25, 50, 100],
  rowsPerPage: 25,
  selectableRows: 'none',
  responsive: 'vertical',
  download: false,
  print: false
};
