import { IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useSearch } from '../../../hooks/useSearch/useSearch';
import { useStyles } from './SearchStyles';

export function Search(): JSX.Element {
  const classes = useStyles();
  const { busca, setBuscaState } = useSearch();

  return (
    <TextField
      id="ativos-pesquisa"
      placeholder="Pesquisar"
      size="small"
      value={busca}
      onChange={event => setBuscaState(event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className={classes.icon} />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            title="Clear"
            aria-label="Clear"
            style={{ visibility: busca ? 'visible' : 'hidden' }}
            onClick={() => setBuscaState('')}
          >
            <ClearIcon className={classes.icon} />
          </IconButton>
        )
      }}
      variant="outlined"
    />
  );
}
