import { makeStyles } from '@mui/styles';

export const daprStyles = makeStyles({
  container: {
    padding: ' 0',
    height: '86vh',
    minWidth: '95%',
    justifyContent: 'center'
  }
});
