import { DesktopDatePicker } from '@mui/lab';
import { FormControl, Grid, InputLabel, OutlinedInput, TextField, Box, Stack, Button } from '@mui/material';
import { InputFieldMask } from '../../../../atoms';
import { createEditActiveModalStyle } from '../CreateEditActiveModalStyle';
import { PropsFormData } from '../Type';
import { createAtivoOperacional, updateActive } from '../../../../../services/active/active';
import { useAlerts } from '../../../../../hooks/useAlerts/useAlerts';
import { queryClient } from '../../../../../services/QueryClient';
import { useFormContext } from '../hook/FormActiveProvider';
import { useContextModalFunction } from '../../../ButtonsModals/hook/ButtonsModalProvider';
import { AlertMessagesEnum, AlertSeverityEnum } from '../../../../../hooks/useAlerts/useAlertsEnums';

export function Grant({ onClose }: PropsFormData): JSX.Element {
  const { handleAlertCall } = useAlerts();
  const classes = createEditActiveModalStyle();
  const {
    dataForm,
    handleInput,
    handleInicioOutorga,
    handleDuracaoAnos,
    formClear,
    grantDataEnableOrDisable
  } = useFormContext();

  const { isEdit, idElementToEdit } = useContextModalFunction();

  function nullOrString(value: string[] | null): string | null {
    if (value) {
      return value.join(' e ');
    } else {
      return null;
    }
  }
  const FormatProcessoAneel = (value: string) => {
    const processoAneelReplace = value.replace(/_/g, '');
    if (processoAneelReplace.substr(-1) === '-') {
      return processoAneelReplace.replace('-', '');
    } else {
      return processoAneelReplace;
    }
  };

  function formatDataForRequest() {
    const { fimOutorgaString, leiloes, editaisDosLeiloes, cnpj, processoAneel, ...rest } = dataForm;
    const leilao = nullOrString(leiloes),
      editalDoLeilao = nullOrString(editaisDosLeiloes),
      cnpjNumbers = cnpj ? cnpj.replace(/[^\w\s]|_/g, '') : null,
      processoAneelFormat = FormatProcessoAneel(processoAneel);

    const valuesRest = Object.entries(rest).map(([key, value]) => {
      if (
        (key.includes('link') ||
          key === 'tipoDoLeilao' ||
          key === 'fimSupLeilao' ||
          key === 'inicioSupLeilao' ||
          key === 'clusterONS' ||
          key === 'nomeAntigo' ||
          key === 'gf') &&
        !value
      ) {
        return [key, null];
      } else {
        return [key, value];
      }
    });

    const newObj = Object.fromEntries([
      ...valuesRest,
      ['leilao', leilao],
      ['editalDoLeilao', editalDoLeilao],
      ['cnpj', cnpjNumbers],
      ['processoAneel', processoAneelFormat]
    ]);

    return newObj;
  }

  function handleOnClose() {
    formClear();
    onClose();
  }

  function handleUpdateAtivo() {
    const objectNewAtivoOperacional = formatDataForRequest();
    updateActive(idElementToEdit, objectNewAtivoOperacional)
      .then(() => handleAlertCall(AlertSeverityEnum.SUCCESS, AlertMessagesEnum.SUCCESS))
      .then(() => handleOnClose())
      .then(() => queryClient.invalidateQueries('Ativos Operacionais'))
      .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
  }

  function handleCreateAtivo() {
    const objectNewAtivoOperacional = formatDataForRequest();
    createAtivoOperacional(objectNewAtivoOperacional)
      .then(() => handleAlertCall(AlertSeverityEnum.SUCCESS, AlertMessagesEnum.SUCCESS))
      .then(() => handleOnClose())
      .then(() => queryClient.invalidateQueries('Ativos Operacionais'))
      .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
  }

  function validateProcessoANEEL() {
    if (/\d+/g.test(dataForm.processoAneel)) {
      if (!dataForm.processoAneel.match(/\d{5}\.\d{6}\/\d{4}/g)) {
        return classes.inputPrazoError;
      }
    }
    return '';
  }

  return (
    <>
      <h4>Outorga</h4>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Outorga *</InputLabel>
            <OutlinedInput type="text" value={dataForm.outorga} onChange={event => handleInput('outorga', event)} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Link Outorga</InputLabel>
            <OutlinedInput
              type="url"
              value={dataForm.linkOutorga}
              onChange={event => handleInput('linkOutorga', event)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <DesktopDatePicker
            inputFormat="dd/MM/yyyy"
            label="Início Outorga *"
            value={dataForm.inicioOutorga}
            onChange={value => {
              handleInicioOutorga(value);
            }}
            renderInput={params => <TextField fullWidth className={classes.labelColor} {...params} />}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Duração em anos *</InputLabel>
            <OutlinedInput type="number" value={dataForm.duracaoAnos} onChange={event => handleDuracaoAnos(event)} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Fim da Outorga *</InputLabel>
            <OutlinedInput type="text" value={dataForm.fimOutorgaString} disabled />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputFieldMask
              mask="99999.999999/9999-99"
              label={'Processo ANEEL *'}
              value={dataForm.processoAneel}
              className={validateProcessoANEEL()}
              onChange={event => handleInput('processoAneel', event)}
              variant={'outlined'}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Link Processo ANEEL</InputLabel>
            <OutlinedInput
              type="url"
              value={dataForm.linkProcessoAneel}
              onChange={event => handleInput('linkProcessoAneel', event)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>REIDI</InputLabel>
            <OutlinedInput type="text" value={dataForm.reidi} onChange={event => handleInput('reidi', event)} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Link REIDI</InputLabel>
            <OutlinedInput type="url" value={dataForm.linkReidi} onChange={event => handleInput('linkReidi', event)} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>ACATI</InputLabel>
            <OutlinedInput type="text" value={dataForm.acati} onChange={event => handleInput('acati', event)} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formInput}>
            <InputLabel className={classes.inputLabelOutlined}>Link ACATI</InputLabel>
            <OutlinedInput type="url" value={dataForm.linkAcati} onChange={event => handleInput('linkAcati', event)} />
          </FormControl>
        </Grid>
      </Grid>
      <Box className={classes.containerButton}>
        <Stack direction="row" spacing={2}>
          <Button onClick={() => handleOnClose()} variant="contained" color="error">
            Cancelar
          </Button>
          <Button
            disabled={grantDataEnableOrDisable}
            variant="contained"
            onClick={isEdit ? handleUpdateAtivo : handleCreateAtivo}
          >
            Salvar
          </Button>
        </Stack>
      </Box>
    </>
  );
}
