import { DateTime } from 'luxon';
import { InterfaceSiglaDoAtivoCluster2 } from '../../../../../interfaces/Pendency/interfacePendency';
import { InterfaceSegmentedActivity } from '../../../../../interfaces/SegmentedActivity/interfaceSegmentedActivity';
import { DateFormatter } from '../../../../../util/DateFormatter';
import { StringNormalize } from '../../../../../util/FormatterActiveDetails';
import { moneyFormatter } from '../../../../../util/FormatterNumber';
import { FilterProps } from './Type';

export const formatFilterFields = (value: string) => {
  type ObjectType = {
    [key: string]: string;
  };

  const formatter: ObjectType = {
    responsavel: 'Responsável',
    dataInicial: 'Data Inicial',
    dataFinal: 'Data Final',
    prazoInicial: 'Prazo Inicial',
    prazoFinal: 'Prazo Final',
    status: 'Status',
    prioridade: 'Prioridade',
    ativosEnvolvidos: 'Ativos Envolvidos',
    valor: 'Valor'
  };

  return formatter[value];
};

export const formatFilterValues = (value: string) => {
  type ObjectType = {
    [key: string]: string;
  };

  const formatter: ObjectType = {
    ATIVO: 'Ativo',
    STAND_BY: 'Stand By',
    ENCERRADO: 'Encerrado',
    ALTA: 'Alta',
    MEDIA: 'Média',
    BAIXA: 'Baixa'
  };
  return formatter[value] || value;
};

function mapAtivos(value: InterfaceSiglaDoAtivoCluster2[]) {
  return value.map(iteration => iteration.siglaDoAtivo).join(', ');
}

export const removeEmptyFields = (value: { [key: string]: string | null | number[] | string[] }) => {
  const newObj = { ...value };

  Object.keys(newObj).forEach(key => {
    if (value['prazoInicial'] !== null) {
      const data = new Date(value['prazoInicial'] as string).toDateString();
      newObj['prazoInicial'] = DateFormatter(new Date(data).toISOString());
    }
    if (value['dataInicial'] !== null) {
      const data = new Date(value['dataInicial'] as string).toDateString();
      newObj['dataInicial'] = DateFormatter(new Date(data).toISOString());
    }
    if (value['prazoFinal'] !== null) {
      const data = new Date(value['prazoFinal'] as string).toDateString();
      newObj['prazoFinal'] = DateFormatter(new Date(data).toISOString());
    }
    if (value['dataFinal'] !== null) {
      const data = new Date(value['dataFinal'] as string).toDateString();
      newObj['dataFinal'] = DateFormatter(new Date(data).toISOString());
    }
    if (value['ativosEnvolvidos']) {
      if (value['ativosEnvolvidos'].length === 0) {
        delete newObj['ativosEnvolvidos'];
      } else {
        Object.defineProperty(newObj, 'ativosEnvolvidos', {
          writable: true,
          value: mapAtivos(value['ativosEnvolvidos'] as [])
        });
      }
    }
    if (value['valor']) {
      if (value['valor'][0] === 0 && value['valor'][1] === 0) {
        delete newObj['valor'];
      } else {
        Object.defineProperty(newObj, 'valor', {
          writable: true,
          value: `${moneyFormatter(value['valor'][0] === 0 ? 0 : value['valor'][0])} - ${moneyFormatter(
            value['valor'][1]
          )}`
        });
      }
    }

    if (newObj[key] === '' || newObj[key] === null) {
      delete newObj[key];
    }
  });

  return newObj;
};

export function filterArrayResults(value: FilterProps, iteration: InterfaceSegmentedActivity) {
  const filtered = {
    responsavel: StringNormalize(value.responsavel),
    dataInicial: value.dataInicial && new Date(value.dataInicial).toISOString(),
    dataFinal: value.dataFinal && new Date(value.dataFinal).toISOString(),
    prazoInicial: value.prazoInicial && new Date(value.prazoInicial).toISOString(),
    prazoFinal: value.prazoFinal && new Date(value.prazoFinal).toISOString(),
    status: StringNormalize(value.status),
    prioridade: StringNormalize(value.prioridade),
    ativosEnvolvidos: value.ativosEnvolvidos,
    valor: value.valor
  };

  let stringfiedItem = StringNormalize(JSON.stringify(iteration));

  const arrayStrings = () => {
    let filterArray: string[] = [];
    filtered.ativosEnvolvidos.forEach(item => {
      filterArray.push(StringNormalize(item.siglaDoAtivo));
    });

    let iterationArray: string[] = [];
    iteration.ativosOperacionais.forEach(item => {
      iterationArray.push(StringNormalize(item.siglaDoAtivo));
    });

    return {
      iterationArray,
      filterArray
    };
  };

  const checkValues = () => {
    const arrayIncludes = arrayStrings().filterArray.every(item => arrayStrings().iterationArray.includes(item));

    const checkMoneyValue = () => {
      if (filtered.valor[0] === 0 && filtered.valor[1] === 0) {
        return true;
      } else {
        return (
          Number(iteration.valorEnvolvido) >= filtered.valor[0] && Number(iteration.valorEnvolvido) <= filtered.valor[1]
        );
      }
    };

    const checkTextValues =
      stringfiedItem.includes(filtered.status === '' ? '' : filtered.status) &&
      stringfiedItem.includes(filtered.prioridade === '' ? '' : filtered.prioridade) &&
      stringfiedItem.includes(filtered.responsavel === '' ? '' : filtered.responsavel);

    const checkDateValues = () => {
      let value: boolean = true;
      if (filtered.dataInicial !== null && filtered.prazoInicial === null) {
        const data =
          DateTime.fromISO(String(iteration.data)) >= DateTime.fromISO(String(filtered.dataInicial)) &&
          DateTime.fromISO(String(iteration.data)) <= DateTime.fromISO(String(filtered.dataFinal));
        value = data;
      }
      if (filtered.dataInicial === null && filtered.prazoInicial !== null) {
        const prazo =
          DateTime.fromISO(String(iteration.prazo)) >= DateTime.fromISO(String(filtered.prazoInicial)) &&
          DateTime.fromISO(String(iteration.prazo)) <= DateTime.fromISO(String(filtered.prazoFinal));
        value = prazo;
      }
      if (filtered.dataInicial !== null && filtered.prazoInicial !== null) {
        const prazo =
          DateTime.fromISO(String(iteration.prazo)) >= DateTime.fromISO(String(filtered.prazoInicial)) &&
          DateTime.fromISO(String(iteration.prazo)) <= DateTime.fromISO(String(filtered.prazoFinal));
        const data =
          DateTime.fromISO(String(iteration.data)) >= DateTime.fromISO(String(filtered.dataInicial)) &&
          DateTime.fromISO(String(iteration.data)) <= DateTime.fromISO(String(filtered.dataFinal));
        value = data && prazo;
      }
      return value;
    };

    return { arrayIncludes, checkMoneyValue, checkTextValues, checkDateValues };
  };

  return (
    checkValues().arrayIncludes &&
    checkValues().checkMoneyValue() &&
    checkValues().checkTextValues &&
    checkValues().checkDateValues()
  );
}
