import { makeStyles } from '@mui/styles';

export const accordionGenericStyles = makeStyles({
  accordion: {
    minHeight: '100%'
  },

  title: {
    fontWeight: 'bold',
    fontSize: '24px'
  }
});
