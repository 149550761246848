import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const segmentedActivityFilterStyles = makeStyles<Theme>((theme: Theme) => ({
  labelColor: {
    '& .MuiInputLabel-formControl': {
      color: theme.palette.text.primary
    }
  },
  formControlLabelColor: {
    color: theme.palette.text.primary
  },
  menuOverflow: {
    '& div': {
      overflowX: 'inherit',
      overflowY: 'inherit'
    }
  }
}));
