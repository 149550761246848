import { createTheme } from '@mui/material';
import { MainTheme } from './MainTheme';

declare module '@mui/material/styles' {
  interface Components {
    [key: string]: any;
  }
}

export const TableTheme = createTheme({
  components: {
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: MainTheme.palette.background.default
        },
        icon: {
          color: MainTheme.palette.secondary.main
        }
      }
    },
    MUIDataTableHeadRow: {
      styleOverrides: {
        root: {
          height: 70
        }
      }
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: MainTheme.palette.secondary.main,
          color: MainTheme.palette.text.secondary,
          textAlign: 'center',
          padding: 0
        },
        contentWrapper: {
          justifyContent: 'center'
        },
        sortActive: {
          backgroundColor: MainTheme.palette.secondary.main,
          color: MainTheme.palette.text.secondary
        }
      }
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        headerCell: {
          backgroundColor: MainTheme.palette.secondary.main,
          color: MainTheme.palette.text.secondary
        },
        icon: {
          margin: 8
        }
      }
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          textAlign: 'center'
        }
      }
    }
  }
});
