import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { tableActiveStyle } from '../TableActiveStyle';

import { LinkColumn } from '../../Columns/RenderCellColumns';
import { FormatNumberColumn, StringCnpjColumn, StringColumnTypeOfAuction } from '../../Columns/utils/ColumnsFormatting';
import { StringColumn, StringColumnOperationalSituation } from '../../../../util/FormatterDetailsDAPRs';
import { FormatData } from '../../../../util/FormatterActiveDetails';
import { InterfaceActive } from '../../../../interfaces/Active/interfaceActive';

export function RowActiveCollapse({ row, open }: { row: InterfaceActive; open: boolean }) {
  const classes = tableActiveStyle();

  return (
    <>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <Typography className={classes.titletablesCollapse} gutterBottom component="div">
              Dados Principais
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow key={'Dados Principais'}>
                  <TableCell>CEG</TableCell>
                  <TableCell>Nome Antigo</TableCell>
                  <TableCell>Situação Operacional</TableCell>
                  <TableCell>Sigla Do Ativo</TableCell>
                  <TableCell>CEG Resumido</TableCell>
                  <TableCell>Cluster1</TableCell>
                  <TableCell>Cluster2</TableCell>
                  <TableCell>Cluster ONS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {[
                    row.ceg,
                    row.nomeAntigo,
                    StringColumnOperationalSituation(String(row.situacaoOperacional)),
                    row.siglaDoAtivo,
                    row.cegResumido,
                    row.cluster1,
                    row.cluster2,
                    row.clusterONS
                  ].map((value, index) => (
                    <TableCell key={value ? `${row.id}-${value}` : `${row.id}-${index}-Dados-Principais`}>
                      {value ? value : ''}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box sx={{ margin: 1 }}>
            <Typography className={classes.titletablesCollapse} gutterBottom component="div">
              Empresa
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow key={'empresa'}>
                  <TableCell>Empresa</TableCell>
                  <TableCell>CNPJ</TableCell>
                  <TableCell>Município</TableCell>
                  <TableCell>UF</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {[row.empresa, StringCnpjColumn(String(row.cnpj)), row.municipio, row.uf].map((value, index) => (
                    <TableCell key={value ? `${row.id}-${value}` : `${row.id}-${index}-Empresa`}>
                      {value ? value : ''}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box sx={{ margin: 1 }}>
            <Typography className={classes.titletablesCollapse} gutterBottom component="div">
              Usina
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>Fonte</TableCell>
                  <TableCell>Número De UGs</TableCell>
                  <TableCell>Potência MW</TableCell>
                  <TableCell>GF MWm</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={'usina'}>
                  {[row.fonte, row.numeroDeUgs, FormatNumberColumn(row.potencia, 2), FormatNumberColumn(row.gf, 2)].map(
                    (value, index) => (
                      <TableCell key={value ? `${row.id}-${value}` : `${row.id}-${index}-Usina`}>
                        {value ? value : ''}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box sx={{ margin: 1 }}>
            <Typography className={classes.titletablesCollapse} gutterBottom component="div">
              Conexão
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>Ponto De Conexão</TableCell>
                  <TableCell>Tensão Do Ponto De Conexao</TableCell>
                  <TableCell>Tipo De Acessado</TableCell>
                  <TableCell>Agente Acessado</TableCell>
                  <TableCell>Parecer De Acesso</TableCell>
                  <TableCell>MUST/MUSD MW</TableCell>
                  <TableCell>CUST/CUSD</TableCell>
                  <TableCell>CCT/CCD</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={'conexao'}>
                  {[
                    row.pontoDeConexao,
                    row.tensaoDoPontoDeConexao,
                    StringColumn(String(row.tipoDeAcessado)),
                    row.agenteAcessado,
                    row.parecerDeAcesso,
                    FormatNumberColumn(row.mustMusd, 2)
                  ].map((value, index) => (
                    <TableCell key={value ? `${row.id}-${value}` : `${row.id}-${index}-Conexao`}>
                      {value ? value : ''}
                    </TableCell>
                  ))}
                  <TableCell>
                    <LinkColumn value={row.custCusd} link={row.linkCustCusd} />
                  </TableCell>
                  <TableCell>
                    <LinkColumn value={row.cctCcd} link={row.linkCctCcd} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box sx={{ margin: 1 }}>
            <Typography className={classes.titletablesCollapse} gutterBottom component="div">
              Leilão
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>Mercado</TableCell>
                  <TableCell>Leilão</TableCell>
                  <TableCell>Edital Do Leilao</TableCell>
                  <TableCell>Tipo Do Leilao</TableCell>
                  <TableCell>Inicio Sup. Leilao</TableCell>
                  <TableCell>Fim Sup. Leilao</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={'leilao'}>
                  {[
                    row.mercado,
                    row.leilao,
                    row.editalDoLeilao,
                    StringColumnTypeOfAuction(String(row.tipoDoLeilao)),
                    FormatData(row.inicioSupLeilao),
                    FormatData(row.fimSupLeilao)
                  ].map((value, index) => (
                    <TableCell key={value ? `${row.id}-${value}` : `${row.id}-${index}-Leilao`}>
                      {value ? value : null}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box sx={{ margin: 1 }}>
            <Typography className={classes.titletablesCollapse} gutterBottom component="div">
              Outorga
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>Outorga</TableCell>
                  <TableCell>Inicio Outorga</TableCell>
                  <TableCell>Duração anos</TableCell>
                  <TableCell>Fim da Outorga</TableCell>
                  <TableCell>Processo ANEEL</TableCell>
                  <TableCell>ACATI</TableCell>
                  <TableCell>REIDI</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={'ortorga'}>
                  <TableCell component="th" scope="row">
                    <LinkColumn value={row.outorga} link={row.linkOutorga} />
                  </TableCell>
                  <TableCell>{FormatData(row.inicioOutorga)}</TableCell>
                  <TableCell>{row.duracaoAnos}</TableCell>
                  <TableCell>{FormatData(row.fimOutorga)}</TableCell>
                  <TableCell>
                    <LinkColumn value={row.processoAneel} link={row.linkProcessoAneel} />
                  </TableCell>
                  <TableCell>
                    <LinkColumn value={row.acati} link={row.linkAcati} />
                  </TableCell>
                  <TableCell>
                    <LinkColumn value={row.reidi} link={row.linkReidi} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </>
  );
}
