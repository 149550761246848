import { useState, ReactNode, useContext } from 'react';
import { ModalControllerContext } from './useModalControllerContext';

interface ModalControllerProviderProps {
  children: ReactNode;
}

export function ModalControllerProvider({ children }: ModalControllerProviderProps) {
  const [regulatoryPrecedentsUploadModal, setRegulatoryPrecedentsUploadModal] = useState(false);

  function regulatoryPrecedentsUploadModalState() {
    setRegulatoryPrecedentsUploadModal(!regulatoryPrecedentsUploadModal);
  }

  return (
    <ModalControllerContext.Provider value={{ regulatoryPrecedentsUploadModal, regulatoryPrecedentsUploadModalState }}>
      {children}
    </ModalControllerContext.Provider>
  );
}

export function useModalController() {
  const context = useContext(ModalControllerContext);
  return context;
}
