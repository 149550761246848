import { InterfaceActive } from '../interfaces/Active/interfaceActive';
import { InterfacePendency, InterfaceSiglaDoAtivoCluster2 } from '../interfaces/Pendency/interfacePendency';
import { InterfaceKeywords } from '../interfaces/Keywords/interfaceKeywords';
import { InterfaceActiveDaprType } from '../interfaces/Active/interfaceActiveTypeDapr';
import { InterfaceAcronyms } from '../interfaces/interfaceAcronyms';
import { ConsultationUpdateProps } from '../components/molecules/Modal/EditConsultationUpdateModal/Type';
import { InterfacePublicConsultations } from '../interfaces/PublicConsultations/interfacePublicConsultations';
import api from './ServiceBase';
import { InterfaceActiveDetails } from '../interfaces/Active/interfaceActiveDetails';
import { InterfaceMapPrecedent } from '../interfaces/MapPrecedent/interfaceMapPrecedent';
import axios from 'axios';

export const getActive = async () => {
  const { data } = await api.get<InterfaceActive[]>('ativo-operacional');
  return data;
};

export const getActiveById = async (id: string) => {
  const { data } = await api.get<InterfaceActiveDetails>(`ativo-operacional/${id}`);
  return data;
};

export const getActiveTypeDapr = async () => {
  const { data } = await api.get<InterfaceActiveDaprType[]>('ativo-operacional/tipo-dapr');
  return data;
};

export const getPendencyById = async (id: string) => {
  const { data } = await api.get<InterfacePendency>(`dapr/${id}`);
  return data;
};

export const getKeywords = async () => {
  const { data } = await api.get<InterfaceKeywords[]>('palavra-chave');
  return data;
};

export const getKeywordById = async (id: string) => {
  const { data } = await api.get<InterfaceKeywords>(`palavra-chave/${id}`);
  return data;
};

export const getAcronyms = async () => {
  const { data } = await api.get<InterfaceAcronyms[]>('ativo-operacional');
  return data;
};

export const newKeyword = async (data: {}) => {
  await api.post('palavra-chave', data);
};

export const deleteKeyword = async (id: string) => {
  await api.delete(`palavra-chave/${id}`);
};

export const updateKeyword = async (id: string, data: {}) => {
  await api.put(`palavra-chave/${id}`, data);
};

export const newPendency = async (data: {}, id: string) => {
  await api.post(`pendencia/${id}`, data);
};

export const deletePendency = async (id: string) => {
  await api.delete(`pendencia/${id}`);
};

export const updatePendency = async (data: {}, id: string) => {
  await api.put(`pendencia/${id}`, data);
};

export const getSiglaCluster = async (cluster: string) => {
  const { data } = await api.get<InterfaceSiglaDoAtivoCluster2[]>(`/ativo-operacional/siglas/${cluster}`);
  return data;
};

export const getConsultations = async () => {
  const { data } = await api.get<InterfacePublicConsultations[]>(`/consulta-publica`);
  return data;
};

export const createConsultation = async (data: {}) => {
  await api.post(`/consulta-publica`, data);
};

export const deleteConsultation = async (id: string) => {
  await api.delete(`/consulta-publica/${id}`);
};

export const updateConsultation = async (data: {}, id: string) => {
  await api.put(`/consulta-publica/${id}`, data);
};

export const createConsultationUpdate = async (id: string, data: {}) => {
  await api.post(`/atualizacao-consulta-publica/${id}`, data);
};

export const deleteConsultationUpdate = async (id: string) => {
  await api.delete(`/atualizacao-consulta-publica/${id}`);
};

export const getConsultationUpdate = async (id: string) => {
  const { data } = await api.get<ConsultationUpdateProps>(`/atualizacao-consulta-publica/${id}`);
  return data;
};

export const updateConsultationUpdate = async (data: {}, id: string) => {
  await api.put(`/atualizacao-consulta-publica/${id}`, data);
};

export const downloadSpreadsheet = async () => {
  const response = await api.get<Blob>('base-geracao-brasil/download', {
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    responseType: 'blob'
  });
  // TODO Exibir para usuário data da ultima atualização, vinda no header da requisição, na chave last-modified
  return { data: response.data, dateConsultaCeg: response.headers['last-modified'] };
};

export const updateGeneratingUnit = async (data: {}, id: string) => {
  await api.put(`/unidade-geradora/${id}`, data);
};

export const voteUpload = async (file: File) => {
  let destination = 'votos';

  const signedUrl = await api.get(`voto/upload-url/${destination}/${file.name}`);

  await axios.put(`${signedUrl.data}`, file, {
    headers: { 'Content-Type': 'application/zip' },
    onUploadProgress: event => {
      const progress: number = Math.round((event.loaded * 100) / event.total);
    }
  });

  await api.post(`voto/upload-zip/${destination}/${file.name}`);
};

export const getVote = async () => {
  const { data } = await api.get<InterfaceMapPrecedent[]>('voto');
  return data;
};
