import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  customToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '40px',
    backgroundColor: theme.palette.background.default,
    '& .MuiButtonBase-root': {
      color: theme.palette.secondary.main
    }
  },

  toolbarBox: {
    display: 'flex'
  },

  filterButton: {
    marginLeft: '80px'
  },

  searchBox: {
    display: 'flex',
    alignItems: 'center'
  }
}));
