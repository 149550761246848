import { StringColumn } from '../../../../util/FormatterDetailsDAPRs';

export function StringCnpjColumn(value: string) {
  const cnpjFormatado = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  return cnpjFormatado;
}

export function FormatNumberColumn(value: string | undefined, decimal: number) {
  if (value !== undefined) {
    const valuePreFormatted = parseFloat(value)
      .toFixed(decimal)
      .replace('.', ',');
    return `${valuePreFormatted}`;
  } else {
    return 'Não informado';
  }
}

export function StringFarolColumn(value: string) {
  if (value === 'FAROL_VERMELHO') {
    return 'Pendências vencidas';
  }
  if (value === 'FAROL_AMARELO') {
    return 'Pendências não atendidas';
  }
  if (value === 'FAROL_VERDE') {
    return 'Pendências atendidas';
  } else {
    return value;
  }
}

export function StringColumnTypeOfAuction(value: string) {
  if (value == null) {
    return 'Não definido';
  } else {
    return StringColumn(value);
  }
}
