import { InterfaceSegmentedActivity } from '../../interfaces/SegmentedActivity/interfaceSegmentedActivity';
import api from '../ServiceBase';

const endpoint = 'atividade';

export const createActivity = async (data: {}) => {
  await api.post(endpoint, data);
};

export const updateActivity = async (id: string, data: {}) => {
  await api.put(`${endpoint}/${id}`, data);
};

export const getActivityByCluster = async (nameCluster: string) => {
  const { data } = await api.get<InterfaceSegmentedActivity[]>(`${endpoint}/${nameCluster}`);
  return data;
};

export const deleteActivity = async (id: string) => {
  await api.delete(`${endpoint}/${id}`);
};
