import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import { ConsultationUpdateProps, EditConsultationUpdateModalProps } from './Type';
import CloseIcon from '@mui/icons-material/Close';
import { editConsultationUpdateModalStyle } from './EditConsultationUpdateModalStyle';
import { useEffect, useState } from 'react';
import { DesktopDatePicker } from '@mui/lab';
import { getConsultationUpdate, updateConsultationUpdate } from '../../../../services/Service';
import { useAlerts } from '../../../../hooks/useAlerts/useAlerts';
import { queryClient } from '../../../../services/QueryClient';
import { AlertSeverityEnum, AlertMessagesEnum } from '../../../../hooks/useAlerts/useAlertsEnums';

export function EditConsultationUpdateModal({ id, open, onClose }: EditConsultationUpdateModalProps) {
  const classes = editConsultationUpdateModalStyle();
  const { handleAlertCall } = useAlerts();

  useEffect(() => {
    if (id !== '') {
      getConsultationUpdate(id).then(res => {
        setAtualizacao(res.atualizacao);
        setDataAtualizacao(res.dataAtualizacao as Date);
      });
    }
  }, [id, open]);

  const [atualizacao, setAtualizacao] = useState('');
  const [dataAtualizacao, setDataAtualizacao] = useState<Date | null>(null);

  const handleSaveUpdate = () => {
    const dados = {
      atualizacao,
      dataAtualizacao
    };

    if (atualizacao === '' || dataAtualizacao === null) {
      handleAlertCall(AlertSeverityEnum.INFO, AlertMessagesEnum.INFO);
    }
    if (atualizacao !== '' && dataAtualizacao !== null) {
      updateConsultationUpdate(dados, id)
        .then(() => handleAlertCall(AlertSeverityEnum.SUCCESS, AlertMessagesEnum.SUCCESS))
        .then(() => queryClient.invalidateQueries('consultations'))
        .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
      onClose();
    }
  };

  function resetFields() {
    setAtualizacao(atualizacao);
    setDataAtualizacao(dataAtualizacao);
  }

  return (
    <Modal open={open}>
      <Box className={classes.container}>
        <Grid container maxWidth={'xl'}>
          <Grid item xs={11}>
            <Typography className={classes.title}>Editar atualização</Typography>
          </Grid>
          <Grid sx={{ textAlign: 'right' }} item xs={1}>
            <Button
              className={classes.closeIcon}
              onClick={() => {
                resetFields();
                onClose();
              }}
            >
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
        <Box mt="2rem">
          <TextField
            multiline
            fullWidth
            id="outlined-basic"
            label="Atualização"
            variant="outlined"
            value={atualizacao}
            onChange={event => setAtualizacao(event.target.value)}
            className={classes.labelColor}
          />
          <DesktopDatePicker
            label="Data de atualização"
            value={dataAtualizacao}
            onChange={event => {
              setDataAtualizacao(event);
            }}
            renderInput={params => (
              <TextField sx={{ marginTop: '1.5rem' }} className={classes.labelColor} {...params} />
            )}
          />
        </Box>
        <Box sx={{ mt: '1.5rem', display: 'flex', gap: '1rem', justifyContent: 'end' }}>
          <Button
            sx={{ boxShadow: 'none' }}
            variant="contained"
            color="error"
            onClick={() => {
              onClose();
              resetFields();
            }}
          >
            Cancelar
          </Button>
          <Button onClick={handleSaveUpdate} sx={{ boxShadow: 'none' }} variant="contained">
            Salvar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
