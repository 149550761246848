import { Container, Autocomplete, TextField, Button, Grid, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { useAlerts } from '../../../hooks/useAlerts/useAlerts';
import { useGetAcronyms } from '../../../hooks/useGetAcronyms/useGetAcronyms';
import { newKeyword } from '../../../services/Service';
import { queryClient } from '../../../services/QueryClient';
import { AlertMessagesEnum, AlertSeverityEnum } from '../../../hooks/useAlerts/useAlertsEnums';
import { KeywordsProps } from './Type';
import { DataGridTable } from '../DataGridTable/DataGridTable';

export function Keywords({ dataRows, dataColumns, isEditable }: KeywordsProps) {
  const { handleAlertCall } = useAlerts();
  const { acronyms } = useGetAcronyms();

  const [active, setActive] = useState('');
  const [keywords, setKeywords] = useState<string[]>([]);

  function handleCreateKeyword() {
    if (keywords.length >= 1 && active != '') {
      const data = {
        palavras: keywords,
        ativoOperacional: active
      };
      newKeyword(data)
        .then(() => handleAlertCall(AlertSeverityEnum.SUCCESS, AlertMessagesEnum.SUCCESS))
        .then(() => formClear())
        .then(() => queryClient.invalidateQueries('palavras-chave'))
        .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
    }
    if (keywords.length >= 1 && active === '') {
      const data = {
        palavras: keywords
      };
      newKeyword(data)
        .then(() => handleAlertCall(AlertSeverityEnum.SUCCESS, AlertMessagesEnum.SUCCESS))
        .then(() => formClear())
        .then(() => queryClient.invalidateQueries('palavras-chave'))
        .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
    }
    if (keywords.length == 0) {
      handleAlertCall(AlertSeverityEnum.INFO, AlertMessagesEnum.INFO);
    }
  }

  function formClear() {
    setActive('');
    setKeywords([]);
  }

  return (
    <Container sx={{ width: '90vw', height: '90vh', padding: '1rem', margin: '0 auto' }} maxWidth={false}>
      <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <Grid item xs={3}>
          <Autocomplete
            id="listaAtivos"
            onChange={(event, value) => setActive(String(value?.id))}
            options={acronyms ? acronyms : []}
            getOptionLabel={option => option?.siglaDoAtivo}
            color="primary"
            renderInput={params => (
              <TextField
                {...params}
                InputLabelProps={{
                  style: { color: '#000' }
                }}
                label="Ativos"
              />
            )}
          />
        </Grid>

        <Grid item xs={7}>
          <Autocomplete
            multiple
            options={[]}
            freeSolo
            onChange={(_event, value) => {
              setKeywords(value);
            }}
            value={[...keywords]}
            renderTags={(value, getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={params => (
              <TextField
                {...params}
                label="Palavras-chave"
                fullWidth={true}
                placeholder="Adicione as palavras-chave"
                InputLabelProps={{
                  variant: 'outlined',
                  style: { color: '#000', backgroundColor: '#e5e5e5', padding: '0 7px', top: '-1px' }
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={2}>
          <Button
            startIcon={<AddIcon />}
            onClick={handleCreateKeyword}
            variant="contained"
            sx={{ display: 'flex', width: '100%', height: '3.5rem' }}
          >
            Adicionar
          </Button>
        </Grid>
      </Grid>
      <Container disableGutters maxWidth={false} sx={{ padding: '0 0', height: '81vh', marginTop: '0.8rem' }}>
        <DataGridTable dataRows={dataRows} dataColumns={dataColumns} isEditable={isEditable} />
      </Container>
    </Container>
  );
}
