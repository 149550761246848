import React from 'react';
import { AlertProvider } from '../hooks/useAlerts/useAlerts';
import { Active } from '../components/organisms/Active/Active';

export default function DocumentsPage(): JSX.Element {
  return (
    <AlertProvider>
      <Active />
    </AlertProvider>
  );
}
