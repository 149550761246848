import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
export const activesFilterStyles = makeStyles<Theme>((theme: Theme) => ({
  labelColor: {
    '& .MuiInputLabel-formControl': {
      color: theme.palette.text.primary
    }
  },
  formControlLabelColor: {
    color: theme.palette.text.primary
  }
}));
