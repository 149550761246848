import InputMask from 'react-input-mask';
import { TextField } from '@mui/material';
import { InputFieldMaskProps } from './Type';
import { inputFieldMaskStyle } from './InputFieldMaskStyle';

export function InputFieldMask({ mask, value, onChange, ...props }: InputFieldMaskProps): JSX.Element {
  const classes = inputFieldMaskStyle();
  return (
    <InputMask mask={mask} value={value} onChange={onChange}>
      {() => <TextField {...props} className={classes.labelColor} />}
    </InputMask>
  );
}
