import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const publicConsultationsBaseStyle = makeStyles<Theme>((theme: Theme) => ({
  container: {
    margin: '1rem',
    display: 'flex',
    flexWrap: 'wrap'
  },

  toolbar: {
    padding: '0 1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  toolbarItems: {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: ''
  },

  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    margin: '1rem 0'
  },

  grid: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },

  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 'fit-content'
  },

  content: {
    background: '#e5e5e5',
    margin: '1rem',
    padding: '1rem',
    maxHeight: '700px',
    overflowY: 'auto'
  },

  card: {
    '& + div': {
      marginTop: '1rem'
    }
  },

  cardTypography: {
    fontWeight: 'bold',
    '& span': {
      fontWeight: 'normal'
    }
  },

  editDeleteContainer: {
    display: 'flex',
    justifyContent: 'end',
    gap: '1rem',
    margin: '1rem 0'
  },

  updateContainer: {
    padding: '1rem',
    margin: '1rem 1rem 0 1rem',
    border: '1px solid #e5e5e5',
    borderRadius: '4px'
  },

  updateInputContainer: {
    marginTop: '0.5rem',
    padding: '1rem'
  },

  filterBox: { margin: '1rem 0', border: '1px solid #e5e5e5', borderRadius: '4px' },

  labelColor: {
    '& .MuiInputLabel-formControl': {
      color: theme.palette.text.primary
    }
  }
}));
