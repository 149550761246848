import { InterfaceSegmentedActivity } from '../../interfaces/SegmentedActivity/interfaceSegmentedActivity';
import { queryClient } from '../QueryClient';
import api from '../ServiceBase';

const endpoint = 'atualizacao-da-atividade';

export const createActivityComment = async (id: string, data: {}) => {
  await api.post(`${endpoint}/${id}`, data);
};

export const updateActivityComment = async (id: string, data: {}) => {
  await api.put(`${endpoint}/${id}`, data);
};

export const deleteActivityComment = async (id: string) => {
  await api.delete(`${endpoint}/${id}`);
};
