import { Box, Button, Modal, Typography } from '@mui/material';
import { worksheetsUploadModalStyle } from './WorksheetsUploadModalStyle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import { LoadingProgress } from '../../LoadingProgress/LoadingProgress';
import { useModalUploads } from '../../FormUploadBaseGeracao/hook/FileUploadModal';

export function WorksheetsUploadModal() {
  const classes = worksheetsUploadModalStyle();

  const { open, file, modalState, handleOpenCloseModal, messageError, formClear } = useModalUploads();

  const closeModal = () => {
    formClear();
    handleOpenCloseModal();
  };

  return (
    <div>
      <Modal open={open} aria-labelledby="upload-documento-apolices" aria-describedby="upload-documento-apolices">
        <Box className={classes.modal}>
          {modalState !== 'initial' && (
            <Box className={classes.closeButton}>
              <IconButton onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}

          <Box className={classes.content}>
            {modalState === 'initial' ? (
              <LoadingProgress />
            ) : (
              <CloudUploadIcon className={classes.icon} htmlColor="#03A9F4" />
            )}
            <Typography fontWeight="bold" mt="1rem">
              Upload de Arquivo
            </Typography>
            <Typography fontWeight="bold" variant="h6" mt="1rem">
              {file.file}
            </Typography>

            <Box>
              {modalState === 'initial' && (
                <Box className={classes.content}>
                  <Typography m="1rem 0" sx={{ textAlign: 'center' }}>
                    O arquivo a ser enviado deve está no formato .xlsx, porém na planilha de Dados do ePowerBay o
                    formato deve ser .xls e na planilha Nodal formato .xlsm .
                  </Typography>
                  <Box className={classes.buttonsContainer}>
                    <LoadingButton
                      loading={true}
                      size="large"
                      disableElevation
                      color="primary"
                      variant="contained"
                      sx={{ height: '3rem', width: '8rem' }}
                    />
                  </Box>
                </Box>
              )}

              {modalState === 'success' && file.progress === 100 && (
                <Box className={classes.content}>
                  <Box m="2rem" className={classes.content}>
                    <CheckCircleIcon sx={{ margin: '1rem' }} color="success" className={classes.icon} />
                    <Typography fontWeight="bold" mb="2rem">
                      Upload concluído com SUCCESSO!
                    </Typography>
                  </Box>
                  <Button size="large" disableElevation color="success" variant="contained" onClick={closeModal}>
                    Concluir
                  </Button>
                </Box>
              )}

              {modalState === 'error' && (
                <Box className={classes.content}>
                  <Box m="2rem" className={classes.content}>
                    <CancelIcon sx={{ margin: '1rem' }} color="error" className={classes.icon} />
                    <Typography fontWeight="bold" mb="2rem" sx={{ textAlign: 'center' }}>
                      {messageError}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
