import { Link } from '@mui/material';
import { isNil } from 'lodash';
import { SourceDescription } from '../interfaces/ProjectsInDevelopment/enumProjectsInDevelopment';
import {
  InterfaceDataBySection,
  InterfaceProjectsInDevelopment,
  InterfaceTableSections,
  ProjectsStatus
} from '../interfaces/ProjectsInDevelopment/interfaceProjectsInDevelopment';
import { DateFormatter } from './DateFormatter';
import { FormatCnpj } from './FormatterActiveDetails';

export function formatProject(data: InterfaceProjectsInDevelopment[]): InterfaceProjectsInDevelopment[] {
  return data.map(project => ({
    ...project,
    //Dados Principais
    fonte: SourceDescription[project.fonte as keyof typeof SourceDescription],
    potenciaMw: formatPower(project.potenciaMw),
    cnpj: FormatCnpj(project.cnpj),
    // DRO
    dataSolicitacaoDro: formatDate(project.dataSolicitacaoDro),
    dataDespacho: formatDate(project.dataDespacho),
    // Informação de Acesso
    resultadoIa: formatResult(project.resultadoIa),
    dataSolicitacaoIa: formatDate(project.dataSolicitacaoIa),
    dataInformeAcesso: formatDate(project.dataInformeAcesso),
    statusIa: formatStatus(project.dataSolicitacaoIa, project.protocoloIa),
    // Outorga
    dataSolicitacaoOutorga: formatDate(project.dataSolicitacaoOutorga),
    dataOutorga: formatDate(project.dataOutorga),
    statusOutorga: formatStatus(project.dataSolicitacaoOutorga, project.outorga),
    // Parecer de Acesso
    dataSolicitacaoPa: formatDate(project.dataSolicitacaoPa),
    dataParecerAcesso: formatDate(project.dataParecerAcesso),
    resultadoPa: formatResult(project.resultadoPa),
    statusPa: formatStatus(project.dataSolicitacaoPa, project.protocoloPa)
  }));
}

function formatPower(value: string | number): string {
  if (typeof value === 'string') {
    return value.replace('.', ',');
  } else if (typeof value === 'number') {
    return value.toString().replace('.', ',');
  } else {
    return value;
  }
}

function formatResult(value: string): string {
  switch (value) {
    case 'POSITIVO': {
      return 'Positivo';
    }
    case 'POSITIVO_COM_RESTRICOES': {
      return 'Positivo com Restrições';
    }
    case 'NEGATIVO': {
      return 'Negativo';
    }
    default: {
      return 'Não definido';
    }
  }
}

function formatStatus(dataSolicitacao: string | null, despacho: string): ProjectsStatus {
  if (!isNil(despacho)) return 'Emitido';
  if (!isNil(dataSolicitacao)) return 'Solicitado';
  if (isNil(dataSolicitacao)) return 'Não Solicitado';
  return 'Não definido';
}

function formatDate(value: string | null): string | null {
  if (isNil(value)) return 'NA';
  const dateFormated = DateFormatter(value);
  return dateFormated !== 'Invalid DateTime' ? dateFormated : null;
}

export function getDataBySections(data: InterfaceProjectsInDevelopment): InterfaceDataBySection {
  return {
    mainSection: getMainSection(data),
    droSection: getDROSection(data),
    accessInformationSection: getAccessInformationSection(data),
    grantSection: getGrantSection(data),
    accessOpinionSection: getAccessOpinionSection(data)
  };
}

function getMainSection(data: InterfaceProjectsInDevelopment): InterfaceTableSections {
  return {
    headers: [
      'Projeto',
      'Fonte',
      'Potência MW',
      'Nº de Usínas',
      'Empresa Principal',
      'CNPJ',
      'UF',
      'Municípios',
      'Nº Processo Aneel',
      'SE de Interesse'
    ],
    data: [
      [
        data.projeto,
        data.fonte,
        data.potenciaMw,
        data.numeroDeUsinas,
        data.empresaPrincipal,
        data.cnpj,
        data.uf,
        data.municipio,
        data.processoAneel,
        data.seDeInteresse
      ]
    ]
  };
}

function getDROSection(data: InterfaceProjectsInDevelopment): InterfaceTableSections {
  return {
    headers: ['Data de Solicitação', 'Nº Protocolo', 'Despacho', 'Data do Despacho'],
    data: [
      [
        data.dataSolicitacaoDro,
        data.protocoloDro,
        data.linkDespacho ? (
          <Link href={data.linkDespacho} target="_blank">
            {data.despacho}
          </Link>
        ) : (
          data.despacho
        ),
        data.dataDespacho
      ]
    ]
  };
}

function getAccessInformationSection(data: InterfaceProjectsInDevelopment): InterfaceTableSections {
  return {
    headers: ['Data de Solicitação', 'Nº SGAcesso', 'Data Informe de Acesso', 'Resultado'],
    data: [
      [
        data.dataSolicitacaoIa,
        data.linkInformeAcesso ? (
          <Link href={data.linkInformeAcesso} target="_blank">
            {data.protocoloIa}
          </Link>
        ) : (
          data.protocoloIa
        ),
        data.dataInformeAcesso,
        data.resultadoIa
      ]
    ]
  };
}

function getGrantSection(data: InterfaceProjectsInDevelopment): InterfaceTableSections {
  return {
    headers: ['Data de Solicitação', 'Nº Protocolo', 'Outorga', 'Data Outorga'],
    data: [
      [
        data.dataSolicitacaoOutorga,
        data.protocoloOutorga,
        data.linkOutorga ? (
          <Link href={data.linkOutorga} target="_blank">
            {data.outorga}
          </Link>
        ) : (
          data.outorga
        ),
        data.dataOutorga
      ]
    ]
  };
}

function getAccessOpinionSection(data: InterfaceProjectsInDevelopment): InterfaceTableSections {
  return {
    headers: ['Data de Solicitação', 'Nº SGAcesso', 'Data do Parecer de Acesso', 'Resultado'],
    data: [
      [
        data.dataSolicitacaoPa,
        data.linkParecerAcesso ? (
          <Link href={data.linkParecerAcesso} target="_blank">
            {data.protocoloPa}
          </Link>
        ) : (
          data.protocoloPa
        ),
        data.dataParecerAcesso,
        data.resultadoPa
      ]
    ]
  };
}
