import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Container, Grid, Zoom } from '@mui/material';
import { DateTime } from 'luxon';
import { VerifyStatus } from '../../../util/FormatterDetailsDAPRs';
import { PendencyDetails } from '../../../interfaces/Pendency/interfacePendency';
import { pendenciaStyle } from './PendenciaStyle';
import { deletePendency, updatePendency } from '../../../services/Service';
import { useSetEditable } from '../../../hooks/useSetEditable/useSetEditable';
import { DeleteDialog } from '../Modal/DeleteDialog/DeleteDialog';
import { useState } from 'react';
import { FormPendencia } from '../FormPendencia/FormPendencia';
import { useAlerts } from '../../../hooks/useAlerts/useAlerts';
import { queryClient } from '../../../services/QueryClient';
import { DateFormatter } from '../../../util/DateFormatter';
import { AlertMessagesEnum, AlertSeverityEnum } from '../../../hooks/useAlerts/useAlertsEnums';

export function Pendencia({
  id,
  queryId,
  descricao,
  prazoPendencia,
  statusPlanoDeAcao,
  prazoPlanoAcao,
  createAt,
  updateAt
}: PendencyDetails): JSX.Element {
  const classes = pendenciaStyle();
  const { handleAlertCall } = useAlerts();
  const [openDelete, setDeleteOpen] = useState(false);

  const [openEdit, setEditOpen] = useState(true);

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleEdit = () => {
    setEditOpen(prev => !prev);
  };
  const handleDelete = () => {
    deletePendency(id)
      .then(() => queryClient.invalidateQueries('DAPRs'))
      .then(() => queryClient.invalidateQueries(['pendency', { id: queryId }]))
      .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
  };

  const { editable } = useSetEditable();
  let validade = '';
  if (statusPlanoDeAcao === 'CONCLUIDO') {
    validade = 'valido';
  }
  if (DateTime.fromISO(`${prazoPendencia}`) > DateTime.now() && statusPlanoDeAcao !== 'CONCLUIDO') {
    validade = 'dentroDoPrazo';
  }
  if (
    DateTime.fromISO(`${prazoPendencia}`) <= DateTime.now() ||
    prazoPendencia === undefined ||
    (prazoPendencia === null && statusPlanoDeAcao !== 'CONCLUIDO')
  ) {
    validade = 'vencido';
  }
  if (
    DateTime.fromISO(`${prazoPendencia}`).year === DateTime.now().year &&
    statusPlanoDeAcao !== 'CONCLUIDO' &&
    DateTime.fromISO(`${prazoPendencia}`).day > DateTime.now().day
  ) {
    validade = 'atencao';
  }

  return (
    <div>
      <Container className={classes.container}>
        <Zoom in={openEdit}>
          <Accordion sx={{ display: openEdit ? 'inherit' : 'none' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="pendencias" id="pendencias">
              <Grid container spacing={1}>
                <Grid className={classes.iconPendencia} xs={1}>
                  {validade == 'valido' && <CheckIcon fontSize="large" style={{ color: '#21AC2A' }} />}
                  {validade == 'dentroDoPrazo' && <CheckIcon fontSize="large" style={{ color: '#21AC2A' }} />}
                  {validade == 'vencido' && <WarningIcon fontSize="large" style={{ color: '#C20000' }} />}
                  {validade == 'atencao' && <CachedIcon fontSize="large" style={{ color: '#FD8B3F' }} />}
                </Grid>
                <Grid item xs={7}>
                  <Typography className={classes.namePendencia}>
                    {validade == 'valido' && 'Pendência Atendida'}
                    {validade == 'dentroDoPrazo' && 'Pendência Dentro do Prazo'}
                    {validade == 'vencido' && 'Pendência Vencida'}
                    {validade == 'atencao' && 'Pendência Próxima ao Vencimento'}
                    <Typography color="initial">{descricao}</Typography>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    align="right"
                    className={classes.namePendencia}
                    paddingRight={4}
                    color={validade == 'vencido' ? '#C20000' : 'initial'}
                  >
                    Data de Expiração:
                    <Typography>
                      {prazoPendencia != null || prazoPendencia != undefined
                        ? DateFormatter(`${prazoPendencia}`)
                        : 'Data indisponível'}
                    </Typography>
                  </Typography>
                </Grid>
                <Container
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    marginTop: '1rem',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    sx={{ display: editable ? 'inherit' : 'none' }}
                    startIcon={<EditIcon />}
                    color="warning"
                    disabled={!editable}
                    onClick={handleEdit}
                  >
                    Editar
                  </Button>
                  <Button
                    sx={{ display: editable ? 'inherit' : 'none' }}
                    startIcon={<DeleteIcon />}
                    color="error"
                    disabled={!editable}
                    onClick={handleDeleteOpen}
                  >
                    Excluir
                  </Button>
                  <DeleteDialog
                    open={openDelete}
                    onClose={handleDeleteClose}
                    handleDelete={handleDelete}
                    text="Se você excluir, não poderá mais reaver essa informação."
                  />
                </Container>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} sx={{ marginTop: '24px', display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={5}>
                  <Typography fontWeight="bold">Descrição:</Typography>
                  {validade == 'valido' && 'A pendência está em dia.'}
                  {validade == 'vencido' && 'A pendência venceu, regularize a situação.'}
                  {validade == 'dentroDoPrazo' && 'A pendência está dentro do prazo.'}
                  {validade == 'atencao' &&
                    'A pendência está próxima do vencimento, regularize para dar andamento ao processo.'}
                </Grid>
                <Grid item xs={3}>
                  <Typography fontWeight="bold">Status do plano de ação:</Typography>
                  {VerifyStatus(statusPlanoDeAcao)}
                </Grid>
                <Grid item xs={3}>
                  <Typography fontWeight="bold">Prazo do plano de ação:</Typography>
                  {statusPlanoDeAcao != 'NAO_HA'
                    ? DateTime.fromISO(`${prazoPlanoAcao}`)
                        .toUTC()
                        .toFormat('dd/MM/yyyy', { locale: 'pt-br' })
                    : 'Não há'}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Zoom>
      </Container>{' '}
      <Zoom in={openEdit === false}>
        <Box sx={{ display: openEdit === false ? 'inherit' : 'none', width: '100%' }}>
          <FormPendencia
            title="Editar Pendência"
            onClose={handleEdit}
            requestPendencia={updatePendency}
            id={id}
            pendenciaData={{
              id,
              queryId,
              descricao,
              prazoPendencia,
              statusPlanoDeAcao,
              prazoPlanoAcao,
              createAt,
              updateAt
            }}
          />
        </Box>
      </Zoom>
    </div>
  );
}
