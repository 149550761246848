import { DateTime } from 'luxon';

import { InterfacePublicConsultations } from '../../../interfaces/PublicConsultations/interfacePublicConsultations';
import { StringNormalize } from '../../../util/FormatterActiveDetails';
import { FilterProps } from '../PublicConsultationsBase/Type';

export function filterArrayResults(filterProps: FilterProps, iteration: InterfacePublicConsultations) {
  const filtered = {
    responsavel: StringNormalize(filterProps.responsavel),
    status: StringNormalize(filterProps.status),
    prioridade: StringNormalize(filterProps.prioridade),
    prazo: filterProps.prazo ? new Date(filterProps.prazo).toISOString() : null,
    orgao: filterProps.organ
  };

  let stringfiedItem = StringNormalize(JSON.stringify(iteration));

  const stringfiedResponsavel = StringNormalize(JSON.stringify(iteration.responsavel));

  const checkWithNoDate = () => {
    if (filtered.orgao !== '') {
      const filteredOrgaos = filtered.orgao.split(',');
      const organQuantity = filteredOrgaos.length;

      let organQuantityFound = 0;
      filteredOrgaos.map(orgaoName => {
        if (iteration.orgao.includes(orgaoName)) {
          organQuantityFound++;
        }
      });

      if (organQuantityFound === organQuantity) {
        stringfiedItem = StringNormalize(JSON.stringify(iteration));
      } else {
        return '';
      }
    }

    if (filtered.status !== '') {
      return (
        stringfiedItem.includes(filtered.responsavel === '' ? '' : filtered.responsavel) &&
        iteration.status !== undefined &&
        iteration.status !== null &&
        iteration.status !== '' &&
        iteration.status.toLowerCase() === filtered.status.toLowerCase() &&
        stringfiedItem.includes(filtered.prioridade === '' ? '' : filtered.prioridade)
      );
    } else {
      return (
        stringfiedResponsavel.includes(filtered.responsavel === '' ? '' : filtered.responsavel) &&
        stringfiedItem.includes(filtered.prioridade === '' ? '' : filtered.prioridade)
      );
    }
  };

  const dateCheck = DateTime.fromISO(String(iteration.dataConclusao)) <= DateTime.fromISO(String(filtered.prazo));

  return filtered.prazo ? checkWithNoDate() && dateCheck : checkWithNoDate();
}

export function searchResults(iteration: InterfacePublicConsultations, search: string) {
  const formattedSearch = StringNormalize(search);

  const stringfiedItem = StringNormalize(JSON.stringify(iteration));

  return stringfiedItem.includes(formattedSearch);
}
