import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { tableOptions } from '../../../../../util/TableOptions';
import { ProjectsInDevelopmentDetail } from '../ProjectsInDevelopmentDetail/ProjectsInDevelopmentDetail';

export const projectsInDevelopmentColumns: MUIDataTableColumnDef[] = [
  {
    name: 'projeto',
    label: 'Projeto',
    options: { sortThirdClickReset: true }
  },
  {
    name: 'fonte',
    label: 'Fonte',
    options: { sortThirdClickReset: true }
  },
  {
    name: 'empresaPrincipal',
    label: 'Empresa',
    options: { sortThirdClickReset: true }
  },
  {
    name: 'dataSolicitacaoDro',
    label: 'DRO - Data do Despacho',
    options: {
      display: false,
      viewColumns: false
    }
  },
  {
    name: 'dataDespacho',
    label: 'Data DRO',
    options: { sortThirdClickReset: true }
  },
  {
    name: 'statusOutorga',
    label: 'Outorga',
    options: { sortThirdClickReset: true }
  },
  {
    name: 'statusIa',
    label: 'Informação de acesso',
    options: { sortThirdClickReset: true }
  },
  {
    name: 'statusPa',
    label: 'Parecer de Acesso',
    options: { sortThirdClickReset: true }
  },
  {
    name: 'resultadoIa',
    label: 'Resultado da Informação',
    options: { sortThirdClickReset: true }
  }
];

export const projectsInDevelopmentTableOptions: MUIDataTableOptions = {
  ...tableOptions,
  expandableRows: true,
  expandableRowsHeader: false,
  expandableRowsOnClick: true,
  renderExpandableRow: (_rowData, rowMeta) => {
    return <ProjectsInDevelopmentDetail rowMeta={rowMeta} />;
  }
};
