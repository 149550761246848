import { InterfaceDapr } from '../../interfaces/Dapr/interfaceDapr';
import api from '../ServiceBase';

const endpoint = 'dapr';

export const getDaprs = async () => {
  const { data } = await api.get<InterfaceDapr[]>(endpoint);
  return data;
};

export const getDaprById = async (id: string) => {
  const { data } = await api.get<InterfaceDapr>(`${endpoint}/${id}`);
  return data;
};

export const createDapr = async (data: {}) => {
  await api.post(endpoint, data);
};

export const deleteDapr = async (id: string) => {
  await api.delete(`${endpoint}/${id}`);
};

export const updateDapr = async (id: string, data: {}) => {
  return await api.put(`${endpoint}/${id}`, data);
};
