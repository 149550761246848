import { createContext } from 'react';
import { AlertSeverityEnum } from './useAlertsEnums';

export interface ContextData {
  openMessage: boolean;
  handleAlertCall: (context: AlertSeverityEnum, messageText: string) => void;
  handleClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
}

export const AlertContext = createContext<ContextData>({} as ContextData);
