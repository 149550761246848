import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const formPendenciaStyle = makeStyles<Theme>((theme: Theme) => ({
  container: {
    maxWidth: 'xl',
    marginTop: '1rem',
    '.&  MuiContainer-maxWidthLg ': {
      padding: '0px'
    }
  },

  iconPendencia: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center'
  },

  namePendencia: {
    fontWeight: 'bold',
    fontSize: '1.25rem'
  },

  labelColor: {
    '& .MuiInputLabel-formControl': {
      color: theme.palette.text.primary
    }
  },

  formControlLabelColor: {
    color: theme.palette.text.primary
  }
}));
