import { isDate } from 'lodash';
import { InterfaceMapPrecedent } from '../../../interfaces/MapPrecedent/interfaceMapPrecedent';
import { DateCompare, DateFormatter } from '../../../util/DateFormatter';
import { StringNormalize } from '../../../util/FormatterActiveDetails';
import { FilterProps } from './Type';

export function filterArrayResults(filterProps: FilterProps, iteration: InterfaceMapPrecedent) {
  const filtered = {
    relator: StringNormalize(filterProps.relator),
    areaResponsavel: StringNormalize(filterProps.area),
    interessado: StringNormalize(filterProps.interessado),
    data: filterProps.data
  };

  const stringfiedRelator = StringNormalize(JSON.stringify(iteration.relator));
  const stringfiedAreaResponsavel = StringNormalize(JSON.stringify(iteration.areaResponsavel));
  const stringfiedInteressado = StringNormalize(JSON.stringify(iteration.interessado));

  const stringfiedDate = new Date(
    +iteration.data.substring(0, 4),
    +iteration.data.substring(5, 7) - 1,
    +iteration.data.substring(8, 10)
  );

  const checkWithNoDate = () => {
    return (
      stringfiedRelator.includes(filtered.relator) &&
      stringfiedAreaResponsavel.includes(filtered.areaResponsavel) &&
      stringfiedInteressado.includes(filtered.interessado)
    );
  };
  if (filtered.data) {
    const dateCheck = DateCompare(filtered.data, stringfiedDate);
    return dateCheck && checkWithNoDate();
  }
  return checkWithNoDate();
}

export function searchResults(iteration: InterfaceMapPrecedent, search: string[]) {
  const filteredWords = Object.keys(iteration.palavras);
  const wordsQuantity = search.length;
  const searchString = StringNormalize(search.join(','));

  let wordsQuantityFound = 0;
  filteredWords.forEach(item => {
    if (searchString.includes(StringNormalize(item))) {
      wordsQuantityFound++;
    }
  });

  if (wordsQuantityFound === wordsQuantity) {
    return iteration;
  } else {
    return '';
  }
}
