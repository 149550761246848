import { DateTime } from 'luxon';

import { InterfaceActive } from '../../../interfaces/Active/interfaceActive';
import { StringNormalize } from '../../../util/FormatterActiveDetails';
import { FilterProps } from './Type';

export function filterArrayResults(filterProps: FilterProps, iteration: InterfaceActive) {
  const filtered = {
    siglaDoAtivo: StringNormalize(filterProps.siglaDoAtivo),
    cluster2: StringNormalize(filterProps.cluster2),
    empresa: StringNormalize(filterProps.empresa),
    uf: StringNormalize(filterProps.uf),
    fonte: StringNormalize(filterProps.fonte),
    potencia: StringNormalize(filterProps.potencia),
    gf: StringNormalize(filterProps.gf),
    pontoDeConexao: StringNormalize(filterProps.pontoDeConexao),
    tensaoDoPontoDeConexao: StringNormalize(filterProps.tensaoDoPontoDeConexao),
    fimOutorga: filterProps.fimOutorga ? new Date(filterProps.fimOutorga).toISOString() : null
  };

  const stringfiedSigla = StringNormalize(JSON.stringify(iteration.siglaDoAtivo));
  const stringfiedCluster = StringNormalize(JSON.stringify(iteration.cluster2));
  const stringfiedEmpresa = StringNormalize(JSON.stringify(iteration.empresa));
  const stringfiedPotencia = StringNormalize(JSON.stringify(iteration.potencia));
  const stringfiedGf = StringNormalize(JSON.stringify(iteration.gf));
  const stringfiedConexao = StringNormalize(JSON.stringify(iteration.pontoDeConexao));
  const stringfiedTensao = StringNormalize(JSON.stringify(iteration.tensaoDoPontoDeConexao));
  const stringfiedUf = StringNormalize(JSON.stringify(iteration.uf));
  const stringfiedFonte = StringNormalize(JSON.stringify(iteration.fonte));

  const checkWithNoDate = () => {
    return (
      stringfiedSigla.includes(filtered.siglaDoAtivo === '' ? '' : filtered.siglaDoAtivo) &&
      stringfiedCluster.includes(filtered.cluster2 === '' ? '' : filtered.cluster2) &&
      stringfiedEmpresa.includes(filtered.empresa === '' ? '' : filtered.empresa) &&
      stringfiedPotencia.includes(filtered.potencia === '' ? '' : filtered.potencia) &&
      stringfiedGf.includes(filtered.gf === '' ? '' : filtered.gf) &&
      stringfiedConexao.includes(filtered.pontoDeConexao === '' ? '' : filtered.pontoDeConexao) &&
      stringfiedTensao.includes(filtered.tensaoDoPontoDeConexao === '' ? '' : filtered.tensaoDoPontoDeConexao) &&
      stringfiedUf.includes(filtered.uf === '' ? '' : filtered.uf) &&
      stringfiedFonte.includes(filtered.fonte === '' ? '' : filtered.fonte)
    );
  };

  const dateCheck = DateTime.fromISO(String(iteration.fimOutorga)) <= DateTime.fromISO(String(filtered.fimOutorga));

  return filtered.fimOutorga ? checkWithNoDate() && dateCheck : checkWithNoDate();
}
