import { InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { formatNumber, notFormatNumber, verifiedNumber } from '../../../util/FormatterNumber';
import { inputNumberStyle } from './InputNumberStyle';
import { InputNumberProps } from './Type';

export const InputNumber = ({ value, valueUpdate, desabilit }: InputNumberProps) => {
  const classes = inputNumberStyle();

  const handleCheckValue = (event: any): void => {
    const valueFormat = event.target.value;

    const valueUnFormat = notFormatNumber(valueFormat);

    const number = verifiedNumber(valueUnFormat);

    const resultado = formatNumber(number);

    valueUpdate(resultado);
  };

  return (
    <TextField
      fullWidth
      className={classes.labelColor}
      value={value}
      onChange={handleCheckValue}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <span className={classes.inputNumber}> R$</span>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <span className={classes.inputNumber}>,00</span>
          </InputAdornment>
        )
      }}
      disabled={desabilit}
      label="Valor"
      multiline
      variant="outlined"
    />
  );
};
