import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Container,
  Grid,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { PublicConsultationsFilter } from '../PublicConsultationsFilter/PublicConsultationsFilter';
import { publicConsultationsBaseStyle } from './PublicConsultationsBaseStyle';
import AddIcon from '@mui/icons-material/Add';
import { Search, ToggleEditDelete } from '../../atoms';
import { FilterProps, PublicConsultationsBaseProps } from './Type';
import { useEffect, useState } from 'react';
import { filterArrayResults, searchResults } from '../PublicConsultationsFilter/PublicConsultationsFilterFormatters';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSearch } from '../../../hooks/useSearch/useSearch';
import { useSetEditable } from '../../../hooks/useSetEditable/useSetEditable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DesktopDatePicker } from '@mui/lab';
import { EditConsultationUpdateModal } from '../Modal/EditConsultationUpdateModal/EditConsultationUpdateModal';
import { CreateEditConsultationModal } from '../Modal/CreateEditConsultationModal/CreateEditConsultationModal';
import { createConsultationUpdate, deleteConsultation, deleteConsultationUpdate } from '../../../services/Service';
import {
  capitalizeFirstLetter,
  formatFilterFields,
  formatFilterValues,
  formatPriority,
  formatValue,
  removeEmptyFields
} from './formatter';
import { DeleteDialog } from '../Modal/DeleteDialog/DeleteDialog';
import { queryClient } from '../../../services/QueryClient';
import { useAlerts } from '../../../hooks/useAlerts/useAlerts';
import { DateFormatter } from '../../../util/DateFormatter';
import { InterfacePublicConsultations } from '../../../interfaces/PublicConsultations/interfacePublicConsultations';
import { AlertMessagesEnum, AlertSeverityEnum } from '../../../hooks/useAlerts/useAlertsEnums';
import { useAuth } from '../../../hooks/useAuth/useAuth';

export function PublicConsultationsBase({ data }: PublicConsultationsBaseProps) {
  const { busca } = useSearch();
  const { editable } = useSetEditable();
  const { editor } = useAuth();

  const [deleteConsultDialog, setDeleteConsultDialog] = useState(false);
  const [deleteUpdateDialog, setDeleteUpdateDialog] = useState(false);

  const [modalContext, setModalContext] = useState('');
  const [iteration, setIteration] = useState<InterfacePublicConsultations>({} as InterfacePublicConsultations);
  const [updateId, setUpdateId] = useState('');

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = (context: string) => {
    setModalContext(context);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [updateText, setUpdateText] = useState('');
  const [updateDate, setUpdateDate] = useState<Date | null>(new Date());

  const handleCreateUpdate = (id: string) => {
    const data = {
      atualizacao: updateText,
      dataAtualizacao: updateDate
    };
    if (updateText === '' || updateDate === null) {
      handleAlertCall(AlertSeverityEnum.INFO, AlertMessagesEnum.INFO);
    } else {
      createConsultationUpdate(id, data)
        .then(() => handleAlertCall(AlertSeverityEnum.SUCCESS, AlertMessagesEnum.SUCCESS))
        .then(() => setUpdateText(''))
        .then(() => setUpdateDate(new Date()))
        .then(() => queryClient.invalidateQueries('consultations'))
        .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
    }
  };

  const [renderData, setRenderData] = useState<InterfacePublicConsultations[] | undefined>(data);
  const [activeFilters, setActiveFilters] = useState({});

  const classes = publicConsultationsBaseStyle();

  const setFilterData = (value: FilterProps) => {
    setActiveFilters(removeEmptyFields(value as {}));
    setRenderData(
      data?.filter(item => {
        return filterArrayResults(value, item);
      })
    );
  };

  const clear = () => {
    setActiveFilters({});
    if (data) {
      setRenderData(data);
    } else {
      setRenderData([]);
    }
  };

  useEffect(() => {
    setRenderData(
      data?.filter(item => {
        return searchResults(item, busca);
      })
    );
  }, [busca, data]);

  const { handleAlertCall } = useAlerts();

  const handleDeleteConsult = () => {
    deleteConsultation(iteration.id)
      .then(() => queryClient.invalidateQueries('consultations'))
      .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
  };

  const handleDeleteConsultUpdate = () => {
    deleteConsultationUpdate(updateId)
      .then(() => queryClient.invalidateQueries('consultations'))
      .then(() => setDeleteUpdateDialog(false))
      .catch(() => handleAlertCall(AlertSeverityEnum.ERROR, AlertMessagesEnum.ERROR));
  };

  const closeDeleteConsultDialog = () => {
    setDeleteConsultDialog(false);
  };

  const closeDeleteConsultUpdateDialog = () => {
    setDeleteUpdateDialog(false);
  };

  return (
    <div className={classes.container}>
      <DeleteDialog
        open={deleteConsultDialog}
        onClose={closeDeleteConsultDialog}
        text="Esta consulta pública não ficará mais disponível."
        handleDelete={handleDeleteConsult}
      />
      <DeleteDialog
        open={deleteUpdateDialog}
        onClose={closeDeleteConsultUpdateDialog}
        text="Esta atualização não ficará mais disponível."
        handleDelete={handleDeleteConsultUpdate}
      />
      <CreateEditConsultationModal
        open={modalContext === 'create-consultation' || modalContext === 'edit-consultation' ? modalOpen : false}
        onClose={handleCloseModal}
        iteration={iteration}
        context={modalContext}
      />
      <EditConsultationUpdateModal
        open={modalContext === 'edit-update' ? modalOpen : false}
        onClose={handleCloseModal}
        id={updateId}
      />
      <Container maxWidth={false}>
        <Typography className={classes.title}>Consultas Públicas</Typography>
        <Box
          py={1}
          className={classes.filterBox}
          sx={{
            display: Object.keys(activeFilters).length !== 0 ? 'flex' : 'none',
            alignItems: 'center',
            gap: '0.5rem'
          }}
        >
          <Typography fontWeight="bold" sx={{ padding: '1rem' }}>
            Filtros aplicados:
          </Typography>{' '}
          {Object.entries(activeFilters).map(([key, value]) => {
            const render = [
              <>
                <strong>{formatFilterFields(key)}</strong>
                <span>
                  {': '}
                  {formatFilterValues(value as string)}
                </span>
              </>
            ];
            return <Chip color="secondary" key={key} label={render[0]} />;
          })}
          <Button sx={{ display: 'inherit' }} onClick={clear}>
            Limpar todos
          </Button>
        </Box>
      </Container>

      <Box className={classes.toolbar} sx={{ width: '100%' }}>
        <Tooltip title={editor ? '' : 'Você não tem permissão para esta ação'}>
          <div>
            <Button disabled={!editor} startIcon={<AddIcon />} onClick={() => handleOpenModal('create-consultation')}>
              Adicionar consulta
            </Button>
          </div>
        </Tooltip>
        <div className={classes.toolbarItems}>
          <PublicConsultationsFilter setFilterData={setFilterData} clear={clear} />
          <Search />
          <ToggleEditDelete />
        </div>
      </Box>

      <Container maxWidth={false} className={classes.content}>
        {renderData!.length <= 0 ? (
          <Box m="1rem">
            <Typography variant="h5" fontWeight="bold">
              Nada consta
            </Typography>
          </Box>
        ) : (
          renderData?.map(item => {
            return (
              <div key={item.id} className={classes.card}>
                <Accordion sx={{ padding: '1rem' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Grid container sx={{ alignItems: 'center' }}>
                      <Grid item sm={8} lg={10}>
                        <Typography className={classes.cardTypography}>
                          Consulta Pública Nº:<span> {item.numeroConsulta}</span>
                        </Typography>
                        <Typography className={classes.cardTypography}>
                          Ementa:{' '}
                          <a target="_blank" href={item.ementaLink}>
                            <span>{item.ementa}</span>
                          </a>
                        </Typography>
                      </Grid>

                      <Grid item sm={4} lg={2}>
                        <Typography className={classes.cardTypography}>
                          Data de Conclusão: <span>{DateFormatter(new Date(item.dataConclusao).toISOString())}</span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.cardTypography}>
                      Data de Abertura: <span> {DateFormatter(new Date(item.dataAbertura).toISOString())}</span>
                    </Typography>
                    <Typography className={classes.cardTypography}>
                      Prioridade: <span>{item.prioridade ? formatPriority(item.prioridade) : 'Não se aplica'}</span>
                    </Typography>
                    <Typography className={classes.cardTypography}>
                      Órgãos: <span>{item.orgao.replace(/ e /g, `, `)}.</span>
                    </Typography>
                    <Typography className={classes.cardTypography}>
                      Responsável: <span>{item.responsavel ? item.responsavel : 'Não se aplica'}</span>
                    </Typography>
                    <Typography className={classes.cardTypography}>
                      Valor Envolvido:{' '}
                      <span>
                        {item.valorEnvolvido ? 'R$ ' + formatValue(String(item.valorEnvolvido)) : 'Não se aplica'}
                      </span>
                    </Typography>
                    <Typography className={classes.cardTypography}>
                      Status: <span>{item.status ? capitalizeFirstLetter(item.status) : 'Não se aplica'}</span>
                    </Typography>
                    <Typography className={classes.cardTypography}>
                      Contribuição:{' '}
                      <span>
                        {item.contribuicao ? (
                          <a target="_blank" href={item.contribuicaoLink}>
                            {capitalizeFirstLetter(item.contribuicao)}
                          </a>
                        ) : (
                          'Não se aplica'
                        )}
                      </span>
                    </Typography>
                  </AccordionDetails>
                  {item.atualizacoes
                    ? item.atualizacoes.map(atualizacao => {
                        return (
                          <div className={classes.updateContainer} key={atualizacao.id}>
                            <Typography className={classes.cardTypography}>
                              Atualização: <span>{atualizacao.atualizacao}</span>
                            </Typography>
                            <Typography mt="1rem" className={classes.cardTypography}>
                              Data: <span>{DateFormatter(new Date(atualizacao.dataAtualizacao).toISOString())}</span>
                            </Typography>
                            <Container className={classes.editDeleteContainer} maxWidth={false}>
                              <Button
                                startIcon={<EditIcon />}
                                sx={{ display: editable ? 'inherit' : 'none' }}
                                color="warning"
                                variant="outlined"
                                onClick={() => {
                                  setUpdateId(atualizacao.id);
                                  handleOpenModal('edit-update');
                                }}
                              >
                                Editar
                              </Button>
                              <Button
                                variant="outlined"
                                startIcon={<DeleteIcon />}
                                sx={{ display: editable ? 'inherit' : 'none' }}
                                color="error"
                                onClick={() => {
                                  setUpdateId(atualizacao.id);
                                  setDeleteUpdateDialog(true);
                                }}
                              >
                                Excluir
                              </Button>
                            </Container>
                          </div>
                        );
                      })
                    : ''}

                  <Grid className={classes.updateInputContainer} container spacing={2}>
                    <Grid item xs={12} sm={8} lg={10}>
                      <TextField
                        multiline
                        fullWidth
                        className={classes.labelColor}
                        id="consult-update"
                        label="Atualização"
                        variant="outlined"
                        value={updateText}
                        onChange={event => setUpdateText(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={2}>
                      <DesktopDatePicker
                        label="Data de Atualização"
                        value={updateDate}
                        onChange={newValue => {
                          setUpdateDate(newValue);
                        }}
                        renderInput={params => <TextField className={classes.labelColor} fullWidth {...params} />}
                      />
                    </Grid>
                    <Grid item>
                      <Button startIcon={<AddIcon />} variant="outlined" onClick={() => handleCreateUpdate(item.id)}>
                        Adicionar atualização
                      </Button>
                    </Grid>
                  </Grid>
                  <Container
                    sx={{ marginTop: editable ? '1rem' : '' }}
                    className={classes.editDeleteContainer}
                    maxWidth={false}
                  >
                    <Button
                      startIcon={<EditIcon />}
                      sx={{ display: editable ? 'inherit' : 'none' }}
                      color="warning"
                      onClick={() => {
                        setIteration(item);
                        handleOpenModal('edit-consultation');
                      }}
                    >
                      Editar consulta
                    </Button>
                    <Button
                      onClick={() => {
                        setIteration(item);
                        setDeleteConsultDialog(true);
                      }}
                      startIcon={<DeleteIcon />}
                      sx={{ display: editable ? 'inherit' : 'none' }}
                      color="error"
                    >
                      Excluir consulta
                    </Button>
                  </Container>
                </Accordion>
              </div>
            );
          })
        )}
      </Container>
    </div>
  );
}
