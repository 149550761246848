import { projectsInDevelopmentColumns } from '../OptionsColumns/OptionColumn';
import { projectsInDevelopmentDetailStyle } from './ProjectsInDevelopmentDetailStyle';
import { RowMetaProps } from '../../Type';
import { useContext } from 'react';
import { DataContext } from '../../ProjectsInDevelopment';
import { getDataBySections } from '../../../../../util/FormatterProjectsInDevelopment';
import { TableSection } from '../../../../molecules/TableSection/TableSection';

export function ProjectsInDevelopmentDetail(props: RowMetaProps): JSX.Element {
  const { rowMeta } = props;
  const classes = projectsInDevelopmentDetailStyle();
  const data = useContext(DataContext)[rowMeta.dataIndex];

  const { mainSection, droSection, accessInformationSection, grantSection, accessOpinionSection } = getDataBySections(
    data
  );

  return (
    <tr className={classes.rowDetail}>
      <td colSpan={projectsInDevelopmentColumns.length + 1}>
        <TableSection title="Dados Principais" data={mainSection.data} headers={mainSection.headers} />
        <TableSection title="DRO" data={droSection.data} headers={droSection.headers} />
        <TableSection
          title="Informação de Acesso"
          data={accessInformationSection.data}
          headers={accessInformationSection.headers}
        />
        <TableSection title="Outorga" data={grantSection.data} headers={grantSection.headers} />
        <TableSection
          title="Parecer de Acesso"
          data={accessOpinionSection.data}
          headers={accessOpinionSection.headers}
        />
      </td>
    </tr>
  );
}
